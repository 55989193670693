import DatePicker from "react-datepicker";
import { Icon, RangeSlider } from "@qp/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { setFilters } from "../../../store/transactions.slice";
import { useEffect, useState } from "react";
import { getTransactions } from "../../../services/transactionsService";

const Filters = () => {
  const { t } = useTranslation(["transaction"]);
  const dispatch = useAppDispatch();

  const { min_amount, max_amount, filters } = useAppSelector(
    (state) => state.transaction
  );

  const [rangevalues, setRangeValues] = useState([0, 0]);

  const { startDate, endDate, lessAmount, greaterAmount, page } = filters;
  const handleChange = (dates: [any, any]) => {
    const [start, end] = dates;
    dispatch(setFilters({ ...filters, startDate: start, endDate: end }));
  };

  const handleFinalRange = (values: Array<number>) => {
    setRangeValues(values);
    dispatch(
      setFilters({
        ...filters,
        lessAmount: rangevalues[0],
        greaterAmount: rangevalues[1],
      })
    );
  };

  useEffect(() => {
    if (min_amount && max_amount) {
      setRangeValues([min_amount, max_amount]);
      dispatch(
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
          lessAmount: min_amount,
          greaterAmount: max_amount,
        })
      );
    }
  }, [min_amount, max_amount, dispatch]);

  useEffect(() => {
    console.log(startDate, endDate, lessAmount, greaterAmount);
    dispatch(getTransactions());
  }, [dispatch, startDate, endDate, lessAmount, greaterAmount, page]);

  if (rangevalues[0] >= min_amount && min_amount < max_amount) {
    return (
      <div className="md:px-0 px-4">
        <div className="qp-cont-date-picker">
          <DatePicker
            className="qp-date-picker"
            dateFormat="yyyy/MM/dd"
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText={t("placeholderDates")}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={true}
          />
          <Icon icon="calendar-orange" />
        </div>
        <div className="relative my-8 mx-auto md:w-6/12 w-full">
          <p className="text-left mb-6 ">{t("amount")}</p>
          <RangeSlider
            min={min_amount}
            max={max_amount}
            values={rangevalues}
            onChange={setRangeValues}
            onFinalChange={handleFinalRange}
          />
        </div>
      </div>
    );
  }

  return <></>;
};

export default Filters;
