import { formNewLoginProps } from "../../interfaces/newLoginInterface";
import { useTranslation } from "react-i18next";
import SignUpEmailForm from "../../components/auth/SignUpEmailForm";
import { useNavigate } from "react-router-dom";
import NewLoginHeader from "@qp/ui/components/NewLoginHeader";

const SignUpEmail = (props: formNewLoginProps) => {
  localStorage.setItem("business", JSON.stringify(""));
  localStorage.setItem("businessType", JSON.stringify(""));
  localStorage.setItem("detailsFormData", JSON.stringify(""));
  localStorage.setItem("representativeFormData", JSON.stringify(""));
  localStorage.setItem("ownersFormData", JSON.stringify(""));
  localStorage.setItem("additionalFirstOwner", JSON.stringify(""));
  localStorage.setItem("additionalSecondOwner", JSON.stringify(""));
  localStorage.setItem("additionalThirdOwner", JSON.stringify(""));
  localStorage.setItem("registrationStatus", JSON.stringify(""));
  localStorage.setItem('document',JSON.stringify(""));
  localStorage.setItem('document2',JSON.stringify(""));
  localStorage.setItem('document3',JSON.stringify(""));
  localStorage.setItem('document4',JSON.stringify(""));
  const { t } = useTranslation(["registration"]);
  const navigate = useNavigate();

  const handleSuccess = (values: any) => {
    localStorage.setItem("qp-signupUser", JSON.stringify(values));
    navigate("/registration/business-type");
  };

  return (
    <div className="w-full mt-20">
      <NewLoginHeader
        showBackButton
        titleHeader={`${t("steps.titlestep1")}<br /> ${t("steps.titlestep11")}`}
      />
      <SignUpEmailForm onSuccess={handleSuccess} />
    </div>
  );
};

export default SignUpEmail;
