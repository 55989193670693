import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { uploadInterface } from "../../../interfaces/uploadInterface";
import { FileUploader } from "react-drag-drop-files";
import UploadLogo from "./UploadLogo";
import { Button } from "@qp/ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getUserInfo, logoutUser } from "../../../services/authService";
import { setUserInfo } from "../../../store/auth.slice";
import { setLoading } from "../../../store/shared.slice";
import { useNavigate } from "react-router-dom";

const fileTypes = ["JPG", "PNG", "jpeg"];
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

function UploadForm(props: uploadInterface) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation(["profile"]);
  const [pictureName, setPictureName] = useState<string | null>(
    t("upload.label")
  );
  const { onSuccess } = props;
  const formik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .test(
          "fileFormat",
          t("upload.fileTypeErr"),
          (file) =>{
            if (!SUPPORTED_FORMATS.includes(file.type)) {
              return false
            }
            return true;
          })
        .test('fileSize', t("upload.fileSizeErr"), (file) => {
          if (file) {
            return file.size <= 2097152;
          }
          return true;
        }),
    }),
    onSubmit: (values: any) => {
      onSuccess(values)
      setPictureName(t("upload.label"))
      formik.setFieldValue("file", null)
    }
  });

  useEffect(() => {
    if(userInfo.photo_url){
      setPictureName(userInfo.photo_url.substring(userInfo.photo_url.lastIndexOf("/") + 1))
    }
  },[userInfo.photo_url])

  useEffect(() => {
    if (userInfo.email.length === 0) {
      getUserInfo()
        .then((res) => {
          dispatch(setUserInfo(res.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response.status === 401) {
            logoutUser().then(() => {
              navigate("/");
            });
          }
          navigate("/signup");
        });
    }
  }, [userInfo, dispatch, navigate]);
  return (
    <form onSubmit={formik.handleSubmit} className="upload-form">
      {/* loyalty */}
      <div className="qp-cont-inp w-full md:w-7/12">
        {formik.touched.file && formik.errors.file && (
          <div className="qp-inp-error">{formik.errors.file}</div>
        )}
        <FileUploader
          handleChange={(file: File) => {
            formik.setFieldValue(
              "file",
              file
                ? file
                : "no file"
            );
            setPictureName(file.name)
          }}
          name="file"
          types={fileTypes}
          children={<UploadLogo label={pictureName} />}
          classes='h-6 mb-4 w-full flex justify-center items-center'
        />
      </div>
      <Button
        type="submit"
        extraClass="profile-submit-btn "
        text={t("upload.button")}
        disabled={formik.values.file ? false : true}
        onClick={formik.handleSubmit}
      />
    </form>
  );
};

export default UploadForm;
