import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { getBalance } from '../../../services/profileService'
import { setBalance } from '../../../store/profile.slice';
import { setModalMessage } from '../../../store/shared.slice';
import { useTranslation } from 'react-i18next';

const Balance = () => {
  const balance = useAppSelector((state) => state.profile.balance);
  const { t } = useTranslation(["profile"]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getBalance().then((res) => {
      dispatch(setBalance(res.data))
    }).catch((err) => {
      dispatch(setModalMessage({
        title: "Error",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      }))
    })
  },[dispatch])

  return (
    <div className="w-full mb-8">
      <div className="text-2xl md:text-3xl font-medium mb-8">{t("balance.title")}</div>
      <div className={`profile-container text-sm md:text-base flex`}>
        <p className='text-sm md:text-base text-left break-words w-2/4'>{t("balance.subtitle")}</p>
        <p className="text-sm font-semibold md:text-base text-right break-words w-2/4">{`$ ${balance ? balance : 0}`}</p>
      </div>
    </div>
  );
};

export default Balance;