import { AppThunk } from "../store";
import { setExpirationDate, setLoyalty } from "../store/loyalty.slice";
import { setModalMessage } from "../store/shared.slice";
import axiosCLient from "./axios";

export const getLoyalty = (): AppThunk => async (dispatch) => {
  await axiosCLient.get("merchants/loyalty").then((res) => {
    dispatch(setLoyalty(res.data));
  });
};

export const getExpirationLoyalty = (): AppThunk => async (dispatch) => {
  await axiosCLient.get("merchants/loyalty/expire").then((res) => {
    dispatch(setExpirationDate(res.data));
  });
};

export const setExpirationLoyalty =
  (expiration: string): AppThunk =>
    async (dispatch) => {
      await axiosCLient
        .put("merchants/loyalty/expire", { days_to_expirate: expiration })
        .then((res) => {
          dispatch(
            setModalMessage({
              title: "Expiration Loyalty",
              text: res.data.message,
              icon: "success",
              confirmButtonText: "OK",
            })
          );
          dispatch(getExpirationLoyalty());
        });
    };

export const sendLoyalty =
  (loyalty: number | string): AppThunk =>
    async (dispatch) => {
      await axiosCLient.put("merchants/loyalty", { loyalty }).then((res) => {
        dispatch(getLoyalty());
        dispatch(
          setModalMessage({
            title: "Loyalty",
            text: "Loyalty updated successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
        );
      });
    };
