import moment from "moment";

export const months = () => {
  const months = [];
  const dateStart = moment("1-1-2023",  "MM-DD-YYYY");
  const dateEnd = moment("1-1-2023",  "MM-DD-YYYY").add(12, 'month')
  while (dateEnd.diff(dateStart, 'months') > 0) {
    months.push(dateStart.format('MMMM'))
    dateStart.add(1, 'month')
  }
  return months;
};

export const days = (formik: any) => {
  const days = []
  const currentMonth = moment().month(formik.values.month).format("M")
  const daysInMonth = moment(`${currentMonth}-${formik.values.year || '2023'}`, "MM-YYYY").daysInMonth()
  const dateStart = moment(`${currentMonth}-01-${formik.values.year || '2023'}`, "MM-DD-YYYY")
  const dateEnd = moment(`${currentMonth}-01-${formik.values.year || '2023' }`, "MM-DD-YYYY").add(daysInMonth-1, 'days')
  while (dateEnd.diff(dateStart, 'days') >= 0) {
    days.push(dateStart.format('D'))
    dateStart.add(1, 'days')
  }
return days
};

export const years = () => {
  const years = []
  const dateStart = moment().subtract(18, 'y');
  const dateEnd =  moment().subtract(100, 'y')
  while (dateStart.diff(dateEnd, 'years') >= 0) {
    years.push(dateStart.format('YYYY'))
    dateStart.subtract(1, 'year')
  }
  return years
};

export const formatData = (a: any) => {
  const arr = a.map((item: string, i: number) => {
    return {
      id: i,
      value: item
    }
  })
  return arr
};