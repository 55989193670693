import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setLoading } from '../../../store/shared.slice';
import { redirectUrlFormProps } from '../../../interfaces/uploadInterface';
import RedirectUrlsForm from '../../../components/profile/urls/RedirectUrlsForm';
import { setFailedUrl, setSuccessfulUrl } from '../../../store/profile.slice';
import Swal from "sweetalert2";
import { editRedirectUrls, postRedirectUrls } from '../../../services/settingsService';
import { useTranslation } from 'react-i18next';

const RedirectUrls = () => {
  const existingSuccessfulUrl = useAppSelector((state) => state.profile.successfulTransactionUrl);
  const existingFailedUrl = useAppSelector((state) => state.profile.failedTransactionUrl);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["settings"]);

  const handleSuccess = (values: redirectUrlFormProps) => {
    if(existingSuccessfulUrl && existingFailedUrl ){
      if (values.successfulUrl !== existingSuccessfulUrl || values.failedUrl !== existingFailedUrl){
        Swal.fire({
          title: t("redirectUrls.changeConfirmationTitle"),
          text: t("redirectUrls.changeConfirmationText"),
          icon: "info",
          confirmButtonText: t("redirectUrls.changeConfirmationYes"),
        }).then((result) => {
          if (result) {
            dispatch(setLoading(true));
            editRedirectUrls({successfulTransactionUrl: values.successfulUrl,failedTransactionUrl: values.failedUrl })
            .then((res) => {
              if(res.status === 200) {
                if(res.data.message === "Merchant settings updated successfully") {
                  setSuccessfulUrl(res.data.successfulTransactionUrl)
                  setFailedUrl(res.data.failedTransactionUrl)
                  Swal.fire({
                    title: t("redirectUrls.done"),
                    text: t("redirectUrls.doneText"),
                    icon: "success",
                    confirmButtonText: t("redirectUrls.ok"),
                  })
                }
              }
            }).catch(() => {
              Swal.fire({
                title: t("redirectUrls.errTitle"),
                text: t("redirectUrls.errText"),
                icon: "error",
                confirmButtonText: t("redirectUrls.ok"),
              })
              dispatch(setLoading(false));
            });
            dispatch(setLoading(false));
          }
        });
      }
    } else {
      Swal.fire({
        title: t("redirectUrls.createConfirmationTitle"),
        text: t("redirectUrls.createConfirmationText"),
        icon: "info",
        confirmButtonText: t("redirectUrls.yes"),
      }).then((result) => {
        dispatch(setLoading(true));
        postRedirectUrls({successfulTransactionUrl:values.successfulUrl, failedTransactionUrl: values.failedUrl })
          .then((res) => {
            dispatch(setLoading(false));
            if(res.status === 200) {
              if(res.data.message === "Merchant settings added successfully") {
                setSuccessfulUrl(res.data.successfulTransactionUrl)
                setFailedUrl(res.data.failedTransactionUrl)
                Swal.fire({
                  title: t("redirectUrls.done"),
                  text: t("redirectUrls.doneCreateText"),
                  icon: "success",
                  confirmButtonText: t("redirectUrls.ok"),
                })
              }
            }
          }).catch(() => {
            Swal.fire({
              title: t("redirectUrls.errTitle"),
              text: t("redirectUrls.errText"),
              icon: "error",
              confirmButtonText: t("redirectUrls.ok"),
            })
            dispatch(setLoading(false));
          });
          dispatch(setLoading(false));
      })
    };
  };

  return (
    <RedirectUrlsForm onSuccess={handleSuccess}/>
  );
};

export default RedirectUrls;