import Accounts from "../../../components/dash/profile/Accounts";
import Address from "../../../components/dash/profile/Address";
import Balance from "../../../components/dash/profile/Balance";
import Upload from "../../../components/dash/profile/Upload";


const Profile = () => {
  return (
    <div className="w-full md:w-9/12">
      <Upload />
      <hr className="w-9/12 my-12 m-auto"/>
      <div className="my-8">
        <Accounts />
      </div>
      <hr className="w-9/12 my-12 m-auto"/>
      <Address />
      <hr className="w-9/12 mt-8 mb-12 m-auto"/>
      <Balance />
    </div>
  );
};

export default Profile;