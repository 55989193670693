import React, { useCallback, useEffect } from "react";
import { Icon, Input, SelectCityUS, SelectStatesUS } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import {
  Business,
  BusinessClassificationsProps,
  businessDetailsFormProps,
  DetailsFormInfoProps,
  IndustryProps,
} from "../../../interfaces/newLoginInterface";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getBussinessClassification } from "../../../services/authService";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const BusinessDetailsForm = (props: businessDetailsFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["registration"]);
  const getPreviousDetails: DetailsFormInfoProps = JSON.parse(
    window.localStorage.getItem("detailsFormData") || ""
  );

  const bussinessClasifications = useAppSelector(
    (state) => state.auth.bussinessclasifications
  );

  const { onSuccess } = props;
  const formik = useFormik({
    initialValues: {
      ein: getPreviousDetails.ein || "",
      address_value: getPreviousDetails.address_value || "",
      city: getPreviousDetails.city || "",
      state: getPreviousDetails.state || "",
      zip_code: getPreviousDetails.zip_code || "",
      country: getPreviousDetails.country || "US",
      phone_number: getPreviousDetails.phone_number || "",
      business_category: getPreviousDetails.business_category || "",
      business_classifications:
        getPreviousDetails.business_classifications || "",
      businessName: getPreviousDetails.businessName || "",
      statement_descriptor: getPreviousDetails.statement_descriptor || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      ein: Yup.string()
        .matches(/^[0-9]{9}$/, t("businessDetailsForm.einError"))
        .required(t("businessDetailsForm.einReq")),
      address_value: Yup.string()
        .required(t("businessDetailsForm.addReq"))
        .max(50, t("businessDetailsForm.addressMax")),
      city: Yup.string().required(t("businessDetailsForm.cityReq")),
      state: Yup.string().required(t("businessDetailsForm.stateReq")),
      zip_code: Yup.string()
        .required(t("businessDetailsForm.zipReq"))
        .matches(
          /(^(?!00000)\d{5}$)|(^\d{5}-\d{4}$)/,
          t("businessDetailsForm.validZipErr")
        ),
      country: Yup.string(),
      phone_number: Yup.string()
        .min(8, t("businessDetailsForm.validPhone"))
        .required(t("businessDetailsForm.phoneReq")),
      business_category: Yup.string().required(
        t("businessDetailsForm.categReq")
      ),
      business_classifications: Yup.string().required(
        t("businessDetailsForm.businessClassReq")
      ),
      statement_descriptor: Yup.string()
        .min(5, t("businessDetailsForm.statementMin"))
        .max(22, t("businessDetailsForm.statementMax"))
        .required(t("businessDetailsForm.statementReq")),
      businessName: Yup.string().required(t("businessDetailsForm.bussNameReq")),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const getCategoryId = () => {
    const getId: any = bussinessClasifications.find(
      (business: BusinessClassificationsProps) =>
        business.value === formik.values.business_category
    );
    return getId;
  };

  const changeState = useCallback(
    (e) => {
      formik.setValues({ ...formik.values, city: "" });
      formik.handleChange(e);
    },
    [formik]
  );

  useEffect(() => {
    dispatch(getBussinessClassification());
  }, [dispatch]);

  return (
    <form className="mt-12" onSubmit={formik.handleSubmit}>
      <div className="w-full">
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.bussName")}</div>
          {formik.touched.businessName && formik.errors.businessName && (
            <div className="qp-inp-error">{formik.errors.businessName}</div>
          )}
          <Input
            type="text"
            name="businessName"
            placeholder={t("businessDetailsForm.bussName")}
            value={formik.values.businessName}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={
              formik.touched.businessName && formik.errors.businessName
            }
          />
        </div>
        <div className="qp-cont-inp relative">
          <div className="text-left">
            {t("businessDetailsForm.statementDes")}
          </div>
          {formik.touched.statement_descriptor &&
            formik.errors.statement_descriptor && (
              <div className="qp-inp-error">
                {formik.errors.statement_descriptor}
              </div>
            )}
          <ReactTooltip
            id="statementDescriptor"
            place="right"
            className="w-80"
            type="dark"
          />
          <i
            data-for="statementDescriptor"
            data-tip={t("businessDetailsForm.info")}
            className="absolute right-0 top-2"
          >
            <Icon icon={"info-reg"} extraClass="qp-info-tooltip" />
          </i>
          <Input
            type="text"
            name="statement_descriptor"
            placeholder={t("businessDetailsForm.statementDes")}
            value={formik.values.statement_descriptor}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={
              formik.touched.statement_descriptor &&
              formik.errors.statement_descriptor
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.employeeId")}</div>
          {formik.touched.ein && formik.errors.ein && (
            <div className="qp-inp-error">{formik.errors.ein}</div>
          )}
          <Input
            type="text"
            name="ein"
            placeholder="123456789"
            value={formik.values.ein}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.ein && formik.errors.ein}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9]/.test(e.key) && e.preventDefault()
            }
            maxLength={9}
          />
        </div>
        <br />
        <div className="qp-cont-inp text-left text-base md:text-xl">
          {t("businessDetailsForm.bussAddress")}
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.country")}</div>
          {formik.touched.country && formik.errors.country && (
            <div className="qp-inp-error">{formik.errors.country}</div>
          )}
          <Input
            type="text"
            name="country"
            placeholder={t("businessDetailsForm.country")}
            value="USA"
            disabled
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.country && formik.errors.country}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.state")}</div>
          {formik.touched.state && formik.errors.state && (
            <div className="qp-inp-error">{formik.errors.state}</div>
          )}
          <SelectStatesUS
            onChange={changeState}
            onBlur={formik.handleBlur}
            haveError={formik.touched.state && formik.errors.state}
            value={formik.values.state}
            name="state"
            placeholder={t("businessDetailsForm.stateSelect")}
          />
        </div>
        <div className="qp-cont-inp">
          {formik.touched.city && formik.errors.city && (
            <div className="qp-inp-error">{formik.errors.city}</div>
          )}
          <SelectCityUS
            label={t("businessDetailsForm.city")}
            placeholder={t("businessDetailsForm.city")}
            onChange={(e: any) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            haveError={formik.touched.city && formik.errors.city}
            name="city"
            onSelectOption={(e: string) =>
              formik.setValues({ ...formik.values, city: e })
            }
            value={formik.values.city}
            stateCode={formik.values.state}
          />
          {/* <Input
            type="text"
            name="city"
            placeholder={t("businessDetailsForm.city")}
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.city && formik.errors.city}
          /> */}
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.zipCode")}</div>
          {formik.touched.zip_code && formik.errors.zip_code && (
            <div className="qp-inp-error">{formik.errors.zip_code}</div>
          )}
          <Input
            type="text"
            name="zip_code"
            placeholder={t("businessDetailsForm.zipCode")}
            value={formik.values.zip_code}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.zip_code && formik.errors.zip_code}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9]/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.address")}</div>
          {formik.touched.address_value && formik.errors.address_value && (
            <div className="qp-inp-error">{formik.errors.address_value}</div>
          )}
          <Input
            type="text"
            name="address_value"
            placeholder={t("businessDetailsForm.address")}
            value={formik.values.address_value}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={
              formik.touched.address_value && formik.errors.address_value
            }
          />
        </div>
        <div className="qp-cont-inp">
          <hr />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessDetailsForm.phone")}</div>
          {formik.touched.phone_number && formik.errors.phone_number && (
            <div className="qp-inp-error">{formik.errors.phone_number}</div>
          )}
          <PhoneInput
            country={"us"}
            value={formik.values.phone_number.toString()}
            onlyCountries={["us"]}
            inputProps={{
              name: "phone_number",
              placeholder: "+1 (132) 55-0123",
              required: true,
              autoFocus: true,
            }}
            onChange={(phone) =>
              formik.handleChange({
                target: { name: "phone_number", value: phone },
              })
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">
            {t("businessDetailsForm.bussCateSelect")}
          </div>
          {formik.touched.business_category &&
            formik.errors.business_category && (
              <div className="qp-inp-error">
                {formik.errors.business_category}
              </div>
            )}
          <select
            name="business_category"
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            value={formik.values.business_category}
            className={`${
              formik.touched.business_category &&
              formik.errors.business_category !== undefined
                ? "error"
                : ""
            } qp-input`}
          >
            <option className="font-bold">
              {t("businessDetailsForm.bussCateSelect")}
            </option>
            {bussinessClasifications.map((business: Business) => {
              return (
                <option
                  key={business.id}
                  value={business.value}
                  className="text-black"
                >
                  {business.value}
                </option>
              );
            })}
          </select>
        </div>
        {formik.values.business_category && (
          <div className="qp-cont-inp">
            <div className="text-left">
              {t("businessDetailsForm.bussClassSelect")}
            </div>
            {formik.touched.business_classifications &&
              formik.errors.business_classifications && (
                <div className="qp-inp-error">
                  {formik.errors.business_classifications}
                </div>
              )}
            <select
              name="business_classifications"
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              value={formik.values.business_classifications}
              className={`${
                formik.touched.business_classifications &&
                formik.errors.business_classifications !== undefined
                  ? "error"
                  : ""
              } qp-input`}
            >
              <option className="font-bold">
                {t("businessDetailsForm.bussClassSelect")}
              </option>
              {getCategoryId()?.embedded.industryClassifications.map(
                (business: IndustryProps) => {
                  return (
                    <option
                      key={business.id}
                      value={business.id}
                      className="text-black"
                    >
                      {business.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        )}
        <br />
        <div className="qp-cont-inp">
          <hr />
        </div>
        <button type="submit" className="qp-btn md:w-6/12 w-full mt-6">
          {t("businessDetailsForm.continueBtn")}
        </button>
        <br />
      </div>
    </form>
  );
};

export default BusinessDetailsForm;
