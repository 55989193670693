import { Input, SelectCityUS, SelectStatesUS } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { formAddressProps } from "../../../interfaces/addressInterface";
import { useCallback } from "react";

const AddressForm = (props: formAddressProps) => {
  const { onSuccess, data } = props;
  const { t } = useTranslation(["profile"]);
  const formik = useFormik({
    initialValues: {
      address_value: data?.address_value ?? "",
      city: data?.city ?? "",
      state: data?.state ?? "",
      zip_code: data?.zip_code ?? "",
      country: data?.country || "US",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      address_value: Yup.string().required(t("address.form.addReq")),
      city: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, t("cityName"))
        .required(t("address.form.cityReq")),
      state: Yup.string().required(t("address.form.stateReq")),
      zip_code: Yup.string()
        .required(t("address.form.zipCodeReq"))
        .matches(
          /(^(?!00000)\d{5}$)|(^\d{5}-\d{4}$)/,
          t("address.form.validZipErr")
        ),
      country: Yup.string(),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const changeState = useCallback(
    (e) => {
      formik.setValues({ ...formik.values, city: "" });
      formik.handleChange(e);
    },
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit} className="mt-12">
      {/* country input */}
      <div className="qp-cont-inp">
        <Input
          label={t("address.form.country")}
          type="text"
          name="country"
          placeholder={t("address.form.country")}
          value="USA"
          disabled
          onBlur={formik.handleBlur}
          onChange={(e) => formik.handleChange(e)}
          haveError={formik.touched.country && formik.errors.country}
        />
        {formik.touched.country && formik.errors.country ? (
          <div className="qp-inp-error">{formik.errors.country}</div>
        ) : null}
      </div>
      {/* state input */}
      <div className="qp-cont-inp">
        {formik.touched.state && formik.errors.state && (
          <div className="qp-inp-error">{formik.errors.state}</div>
        )}
        <SelectStatesUS
          label={t("address.form.state")}
          onChange={changeState}
          onBlur={formik.handleBlur}
          haveError={formik.touched.state && formik.errors.state}
          value={formik.values.state}
          name="state"
          placeholder={t("address.form.state")}
        />
      </div>
      {/* city input */}
      <div className="qp-cont-inp">
        <SelectCityUS
          label={t("address.form.city")}
          placeholder={t("address.form.city")}
          onChange={(e: any) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          haveError={formik.touched.city && formik.errors.city}
          name="city"
          onSelectOption={(e: string) =>
            formik.setValues({ ...formik.values, city: e })
          }
          value={formik.values.city}
          stateCode={formik.values.state}
        />
        {formik.touched.city && formik.errors.city ? (
          <div className="qp-inp-error">{formik.errors.city}</div>
        ) : null}
      </div>
      {/* zip input */}
      <div className="qp-cont-inp">
        <Input
          label={t("address.form.zip")}
          type="text"
          placeholder={t("address.form.zip")}
          name="zip_code"
          value={formik.values.zip_code}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          haveError={formik.touched.zip_code && formik.errors.zip_code}
        />
        {formik.touched.zip_code && formik.errors.zip_code ? (
          <div className="qp-inp-error">{formik.errors.zip_code}</div>
        ) : null}
      </div>
      {/* address input */}
      <div className="qp-cont-inp">
        <Input
          label={t("address.form.address")}
          type="text"
          placeholder={t("address.form.address")}
          name="address_value"
          value={formik.values.address_value}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          haveError={
            formik.touched.address_value && formik.errors.address_value
          }
        />
        {formik.touched.address_value && formik.errors.address_value ? (
          <div className="qp-inp-error">{formik.errors.address_value}</div>
        ) : null}
      </div>
      <button type="submit" className="qp-btn mt-8">
        {!data ? t("address.form.saveAddress") : t("address.editAddress")}
      </button>
    </form>
  );
};

export default AddressForm;
