import React from "react";
import { selectProps } from "../interfaces/formInterface";

const Select = (props: selectProps) => {
  const {
    placeholder,
    name,
    value,
    keyValue,
    haveError,
    onBlur,
    onChange,
    extraClass,
    reference,
    disabled,
    options,
  } = props;
  return (
    <select
      name={name}
      className={`${haveError !== undefined ? "error" : ""} ${
        extraClass ? extraClass : ""
      } ${disabled ? "qp-inp-disabled" : ""} qp-input`}
      onChange={(e) => onChange(e)}
      onBlur={() => onBlur}
      ref={reference}
      disabled={disabled}
      value={value}
    >
      <option value="">{placeholder}</option>
      {options.map((option: any) => {
        return (
          <option
            value={keyValue ? option[keyValue] : option.value}
            key={option.id}
          >
            {option.value}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
