import ReactPaginate from "react-paginate";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { setFilters } from "../../../store/transactions.slice";
import ItemTransaction from "./TransactionItem";

const ListTransactions = () => {
  const { transactions, totalPages } = useAppSelector(
    (state) => state.transaction
  );
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.transaction);

  const handleChangePage = (e: any) => {
    dispatch(
      setFilters({
        ...filters,
        page: e.selected + 1,
      })
    );
  };

  return (
    <>
      <div className="transaction-merchant-list">
        {transactions.map((transaction: any) => {
          return <ItemTransaction data={transaction} key={transaction.id} />;
        })}
      </div>
      {totalPages > 0 ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handleChangePage}
          pageRangeDisplayed={2}
          pageCount={totalPages}
          previousLabel="<"
          containerClassName="paginator"
        />
      ) : (
        <div className="font-medium">No transactions yet</div>
      )}
    </>
  );
};

export default ListTransactions;
