import { layoutPropsMerchant } from "@qp/ui/interfaces/layoutInterface";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const Layout = (props: layoutPropsMerchant) => {
  const { disableHeader } = props;

  return (
    <>
      {!disableHeader && <Header />}
      <Outlet />
    </>
  );
};

export default Layout;
