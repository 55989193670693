import { useEffect } from 'react';
import TaxesForm from '../../../components/settings/taxes/TaxesForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { createCustomTax, getTaxesValues, updateTaxesValues } from '../../../services/settingsService';
import { setAddCustomTax, setTaxesData, setUpdateCustomTax } from '../../../store/profile.slice';
import { TaxesValuesI, TaxIndividualI } from '../../../interfaces/uploadInterface';
import { setLoading, setModalMessage } from '../../../store/shared.slice';
import { useTranslation } from 'react-i18next';

const Taxes = () => {
  const dispatch = useAppDispatch();
  const taxes = useAppSelector((state) => state.profile.taxesData);
  const { t } = useTranslation(["settings"]);

  const handleSuccess = (values: TaxesValuesI) => {
    values.taxesValues.forEach(element => {
      const valueId = element.id;
      const customTaxId = element.customTax?.merchantTaxId;
      const customTaxRate = element.customTax?.customRate;
      const existingValue = taxes.filter((tax: TaxIndividualI) => tax.id === valueId);
      // if custom rate id does not exists yet, and default rate is different from new rate, then create custom rate
      if (customTaxId === "" && Number(existingValue[0].rate).toFixed(2) !==  Number(element.customTax?.customRate).toFixed(2)) {
        dispatch(setLoading(true));
        createCustomTax(valueId, Number(element.customTax?.customRate)).then((res) => {
          dispatch(setLoading(false));
          dispatch(setAddCustomTax({id: res.data.taxId, rate: res.data.rate, customTaxId: res.data.id}))
          dispatch(
            setModalMessage({
              title: t("taxes.doneTitle"),
              text: t("taxes.doneCreateTaxText"),
              icon: "success",
              confirmButtonText: t("taxes.ok"),
            })
          );
        }).catch((err : any) => {
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("taxes.errTitle"),
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: t("taxes.ok"),
            })
          );
        })
      // if custom rate id exists, and new custom rate is different than 0 and the existing custom rate is different than the new custom rate
      } else if (customTaxId !== "" &&  Number(customTaxRate) !== 0 && (Number(existingValue[0].customTax?.customRate).toFixed(2) !== Number(customTaxRate).toFixed(2))) {
        dispatch(setLoading(true));
        updateTaxesValues(element.customTax?.merchantTaxId, Number(element.customTax?.customRate)).then(() => {
          dispatch(setLoading(false));
          dispatch(setUpdateCustomTax({rate: element.customTax?.customRate,  id: customTaxId}));
          dispatch(
            setModalMessage({
              title: t("taxes.doneTitle"),
              text: t("taxes.doneUpdateTaxText"),
              icon: "success",
              confirmButtonText: t("taxes.ok"),
            })
          );
        }).catch((err : any) => {
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("taxes.errTitle"),
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: t("taxes.ok"),
            })
          );
        })
      }
    });
  };

  useEffect(() => {
    getTaxesValues().then((res) => {
      if (res.status === 200) {
        dispatch(setTaxesData(res.data))
      }
    })
  },[dispatch])

  return (
    <div className='w-full mb-8'>
      <div className="flex justify-start">
        <TaxesForm data={taxes} onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export default Taxes;