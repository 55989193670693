import { useEffect, useState } from 'react'
import NewLoginHeader from '@qp/ui/components/NewLoginHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BusinessOwnerForm from '../../../components/auth/registration/BusinessOwnerForm';
import { OwnerFormInfoProps, publicDetailsProps } from "../../../interfaces/newLoginInterface";
import { useAppDispatch } from '../../../hooks/storeHooks';
//import { signUpAdditionalOwner } from '../../services/authService';
import { setLoading, setModalMessage } from '../../../store/shared.slice';
import { registerOwnerIdDocument } from '../../../services/authService';
import moment from 'moment';

const BusinessOwner = (props: publicDetailsProps) => {
  const { t } = useTranslation(["registration"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ownersFormValues, setOwnersFormValues] = useState<any>(JSON.parse(localStorage.getItem('ownersFormData') || "no"));
  const [additionalFirstOwner, setAdditionalFirstOwner] = useState<any>(JSON.parse(localStorage.getItem('additionalFirstOwner') || "no"));
  const [additionalSecondOwner, setAdditionalSecondOwner] = useState<any>(JSON.parse(localStorage.getItem('additionalSecondOwner') || "no"));
  const [additionalThirdOwner, setAdditionalThirdOwner] = useState<any>(JSON.parse(localStorage.getItem('additionalThirdOwner') || "no"));

  const handleSuccess = (values: OwnerFormInfoProps) => {
    values.dateOfBirth = moment(`${values.day} ${values.month} ${values.year}`).toDate();
    if(values.file.name){
      const formData = new FormData();
      formData.append('file',values.file)
      dispatch(setLoading(true));
      registerOwnerIdDocument(formData)
      .then((res:any) => {
        if((ownersFormValues === "") || (ownersFormValues && JSON.parse(localStorage.getItem('ownerNumber') || "") === 1)) {
          localStorage.setItem('document',JSON.stringify(res.data))
        } else if((additionalFirstOwner === "" ) || (additionalFirstOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 2)) {
          localStorage.setItem('document2',JSON.stringify(res.data))
        } else if((additionalSecondOwner === "") || (additionalSecondOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 3)) {
          localStorage.setItem('document3',JSON.stringify(res.data))
        } else if((additionalThirdOwner === "") || (additionalThirdOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 4)){
          localStorage.setItem('document4',JSON.stringify(res.data))
        }
        dispatch(setLoading(false));
      })
      .catch((err:any) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: t("steps.errTitle"),
            text: t("steps.errText"),
            icon: "error",
            confirmButtonText: t("steps.ok"),
          })
        );
      });
    };

    if((ownersFormValues === "") || (ownersFormValues && JSON.parse(localStorage.getItem('ownerNumber') || "") === 1)) {
      setOwnersFormValues(values)
      navigate("/registration/add-business-owners");
    } else if((additionalFirstOwner === "" ) || (additionalFirstOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 2)) {
      setAdditionalFirstOwner(values)
      navigate("/registration/add-business-owners");
    } else if((additionalSecondOwner === "") || (additionalSecondOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 3)) {
      setAdditionalSecondOwner(values)
      navigate("/registration/add-business-owners");
    } else if((additionalThirdOwner === "") || (additionalThirdOwner && JSON.parse(localStorage.getItem('ownerNumber') || "") === 4)){
      setAdditionalThirdOwner(values)
      navigate("/registration/add-business-owners");
    }
  };

  useEffect (() => {
    localStorage.setItem('ownersFormData', JSON.stringify(ownersFormValues));
    localStorage.setItem('additionalFirstOwner', JSON.stringify(additionalFirstOwner))
    localStorage.setItem('additionalSecondOwner', JSON.stringify(additionalSecondOwner))
    localStorage.setItem('additionalThirdOwner', JSON.stringify(additionalThirdOwner))
  },[ownersFormValues,additionalFirstOwner, additionalSecondOwner,additionalThirdOwner])

  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("steps.titlebo")}`}
      />
      <BusinessOwnerForm onSuccess={(e) => handleSuccess(e)} />
    </div>
  );
};

export default BusinessOwner;