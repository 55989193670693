import React from "react";
import "./App.css";
import "@qp/ui/dist/css/main.css";
import Router from "./router";
import { Loading } from "@qp/ui";
import { useAppSelector } from "./hooks/storeHooks";
import ModalApi from "./components/ModalApi";

function App() {
  const loadVisible = useAppSelector((state) => state.shared.isLoading);

  return (
    <div className="App">
      <div className="container">
        {/* {token !== null && <Router />} */}
        <Router />
        <ModalApi />
        <Loading type="global" visible={loadVisible} />
      </div>
    </div>
  );
}

export default App;
