import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getCustomers } from "../../../services/customersService";
import CustomerItem from "./CustomerItem";
import { useTranslation } from "react-i18next";

const ListCustomers = () => {
  const customers = useAppSelector((state) => state.customer.customers);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["customers"]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  if (customers.length > 0) {
    return(
      <>
        <div className="justify-between text-orange-dark font-medium px-6 mt-12 hidden md:flex">
          <p className="name text-lg">{t("cusName")}</p>
          <p className="date text-lg">{t("expDate")}</p>
          <p className="ammount text-lg">
            {t("avPoints")}
          </p>
        </div>
        <div className="customers-merchant-list">
          {customers.map((customer: any, i: number) => {
            return <CustomerItem data={customer} key={i} />;
          })}
        </div>
      </>
    );
  }
  else {
    return (
      <div className="md:py-20 py-12">
        <p>{t("noCustomers")}</p>
      </div>
    );
  };
};

export default ListCustomers;
