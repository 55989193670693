import { useEffect } from "react";
import QpPlaidLink from "../../../components/plaid/QpPlaidLinkMerchant";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getPlaidAccounts, savePlaidAccounts } from "../../../services/plaid";
import { PlaidLinkOnSuccess } from "react-plaid-link";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const accounts = useAppSelector((state) => state.accounts.accounts);
  const { t } = useTranslation(["profile"]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPlaidAccounts());
  }, [dispatch]);

  const handleSuccess = (e: PlaidLinkOnSuccess) => {
    dispatch(savePlaidAccounts(e));
  };

  return (
    <div className="w-full mb-8">
      <div className="text-2xl md:text-3xl font-medium mb-8">{t("bankAccount.title")}</div>
      {accounts.length > 0 && (
        <div className="profile-container relative">
          <div className="info">
            <h3 className="font-medium">{t("bankAccount.subtitle")}</h3>
            <p>
              {accounts[0].name} ******{accounts[0].mask}
            </p>
          </div>
          <div className="edit-bank-profile">
            <QpPlaidLink
              onAddAccount={(e: any) => handleSuccess(e)}
              isEditing={true}
            />
          </div>
        </div>
      )}
      {accounts.length === 0 && (
        <QpPlaidLink
          onAddAccount={(e: any) => handleSuccess(e)}
          isEditing={false}
        />
      )}
    </div>
  );
};

export default Accounts;