import { Icon, MenuItem } from "@qp/ui";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../img/logo.png";
import { logoutUser } from "../../services/authService";
import { useTranslation } from "react-i18next";

const HeaderDash = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const { t } = useTranslation(["headerDash"]);
  const logout = (e: any) => {
    e.preventDefault();
    logoutUser().then(() => {
      localStorage.removeItem("qp-auth-token");
      window.location.reload();
    });
  };

  const menu = () => {
    return (
      <>
        <Icon
          icon="close-menu"
          extraClass="qp-icon-close"
          onClick={() => setShowMenu(!showMenu)}
        />
        <NavLink
          to={"transactions"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MenuItem text={t("firstLink")} icon="transactions" />
        </NavLink>
        <NavLink to={"customers"}>
          <MenuItem text={t("secondLink")} icon="customers" />
        </NavLink>
        <NavLink to={"loyalty"}>
          <MenuItem text={t("thirdLink")} icon="loyalty" />
        </NavLink>
        <NavLink to={"profile"}>
          <MenuItem text={t("fourthLink")} icon="user" />
        </NavLink>
        <NavLink
          to={"settings"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          <MenuItem text={t("fifthLink")} icon="settings" />
        </NavLink>
        {/*<NavLink to={"generate-button"}>
          <MenuItem text="Generate button" icon="generate" />
        </NavLink> */}
        <a href="/" onClick={(e) => logout(e)}>
          <MenuItem text={t("sixthLink")} icon="logout" />
        </a>
      </>
    );
  };

  return (
    <header className="header-merchant">
      <div className="w-full flex justify-start md:justify-start xl:justify-start md:visible mr-2">
        <Link to="/">
          <img src={logo} alt="logo" width={130} />
        </Link>
      </div>
      <Icon
        icon="menu"
        extraClass="qp-icon-menu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div className={`container-links animatecss xl:flex justify-end hidden`}>
        {menu()}
      </div>
      {/* mobile menu */}
      <div
        className={`container-links xl:hidden block animatecss menu-mobile ${
          showMenu ? "animatecss-fadeInRigth" : "animatecss-fadeOutRight"
        }`}
      >
        {menu()}
      </div>
      <div
        className={`overlay ${showMenu ? "block" : "hidden"}`}
        onClick={() => setShowMenu(!showMenu)}
      ></div>
    </header>
  );
};

export default HeaderDash;
