import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./lottie/loader.json";

interface loadingProps {
  type?: "global" | "local";
  width?: number;
  height?: number;
  visible: boolean;
}

const Loading = (props: loadingProps) => {
  const { type, height, width, visible } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={`loading ${type ? type : ""} ${!visible ? "hidden" : ""}`}>
      <Lottie
        options={defaultOptions}
        height={height ? height : 200}
        width={width ? width : 200}
      />
    </div>
  );
};

export default Loading;
