import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, PasswordInput } from "@qp/ui";
import {
  loginFormProps,
  IUserLoginEmail,
} from "../../interfaces/loginInterface";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoginForm(props: loginFormProps) {
  localStorage.setItem("qp-signupUser", JSON.stringify(""));
  const { onSuccess } = props;
  const { t } = useTranslation(['login']);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("form.validEmail"))
        .test('specialChar', t("form.emailCharacters"), (string: any) => {
          const specialChars = /^[-_.@A-Za-z0-9]*$/;
          if (specialChars.test(string)) return true
          else return false;
        })
        .required(t("form.emailReq")),
      password: Yup.string()
        .required(t("form.passReq"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^.()])[A-Za-z\d@$!%*?&#^.()]{12,}$/,
          t("form.passMatch")
        ),
    }),
    onSubmit: (values: IUserLoginEmail) => {
      onSuccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="mt-12">
      {/* email input */}
      <div className="qp-cont-inp">
        {formik.touched.email && formik.errors.email && (
          <div className="qp-inp-error">{formik.errors.email}</div>
        )}
        <Input
          label={t("form.email")}
          type="email"
          placeholder={t("form.email")}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          haveError={formik.touched.email && formik.errors.email}
        />
      </div>
      {/* password */}
      <div className="qp-cont-inp">
        {formik.touched.password && formik.errors.password && (
          <div className="qp-inp-error">{formik.errors.password}</div>
        )}
        <PasswordInput
          label={t("form.password")}
          placeholder={t("form.password")}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          haveError={formik.touched.password && formik.errors.password}
          extraClass={`password`}
        />
        <Link
          to="/login/forgot-password"
          className="block link text-right mt-2"
        >
          {t("form.forgotPass")}
        </Link>
      </div>
      <button type="submit" className="qp-btn qp-btn-outline mt-16">
        {t("form.login")}
      </button>
    </form>
  );
};

export default LoginForm;
