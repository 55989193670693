import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../components/auth/registration/Sidebar";


const OutletAuth = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      className={`${
        pathname !== "/registration" ? "mt-16" : ""
      } flex justify-center h-7`}
    >
      {pathname !== "/registration" && <Sidebar />}
      <div
        className={
          pathname !== "/registration"
            ? "registration-forms-container lg:mr-44"
            : "registration-forms-container"
        }
      >
        <div className="login-container-qp">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default OutletAuth;
