import { getStatesOfCountry } from "country-state-city/lib/state";
import Select from "./Select";
import { selectStatesProps } from "../interfaces/formInterface";

const SelectStatesUS = (props: selectStatesProps) => {
  const {
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    reference,
    disabled,
    label
  } = props;
  const states = getStatesOfCountry("US");
  const usableStates = states.map((state, index) => ({
    id: index,
    code: state.isoCode,
    value: state.name,
  }));

  const removeStates = (states: any) => {
    return (
      states.code !== "AS" &&
      states.code !== "GU" &&
      states.code !== "MP" &&
      states.code !== "PR" &&
      states.code !== "VI" &&
      states.code !== "UM" &&
      states.code !== "UM-81" &&
      states.code !== "UM-84" &&
      states.code !== "UM-86" &&
      states.code !== "UM-67" &&
      states.code !== "UM-89" &&
      states.code !== "UM-71" &&
      states.code !== "UM-76" &&
      states.code !== "UM-95" &&
      states.code !== "UM-79"
    );
  };

  const getFiftyStates = usableStates.filter(removeStates);

  return (
    <>
      <div className="text-left">{label}</div>
      <Select
        name={name}
        options={getFiftyStates}
        onChange={onChange}
        onBlur={onBlur}
        keyValue="code"
        value={value}
        placeholder={placeholder}
        haveError={haveError}
        extraClass={extraClass}
        reference={reference}
        disabled={disabled}
      />
    </>
  );
};

export default SelectStatesUS;
