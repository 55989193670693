import { createSlice } from "@reduxjs/toolkit";
import { TransactionsState } from "./state.types";

const initialState: TransactionsState = {
  transactions: [],
  max_amount: 0,
  min_amount: 0,
  transactionConsulted: false,
  totalPages: 0,
  filters: {
    startDate: null,
    endDate: null,
    lessAmount: null,
    greaterAmount: null,
    page: 1
  },
  transactionDetail: ''
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      if (action.payload.hasOwnProperty("transactions")) {
        state.transactions = action.payload.transactions;
        state.totalPages = action.payload.number_pages
      } else {
        state.transactions = [];
      }
      if (action.payload.firstGet) {
        state.transactionConsulted = true;
        state.min_amount = action.payload.min_amount;
        state.max_amount = action.payload.max_amount;
        state.filters.lessAmount = action.payload.min_amount;
        state.filters.greaterAmount = action.payload.max_amount;
      }
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setTransactionInList: (state, action) => {
      const transaction = state.transactions.find((t) => t.id === action.payload.id);
      if (transaction) {
        transaction.amount = action.payload.amount;
      }
      return state;
    },
    setTransactionDetail: (state, action) => {
      state.transactionDetail = action.payload;
    }
  },
});

export const { setTransactions, setFilters, setTransactionInList } = transactionSlice.actions;

export default transactionSlice.reducer;
