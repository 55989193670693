import { Icon } from '@qp/ui';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactSwitch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ShippingFormTestI, ShippingGetData} from '../../../interfaces/uploadInterface';
import { createShippingValues, deleteShippingValues, getShippingValues } from '../../../services/settingsService';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setLoading, setModalMessage } from '../../../store/shared.slice';
import { setAddIndividualShippingData, setShippingData } from '../../../store/profile.slice';
import ShippingRow from './ShippingRow';
import { useTranslation } from 'react-i18next';

const ShippingForm = (props: ShippingFormTestI ) => {
  const { onSuccess } = props;
  const { t } = useTranslation(["settings"]);
  const [changedData, setChangedData]= useState<boolean>(false);
  const shippingData = useAppSelector((state) => state.profile.shippingData)
  let dataUpdated: any = []
  const [checked, setChecked] = useState<boolean>(true);
  const dispatch = useAppDispatch()
  const handleConfirm = (nextChecked: boolean) => {
    setChecked(nextChecked);
  };

  const deleteAction = (nextChecked: any) => {
    dispatch(setLoading(true))
    deleteShippingValues().then(() => {
      dispatch(setLoading(false))
      dispatch(setShippingData([]))
      handleConfirm(nextChecked)
    }).catch((err) => {
      dispatch(setLoading(false))
      dispatch(
        setModalMessage({
          title: t("shipping.errTitle"),
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: t("shipping.ok"),
        })
      );
    });
  };

  const handleChange = (nextChecked: any) => {
    if (formik.values.shippingValues[0].to !== "" || formik.values.shippingValues[0].rate !== ""){
      Swal.fire({
        title: t("shipping.resetConfirmationTitle"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("shipping.yes"),
        confirmButtonColor: "#FFA31C",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteAction(nextChecked)
        }
      });
    } else {
      deleteAction(nextChecked)
    }
  };

  const formik:any = useFormik({
    initialValues: {
      shippingValues: [] as  Array<ShippingGetData>,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      shippingValues: Yup.array().of(
        Yup.object().shape({
          from: Yup.string(),
          to: Yup.string().ensure()
            /*.when('from', {
            is: '',
            then: Yup.string().required("Final value is required")
            /*.matches(
              /((\d+)(\.\d{2}))$/,
              'Missing two decimals',
            ),
            otherwise: Yup.string()
          })
          */
          .test(
            "bigger-number",
            t("shipping.smallValErr"),
            function (value: string){
              return value
                ? Number(value) > Number(this.parent.from)
                : true;
            }
          )
          .test(
            "last-to",
            'To is required',
            function (value: string) {
            var self : any = this;
            return  ( value === '' && (self.options.index < formik.values.shippingValues.length - 1)) ?
            false : true;
            }
          ),
          rate: Yup.string()
          .when('type', {
            is: (type: number) => type ===  1,
            then: Yup.string(),
            otherwise: Yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, t("shipping.maxDecimals"))
            .test("maxLenght", t("shipping.maxErr"), val => Number(val) < 101  ),
          })
          .required(t("shipping.rateReq"))
        }, [['from', 'to'], ['from', 'from'],  ['to', 'to']])
      )
    }),
    onSubmit: (values: any) => {
      onSuccess(values);
      setChangedData(false)
    },
  });

const tip = `
    <div style="width: 100%; height: auto; display: flex; flex-direction: column; align-items: center">
      <div style="font-weight: bold; text-align: start; font-size: 1rem">${t("shipping.instructionsOne")}</div>
      <p>-${t("shipping.instructionsTwo")} <b>${t("shipping.instructionsThree")}</b> ${t("shipping.instructionsFour")}<b>${t("shipping.instructionsThree")}</b> ${t("shipping.instructionsFive")}</p>
    </div>
  `;

  useEffect(() => {
    const updateData = () => {
      const shippingV = shippingData.map((item) => {
        return ({
          from : item.from ? Number(item.from).toFixed(2) : '0.00',
          to: item.to ? Number(item.to).toFixed(2) : '',
          rate: Number(item.rate).toFixed(2),
          id: item.id,
          merchantId: item.merchantId,
          type: item.type
        })
      })
      shippingV.sort((a, b) => (Number(a.from) > Number(b.from)) ? 1 : -1)
      dataUpdated = shippingV.sort((a, b) => (Number(a.from) > Number(b.from)) ? 1 : -1)
    }
    updateData()
    formik.setValues({shippingValues:dataUpdated })
  },[shippingData])

  useEffect(() => {
    if (shippingData.length > 0) {
      if (shippingData[0].type === 0) {
        setChecked(false)
      }
    }
  },[shippingData])

  useEffect(() => {
      getShippingValues().then((res: any) => {
        if(res.data.length === 0){
          createShippingValues({from: 0.01, rate: 0.00, type: checked ? 1 : 0, id: '', merchantId: "" }).then((resp) => {
            dispatch(setAddIndividualShippingData(resp.data))
          });
        };
        dispatch(setShippingData(res.data))
      }).catch((err) => {
        if (err.response.status === 404){
          createShippingValues({from: 0.01, rate: 0.00, type: checked ? 1 : 0, id: '', merchantId: "" }).then((res) => {
            dispatch(setAddIndividualShippingData(res.data))
          }).catch((err) => {
            console.error(err)
          })
        }
      })
  },[dispatch, checked])

  return (
    <div className='flex justify-center w-full'>
      <form  onSubmit={formik.handleSubmit} className='w-full'>
        <div className='flex items-start justify-center'>
          <div className="text-2xl md:text-3xl font-medium mr-2">{t("shipping.header")}</div>
          <div className='flex items-end self-end'>
            {/*formik.values.shippingValues.length === 0 && (
              <>
                <ReactTooltip
                    id="add"
                    place="top"
                    className="w-20"
                    type="dark"
                  />
                  <i
                    data-for="add"
                    data-tip="Add"
                    className='self-end'
                  >
                <Icon icon='addSmall' onClick={() => handleAddFormFile(formik.values)} />
                </i>
              </>
            )*/
            }
            <ReactTooltip
              id="shipping-info"
              place="right"
              className="w-80"
              type="dark"
            />
            <i
              data-html={true}
              data-for="shipping-info"
              data-tip={tip}
              className=''
            >
              <Icon icon={"info"} />
            </i>
          </div>
        </div>
        <div className='profile-container flex flex-col items-center'>
          {formik.values.shippingValues?.length > 0 && (
            <div className="flex justify-start w-full" style={{paddingRight: "22px"}}>
              <div className="text-left text-base" style={{width: "30%"}}>{t("shipping.from")}</div>
              <div className="text-left text-base mx-2" style={{width: "30%"}}>{t("shipping.to")}</div>
              <div className="flex justify-start" style={{width: "30%"}}>
                <div className='rate-switch'>
                  <div>{t("shipping.rate")}</div>
                  <ReactSwitch
                    onChange={handleChange}
                    checked={checked}
                    className="ml-2"
                    onColor="#FFA31C"
                    offColor="#C4541B"
                    onHandleColor="#FFFFFF"
                    handleDiameter={10}
                    width={75}
                    activeBoxShadow={'none'}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          fontWeight:"500",
                          color: "white",
                          paddingRight: 2
                        }}
                      >
                        %
                      </div>
                    }
                    checkedIcon={<div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontWeight:"500",
                        fontSize: 15,
                        color: "black",
                        paddingRight: 2
                      }}
                    >
                    USD
                    </div>}
                  />
                </div>
              </div>
              <div style={{width: "8%"}}></div>
            </div>
          )}
          <div className='shipping-container'>
            {formik.values.shippingValues?.map((shipping: any, i: number) => {
              return (
                <ShippingRow key={i} index={i} formik={formik} shipping={shipping} data={shippingData} checked={checked} setChangedData={setChangedData} />
              )
            })}
          </div>
          <button type="submit" className={`profile-submit-btn ${!changedData ||  formik.errors.shippingValues ? "opacity-70" : "opacity-100"}`} disabled={!changedData || formik.errors.shippingValues}>
            {t("shipping.saveBtn")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShippingForm;