import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { NotFoundPage } from "@qp/ui";
import SignUpEmail from "../screens/auth/SignUpEmail";
import OutletDash from "../screens/dash/OutletDash";
import Transactions from "../screens/dash/tabs/Transactions";
import Customers from "../screens/dash/tabs/Customers";
import Loyalty from "../screens/dash/tabs/Loyalty";
import GenerateButton from "../screens/dash/GenerateButton";
import OutletTransactions from "../screens/dash/transaction/OutletTransactions";
import Refund from "../screens/dash/transaction/Refund";
import Detail from "../screens/dash/transaction/Detail";
import Otp from "../screens/auth/oldAuth/Otp";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import SignUp from "../screens/auth/oldAuth/SignUp";
import CreateAddress from "../screens/dash/address/CreateAddress";
import EditAddress from "../screens/dash/address/EditAddress";
import OutletAddress from "../screens/dash/address/OutletAddress";
import { useAppSelector } from "../hooks/storeHooks";
import BusinessType from "../screens/auth/registration/BusinessType";
import BusinessDetails from "../screens/auth/registration/BusinessDetails";
import BusinessRepresentative from "../screens/auth/registration/BusinessRepresentative";
import OutletAuth from "../screens/auth/OutletAuth";
import BusinessOwner from "../screens/auth/registration/BusinessOwner";
import BankDetails from "../screens/auth/registration/BankDetails";
import Summary from "../screens/auth/registration/Summary";
import AddBusinessOwners from "../screens/auth/registration/AddBusinessOwners";
import LoginEmail from "../screens/auth/LoginEmail";
import RecoveryPass from "../screens/auth/RecoveryPass";
import ConfirmPass from "../screens/auth/oldAuth/ConfirmPass";
import ChangePass from "../screens/auth/ChangePass";
import Profile from "../screens/dash/tabs/Profile";
import Address from "../components/dash/profile/Address";
import Upload from "../components/dash/profile/Upload";
import Settings from "../screens/dash/tabs/Settings";
import Layout from "../components/layout/Layout";

const Router = () => {
  const { token } = useAppSelector((state) => state.auth);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              disableHeader={token ? true : false}
            />
          }
        >
          <Route index element={<PublicRoute component={LoginEmail} />} />
          <Route path="/registration" element={<OutletAuth />}>
            <Route index element={<SignUpEmail />} />
            <Route path="business-type" element={<BusinessType />} />
            <Route path="business-details" element={<BusinessDetails />} />
            <Route
              path="business-representative"
              element={<BusinessRepresentative />}
            />
            <Route path="add-business-owners" element={<AddBusinessOwners />} />
            <Route path="business-owners" element={<BusinessOwner />} />
            <Route path="bank-details" element={<BankDetails />} />
            <Route path="summary" element={<Summary />} />
          </Route>
          {/* <Route path="/login" element={<LoginEmail />} /> */}
          <Route path="/login/forgot-password" element={<RecoveryPass confirmAcc={false} />} />
          <Route path="/login/confirm-account" element={<RecoveryPass confirmAcc={true} />} />
          <Route path="/login/confirm-password" element={<ConfirmPass />} />
          <Route path="/login/change-password" element={<ChangePass />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/validate-number" element={<Otp />} />
          <Route path="/dash" element={<PrivateRoute component={OutletDash} />}>
            <Route index element={<Navigate to="transactions" />} />
            <Route path="transactions" element={<OutletTransactions />}>
              <Route index element={<Transactions />} />
              <Route path=":id" element={<Detail />} />
              <Route path="refund/:id" element={<Refund />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route path="profile" element={<Profile />} />
            <Route path="address" element={<OutletAddress />}>
              <Route index element={<Address />} />
              <Route path="create" element={<CreateAddress />} />
              <Route path="edit/:id" element={<EditAddress />} />
            </Route>
            <Route path="customers" element={<Customers />} />
            <Route path="loyalty" element={<Loyalty />} />
            <Route path="upload-logo" element={<Upload />} />
            <Route path="generate-button" element={<GenerateButton />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
