import { ShipingValuesGeneralI, ShippingGetData, ShippingValuesI, TaxIndividualI } from "../interfaces/uploadInterface";
import axiosCLient from "./axios";

export const secretKeys = async () => {
  let secretKeys = await axiosCLient.get(`merchants/keys`);
  return secretKeys;
}

export const getNewSecretKeys = async () => {
  let newSecretKeys = await axiosCLient.post(`merchants/keys`);
  return newSecretKeys;
}

export const redirectUrls = async () => {
  let redirectUrls = await axiosCLient.get(`merchants/settings`);
  return redirectUrls;
}

export const postRedirectUrls = async (props: {successfulTransactionUrl: string, failedTransactionUrl: string}) => {
  let createdUrls = await axiosCLient.post(`merchants/settings`, {successfulTransactionUrl: props.successfulTransactionUrl.toString(), failedTransactionUrl: props.failedTransactionUrl.toString()});
  return createdUrls;
}

export const editRedirectUrls = async (props: {successfulTransactionUrl: string, failedTransactionUrl: string}) => {
  let createdUrls = await axiosCLient.put(`merchants/settings`, {successfulTransactionUrl: props.successfulTransactionUrl.toString(), failedTransactionUrl: props.failedTransactionUrl.toString()});
  return createdUrls;
}

export const getShippingValues = () => axiosCLient.get("merchants/shippings");

export const createShippingValues = (data: ShippingGetData) => axiosCLient.post("merchants/shippings", data);

export const deleteShippingValues = (id?: string) => axiosCLient.delete(`merchants/shippings/${id ? id : ""}`);

export const updateShippingValues = (data: ShippingGetData) => axiosCLient.put("merchants/shippings", data);

export const getTaxesValues = () => axiosCLient.get("merchants/taxes");

export const updateTaxesValues = (id: string | undefined | null, rate: number) => axiosCLient.put("merchants/taxes", {id, rate});

export const createCustomTax = (taxId: string, rate: number | undefined) => axiosCLient.post("merchants/taxes", {taxId, rate})
