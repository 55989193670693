import { useFormik } from "formik";
import * as Yup from "yup";
import { businessTypeProps } from "../../../interfaces/newLoginInterface";
import { useTranslation } from "react-i18next";

const BusinessTypeForm = (props: businessTypeProps) => {
  const { onSuccess } = props;
  const { t } = useTranslation(['registration']);
  const getBusinessStructure = JSON.parse(window.localStorage.getItem("businessType") || "");
  const getBusiness = JSON.parse(window.localStorage.getItem("business") || "");
  const businessTypes = [
    { value: t("businessTypeForm.nonProfit"), id: 1 },
    { value: t("businessTypeForm.individual"), id: 2 },
    { value: t("businessTypeForm.company"), id: 3 },
  ];
  const businessStructure = [
    { name: t("businessTypeForm.companyTypeOne"), value: "Sole propietorship", id: 1 },
    { name: t("businessTypeForm.companyTypeTwo"), value: "Single-member LLC", id: 2 },
    { name: t("businessTypeForm.companyTypeThree"), value: "llc", id: 3 },
    { name: t("businessTypeForm.companyTypeFour"), value: "partnership", id: 4 },
    { name: t("businessTypeForm.companyTypeFive"), value: "corporation", id: 5 },
  ];
  const formik = useFormik({
    initialValues: {
      businessTypes: getBusiness || "",
      businessStructure: getBusinessStructure || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      businessTypes: Yup.string().required(t("businessTypeForm.busTypeReq")),
      businessStructure: Yup.string().when("businessTypes", {
        is: "Company",
        then: Yup.string().required(t("businessTypeForm.busStructureReq")),
      }),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="mt-6">
      <div className="w-full">
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessTypeForm.busType")}</div>
          {formik.touched.businessTypes && formik.errors.businessTypes && (
            <div className="qp-inp-error">{formik.errors.businessTypes}</div>
          )}
          <select
            name="businessTypes"
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            value={formik.values.businessTypes}
            className={`${formik.touched.businessTypes &&
                formik.errors.businessTypes !== undefined
                ? "error"
                : ""
              } qp-input`}
          >
            <option value="">{t("businessTypeForm.selectBusType")}</option>
            {businessTypes.map((option: any) => {
              return (
                <option value={option.value} key={option.id}>
                  {option.value}
                </option>
              );
            })}
          </select>
        </div>
        {formik.values.businessTypes === "Company" && (
          <div className="qp-cont-inp">
            <div className="text-left">{t("businessTypeForm.busStructure")}</div>
            {formik.touched.businessStructure &&
              formik.errors.businessStructure && (
                <div className="qp-inp-error">
                  {formik.errors.businessStructure}
                </div>
              )}
            <select
              name="businessStructure"
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              value={formik.values.businessStructure}
              className={`${formik.touched.businessStructure &&
                  formik.errors.businessStructure !== undefined
                  ? "error"
                  : ""
                } qp-input`}
            >
              <option value="">{t("businessTypeForm.selectBusStructure")}</option>
              {businessStructure.map((option: any) => {
                return (
                  <option value={option.value} key={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="qp-cont-inp">
          <hr />
        </div>
        <button type="submit" className="qp-btn md:w-6/12 w-full mt-6">
          {t("businessTypeForm.continueBtn")}
        </button>
      </div>
    </form>
  );
};

export default BusinessTypeForm;