import { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Input } from "@qp/ui";
import { useTranslation } from "react-i18next";
import { otpFormProps } from "../../interfaces/authInterface";

const OtpForm = (props: otpFormProps) => {
  const { onSuccess, showButtons, customButtons } = props;
  const n1 = useRef<HTMLInputElement>(null);
  const n2 = useRef<HTMLInputElement>(null);
  const n3 = useRef<HTMLInputElement>(null);
  const n4 = useRef<HTMLInputElement>(null);
  const n5 = useRef<HTMLInputElement>(null);
  const n6 = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(["login"]);

  const formik = useFormik({
    initialValues: {
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
    },
    validationSchema: Yup.object({
      num1: Yup.string().required(t("otp.req")),
      num2: Yup.string().required(t("otp.req")),
      num3: Yup.string().required(t("otp.req")),
      num4: Yup.string().required(t("otp.req")),
      num5: Yup.string().required(t("otp.req")),
      num6: Yup.string().required(t("otp.req")),
    }),
    onSubmit: (values) => {
      let number = `${values.num1}${values.num2}${values.num3}${values.num4}${values.num5}${values.num6}`;
      onSuccess(number);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex items-center justify-center">
          <Input
            type="number"
            name="num1"
            value={formik.values.num1}
            onChange={(e) => {
              if (e.target.value.length === 0) formik.handleChange(e);
              if (e.target.value.length === 1) {
                formik.handleChange(e);
                n2.current!.focus();
              }
              if (e.target.value.length > 1) n2.current!.focus();
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n1}
          />
          <Input
            type="number"
            name="num2"
            value={formik.values.num2}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                formik.handleChange(e);
                n1.current!.focus();
              }
              if (e.target.value.length === 1) {
                formik.handleChange(e);
                n3.current!.focus();
              }
              if (e.target.value.length > 1) n3.current!.focus();
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n2}
          />
          <Input
            type="number"
            name="num3"
            value={formik.values.num3}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                formik.handleChange(e);
                n2.current!.focus();
              }
              if (e.target.value.length === 1) {
                formik.handleChange(e);
                n4.current!.focus();
              }
              if (e.target.value.length > 1) n4.current!.focus();
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n3}
          />
          <Input
            type="number"
            name="num4"
            value={formik.values.num4}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                formik.handleChange(e);
                n3.current!.focus();
              }
              if (e.target.value.length === 1) {
                formik.handleChange(e);
                n5.current!.focus();
              }
              if (e.target.value.length > 1) n5.current!.focus();
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n4}
          />
          <Input
            type="number"
            name="num5"
            value={formik.values.num5}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                formik.handleChange(e);
                n4.current!.focus();
              }
              if (e.target.value.length === 1) {
                formik.handleChange(e);
                n6.current!.focus();
              }
              if (e.target.value.length > 1) n6.current!.focus();
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n5}
          />
          <Input
            type="number"
            name="num6"
            value={formik.values.num6}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                formik.handleChange(e);
                n5.current!.focus();
              }
              if (e.target.value.length === 1) {
                formik.handleChange(e);
              }
              if (e.target.value.length > 1) return;
            }}
            onBlur={formik.handleBlur}
            extraClass="qp-inp-code"
            reference={n6}
          />
        </div>
        {showButtons && (
          <div className="flex items-center justify-between md:w-8/12 mx-auto mt-8">
            <button className="link-phone">{t("otp.newCode")}</button>
            <Link to="/" className="link-phone">
              {t("otp.newNumber")}
            </Link>
          </div>
        )}
        {customButtons && customButtons}
        <button type="submit" className="qp-btn mt-16">
          {t("otp.next")}
        </button>
      </form>
    </div>
  );
};

export default OtpForm;