import { Input } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { setExpirationProps } from "../../../interfaces/loyaltyInterface";
import { useAppSelector } from "../../../hooks/storeHooks";

const SetExpiration = (props: setExpirationProps) => {
  const { t } = useTranslation(["loyalty"]);
  const { onSuccess } = props;
  const { expirationDays } = useAppSelector((state) => state.loyalty);
  const formik = useFormik({
    initialValues: {
      expirationDays: expirationDays.toString() === '0' ? "" : expirationDays,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      expirationDays: Yup.number()
        .min(0, t("setExpiration.expMin"))
        .max(730, t("setExpiration.expMax"))
        .required(t("setExpiration.expReq")),
    }),
    onSubmit: (values) => {
      onSuccess(values.expirationDays);
    },
  });

  return (
    <>
      <div className="text-2xl md:text-3xl font-medium">{t("setExpiration.titleSetExpiration")}</div>
      <form
        className="profile-container"
        onSubmit={formik.handleSubmit}
      >
        {/* expiration loyalty */}
        <div className="qp-cont-inp flex flex-col items-center">
          {formik.touched.expirationDays && formik.errors.expirationDays && (
            <div className="w-full md:w-4/6 qp-inp-error">{formik.errors.expirationDays}</div>
          )}
          <Input
            extraClassLabel="w-full md:w-4/6"
            label={t("setExpiration.placeholder")}
            extraClass="w-full md:w-4/6"
            placeholder={t("setExpiration.placeholder")}
            type="number"
            name="expirationDays"
            value={formik.values.expirationDays}
            onChange={(e) => formik.handleChange(e)}
            extraProps={{
              min: 0,
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9.]/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <button type="submit" className="profile-submit-btn">
          {t("setExpiration.buttonSetExpiration")}
        </button>
      </form>
    </>
  );
};

export default SetExpiration;
