import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, PasswordInput } from "@qp/ui";
import { stepOneProps } from "../../interfaces/authInterface";
import { IUserLoginEmail } from "../../interfaces/loginInterface";
import { emailExist } from "../../services/authService";
import { setLoading, setModalMessage } from "../../store/shared.slice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignUpEmailForm = (props: stepOneProps) => {
  const { onSuccess } = props;
  const { t } = useTranslation(["registration"]);
  const [exist, setExist] = useState(false);
  const userSaved: IUserLoginEmail = localStorage["qp-signupUser"]
    ? JSON.parse(window.localStorage.getItem("qp-signupUser") || "")
    : {};
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: userSaved?.email || "",
      password: userSaved?.password || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("signUpEmailForm.validEmail"))
        .test('specialChar',  t("signUpEmailForm.emailCharacters"), (string: any) => {
          const specialChars = /^[-_.@A-Za-z0-9]*$/;
          if (specialChars.test(string)) return true
          else return false;
        })
        .required(t("signUpEmailForm.emailReq")),
      password: Yup.string()
        .required(t("signUpEmailForm.passReq"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^.()])[A-Za-z\d@$!%*?&#^.()]{12,}$/,
          t("signUpEmailForm.passMatch")
        ),
    }),
    onSubmit: (values: IUserLoginEmail) => {
      onSuccess(values);
    },
  });

  const dispatch = useAppDispatch();
  const handleBlur = (e: any) => {
    if (!formik.errors.email) {
      dispatch(setLoading(true));
      emailExist(formik.values.email).then((res) => {
        dispatch(setLoading(false));
        if (res.data) {
          dispatch(
            setModalMessage({
              title: "Error",
              text: t("signUpEmailForm.errText"),
              icon: "error",
              confirmButtonText: t("signUpEmailForm.ok"),
            })
          );
          setExist(true);
          navigate("/");
        } else {
          setExist(false);
          dispatch(setLoading(false));
        }
      });
    }
    formik.handleBlur(e);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="mt-12">
      <div className="w-full">
        <div className="qp-cont-inp">
          {formik.touched.email && formik.errors.email && (
            <div className="qp-inp-error">{formik.errors.email}</div>
          )}
          <Input
            label={t("signUpEmailForm.email")}
            type="email"
            name="email"
            placeholder={t("signUpEmailForm.email")}
            value={formik.values.email}
            onBlur={handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="qp-cont-inp">
          {formik.touched.password && formik.errors.password && (
            <div className="qp-inp-error">{formik.errors.password}</div>
          )}
          <PasswordInput
            label={t("signUpEmailForm.password")}
            name="password"
            placeholder={t("signUpEmailForm.password")}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            haveError={formik.touched.password && formik.errors.password}
          />
        </div>
        <hr className="mt-12" />
        <button
          type="submit"
          className={`qp-btn mt-8 md:w-6/12 w-full ${exist ? "disabled" : ""}`}
          disabled={exist}
        >
          {t("signUpEmailForm.continueBtn")}
        </button>
      </div>
    </form>
  );
};

export default SignUpEmailForm;
