import {
  Action,
  configureStore,
  ThunkAction,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import accountsMerchantSlice from "./accountsMerchant.slice";
import addressSlice from "./address.slice";
import authSlice from "./auth.slice";
import businessFlowSlice from "./businessFlow.slice";
import customersSlice from "./customers.slice";
import loyaltySlice from "./loyalty.slice";
import sharedSlice from "./shared.slice";
import transactionsSlice from "./transactions.slice";
import profileSlice from "./profile.slice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    shared: sharedSlice,
    transaction: transactionsSlice,
    address: addressSlice,
    loyalty: loyaltySlice,
    customer: customersSlice,
    businessFlow: businessFlowSlice,
    accounts: accountsMerchantSlice,
    profile: profileSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
