import React from "react";
import { useNavigate } from "react-router-dom";
import { newLoginHeaderProps } from "../interfaces/layoutInterface";

const NewLoginHeader = (props: newLoginHeaderProps) => {
  const { titleHeader, showBackButton } = props;
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-center items-center h-7 relative">
        {showBackButton && (
          <div className="back absolute left-0" onClick={() => navigate(-1)}>
            <div className="mr-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
                  fill="#C4541B"
                />
              </svg>
            </div>
          </div>
        )}
        <h2 className="flex items-center">{<span className="sidebar-header" dangerouslySetInnerHTML={{ __html: titleHeader }} />}</h2>
      </div>
    </div>
  );
};

export default NewLoginHeader;
