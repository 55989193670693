import { createSlice } from "@reduxjs/toolkit";
import { LoyaltyState } from "./state.types";

const initialState: LoyaltyState = {
  loyalty: 0,
  expirationDays: "",
};

const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    setLoyalty: (state, action) => {
      state.loyalty = action.payload;
    },
    setExpirationDate: (state, action) => {
      state.expirationDays = action.payload;
    },
  },
});

export const { setLoyalty, setExpirationDate } = loyaltySlice.actions;

export default loyaltySlice.reducer;
