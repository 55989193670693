import { Outlet, useLocation } from "react-router-dom";
import HeaderDash from "../../components/dash/HeaderDash";

const OutletDash = () => {
  const location = useLocation();
  return (
    <>
      <HeaderDash />
      <div
        className={`container-qp container-dash-merchant${
          location.pathname === "/dash/transactions" ? " list-transaction" : ""
        }`}
      >
        <Outlet />
      </div>
    </>
  );
};

export default OutletDash;
