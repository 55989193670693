import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import RefundForm from "../../../components/dash/transactions/RefundForm";
import { useAppDispatch } from "../../../hooks/storeHooks";
import {
  getTransactionDetail,
  refundTransactionService,
} from "../../../services/transactionsService";
import { setLoading, setModalMessage } from "../../../store/shared.slice";

const Refund = () => {
  const { t } = useTranslation(["transaction"]);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<any>(null);

  const handleSuccess = async (values: any) => {
    if (id) {
      dispatch(setLoading(true));
      await refundTransactionService({
        id: id,
        amount: values.amount,
        reason: values.reason,
      })
        .then((res) => {
          if (transaction?.sub_total) {
            dispatch(setLoading(false));
            if (res.data.message === "Transaction refunded successfully.") {
              dispatch(
                setModalMessage({
                  title: t("doneRefundTitle"),
                  text: t("doneRefundText"),
                  icon: "success",
                  confirmButtonText: t("ok"),
                })
              );
              navigate("/dash/transactions");
            }
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("errorRefundTitle"),
              text: t("errorRefundText"),
              icon: "error",
              confirmButtonText: t("ok"),
            })
          );
        });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      getTransactionDetail(id)
        .then((res) => {
          setTransaction(res.data);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  }, [id, dispatch]);

  return (
    <div className="w-full">
      <h2>{t("refundForm.title")}</h2>
      <RefundForm onSuccess={handleSuccess} transaction={transaction} />
    </div>
  );
};

export default Refund;
