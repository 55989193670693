import { logoutUser, refreshToken } from "../services/authService";
import axiosCLient from "../services/axios";
import { setToken } from "../store/auth.slice";

const setup = (store: any) => {
  axiosCLient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response.status === 401) {
        localStorage.removeItem("qp-auth-token");
        logoutUser().then(() => {
          window.location.reload();
        });
        // if (store.getState().auth.token && !originalConfig._retry) {
        //   await refreshToken()
        //     .then((res: any) => {
        //       store.dispatch(setToken(res.data.access_token));
        //       localStorage.setItem("qp-auth-token", JSON.stringify(res.data));
        //       originalConfig._retry = true;
        //       window.location.reload();
        //       return axiosCLient(originalConfig);
        //     })
        //     .catch((err) => {
        //       console.error("error ", err);
        //       localStorage.removeItem("qp-auth-token");
        //       window.location.reload();
        //     });
        // }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
