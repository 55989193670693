import {useEffect, useState } from 'react'
import { Icon, Input } from '@qp/ui'
import { useAppDispatch } from '../../../hooks/storeHooks';
import { setLoading, setModalMessage } from '../../../store/shared.slice';
import Swal from "sweetalert2";
import ReactTooltip from 'react-tooltip';
import { getNewSecretKeys, secretKeys } from '../../../services/settingsService';
import { useTranslation } from 'react-i18next';

const Secretkeys = () => {
  const [visibleClient, setVisibleClient] = useState(false);
  const [visibleId, setVisibleId] = useState(false);
  const [secretClient, setSecretClient] = useState('');
  const [secretId, setSecretId] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["settings"]);

  const handleGetNewKeys = async() => {
    if(secretClient !== "" && secretId !== ""){
      Swal.fire({
        title: t("secretKeys.changeCurrentTitle"),
        text: t("secretKeys.changeCurrentText"),
        icon: "warning",
        confirmButtonText: t("secretKeys.changeCurrentYes"),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          newSecretKeys()
          Swal.fire({
            title: t("secretKeys.doneTitle"),
            text: t("secretKeys.doneChangeText"),
            icon: "success",
            confirmButtonText: t("secretKeys.ok"),
          })
        }
      });
    } else {
      Swal.fire({
        title: t("secretKeys.createNewTitle"),
        text: t("secretKeys.createNewText"),
        icon: "warning",
        confirmButtonText: t("secretKeys.createNewYes"),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          newSecretKeys()
          Swal.fire({
            title: t("secretKeys.doneTitle"),
            text: t("secretKeys.doneNewText"),
            icon: "success",
            confirmButtonText: t("secretKeys.ok"),
          })
        }
      });
    };
  };

  const newSecretKeys = async () => {
    dispatch(setLoading(true));
    await getNewSecretKeys().then((res) => {
      if(res.status === 200) {
        setSecretClient(res.data.clientSecret)
        setSecretId(res.data.clientId);
      }
    }).catch((err) => {
      setModalMessage({
        title: t("secretKeys.errTitle"),
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: t("secretKeys.ok"),
      })
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    const getSecretKeys = async () => {
      dispatch(setLoading(true));
      await secretKeys().then((res: any) => {
        if (res.status === 200 ) {
          setSecretClient(res.data.clientSecret)
          setSecretId(res.data.clientId);
        }
      }).catch((err: any) => {
        console.error(err)
      });
      dispatch(setLoading(false));
    }
    getSecretKeys()
  },[dispatch])

  return (
    <div className='w-full mb-8'>
      <div className="text-2xl md:text-3xl font-medium">{t("secretKeys.header")}</div>
      <div className='profile-container flex flex-col items-center'>
        {secretId && (
          <div className='flex flex-col w-full mb-4 md:w-3/4'>
            <span className='text-left text-base'>{t("secretKeys.clientId")}</span>
            <div className='relative'>
              <Input name="id-secret" type={visibleId ? "text" : "password"} disabled={true} value={secretId} extraClass={`merchant-keys-input ${visibleId ? "text-base" : "text-xs"}`}>
                {secretId}
              </Input>
              <Icon
                icon={`${visibleId ? 'visible' : 'not-visible'}`}
                onClick={() => setVisibleId((visibleId) => !visibleId)}
                extraClass="secret-icon-visible"
              />
              <ReactTooltip
                id="copy"
                place="top"
                className="w-20"
                type="dark"
              />
              <i
                data-for="copy"
                data-tip={t("secretKeys.copy")}
              >
              <Icon
                icon="copy"
                onClick={() =>  navigator.clipboard.writeText(secretId)}
                extraClass="secret-icon-copy"
              />
            </i>
            </div>
          </div>
        )}
        {secretClient && (
          <div className='flex flex-col mt-4 w-full md:w-3/4'>
            <span className='text-left text-base'>{t("secretKeys.clientSecret")}</span>
            <div className='relative'>
              <Input name="client-secret" type={visibleClient ? "text" : "password"} disabled={true} value={secretClient} extraClass={`merchant-keys-input ${visibleClient ? "text-base" : "text-xs"}`}/>
              <Icon
                icon={`${visibleClient ? 'visible' : 'not-visible'}`}
                onClick={() => setVisibleClient((visibleClient) => !visibleClient)}
                extraClass="secret-icon-visible"
              />
              <ReactTooltip
                id="copy"
                place="top"
                className="w-20"
                type="dark"
              />
              <i
                data-for="copy"
                data-tip="Copy"
              >
                <Icon
                  icon="copy"
                  onClick={() =>  navigator.clipboard.writeText(secretClient)}
                  extraClass="secret-icon-copy"
                />
              </i>
            </div>
          </div>
        )}
        <button type="submit" className="profile-submit-btn" onClick={() => handleGetNewKeys()}>
          {`${secretClient && secretId ? t("secretKeys.genNewBtn") : t("secretKeys.genBtn")}`}
        </button>
      </div>
    </div>
  );
};

export default Secretkeys;