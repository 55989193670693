import { Loading } from "@qp/ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  deleteAddress,
  getAddress,
  setPrincipalAddress,
} from "../../../services/addressService";
import { ItemAddress } from "./ItemAddress";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import {
  addressClickProps,
  addressProps,
} from "../../../interfaces/addressInterface";

const ListAddress = () => {
  const listAddress = useAppSelector((state) => state.address.address);
  const isLoadPrincipalAddress = useAppSelector(
    (state) => state.address.isLoadPrincipalAddress
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["profile"]);

  useEffect(() => {
    dispatch(getAddress());
  }, [dispatch]);

  const handleClick = (payload: addressClickProps) => {
    switch (payload.type) {
      case "principal":
        setPrincipalAddress(payload.id)
          .then(() => dispatch(getAddress()))
          .catch((err) => {});
        break;
      case "edit":
        navigate("/dash/address/edit/" + payload.id);
        break;
      case "delete":
        Swal.fire({
          title: t("address.deleteConfirmationTitle"),
          text: t("address.deleteConfirmationText"),
          icon: "warning",
          showCancelButton: true,
        }).then((result) => {
          let principal = listAddress.filter(
            (item: addressProps) => item.selected === true
          );
          if (result.isConfirmed) {
            if (principal && principal[0].id === payload.id) {
              Swal.fire({
                title: "Error",
                text: t("address.deleteErr"),
                icon: "error",
              });
            } else {
              deleteAddress(payload.id)
                .then(() => dispatch(getAddress()))
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        });
        break;
    };
  };

  const renderListAddress = () => {
    let list = listAddress.map((a: any) => {
      let r = Object.assign({}, a);
      return (
        <ItemAddress data={r} total={listAddress.length} key={r.id} onIconClick={(e) => handleClick(e)} />
      );
    });
    return list;
  };

  return (
    <div className="address-container flex flex-col items-center">
      {isLoadPrincipalAddress ? (
        <Loading visible={true} type="local" />
      ) : listAddress.length > 0 ? (
        renderListAddress()
      ) : (
        <div className="text-center my-16">{t("address.notAddress")}</div>
      )}
    </div>
  );
};

export default ListAddress;
