import { useFormik } from 'formik';
import * as Yup from "yup";
import chase from '../../../img/chase.png';
import bankofamerica from '../../../img/bankofamerica.png';
import wellsfargo from '../../../img/wellsfargo.png';
import usbank from '../../../img/usbank.png';
import capitalone from '../../../img/capitalone.png';
import siliconvalleybank from '../../../img/siliconvalleybank.png';
import { bankDetailsFormProps } from '../../../interfaces/newLoginInterface';
import QpPlaidLink from '../../plaid/QpPlaidLinkMerchant';
import { savePlaidAccounts } from '../../../services/plaid';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { useNavigate } from 'react-router-dom';

const BankDetailsForm = (props: bankDetailsFormProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      searchWord: props.searchWord ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      searchWord: Yup.string()
    }),
    onSubmit: () => {
    },
  });

  const banks = [
    { name: chase, id: 1 },
    { name: bankofamerica, id: 2 },
    { name: wellsfargo, id: 3 },
  ];

  const banks2 = [
    { name: usbank, id: 4 },
    { name: capitalone, id: 5 },
    { name: siliconvalleybank, id: 6 }
  ];

  const handleSuccess = async (e: any) => {
    dispatch(savePlaidAccounts(e, true, (isSuccess => {
      if (isSuccess === "fulfilled") {
        navigate('/')
      }
    })));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex'>
        {banks.map((bank) => (
          <div className="card-banks flex-1" key={bank.id}>
            <img src={bank.name} alt="bank" />
          </div>
        ))}
      </div>
      <div className='flex'>
        {banks2.map((bank) => (
          <div className="card-banks flex-1" key={bank.id}>
            <img src={bank.name} alt="bank" />
          </div>
        ))}
      </div>
      <div className="qp-cont-inp">
        <QpPlaidLink onAddAccount={(e: any) => handleSuccess(e)} isEditing={false} className="qp-btn mt-6" />
      </div>
      <br />
      <br />
    </form>
  );
};

export default BankDetailsForm;