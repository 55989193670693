import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";
import { TaxesFormFormI, TaxIndividualI } from '../../../interfaces/uploadInterface';
import TaxRow from './TaxRow';
import { useTranslation } from 'react-i18next';


const TaxesForm = (props: TaxesFormFormI) => {
  const { onSuccess, data } = props;
  const [changedData, setChangedData]= useState<boolean>(false);
  const { t } = useTranslation(["settings"]);

  const formik = useFormik({
    initialValues: {
      taxesValues: data ? data : [] as  Array<TaxIndividualI>,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      taxesValues: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          state: Yup.string(),
          rate: Yup.number(),
          customTax: Yup.object().shape({
            customRate: Yup.string()
            .required(t("taxes.rateReq"))
            .matches(/^\d+(\.\d{1,2})?$/, t("taxes.maxDecimals"))
            .test("maxLenght", t("taxes.maxErr"), val => Number(val) < 101  ),
            merchantTaxId: Yup.string()
          })
        }),
      ),
    }),
    onSubmit: (values: any) => {
      onSuccess(values);
      setChangedData(false);
    },
  });

  useEffect(() => {
    const updateData = () => {
      const formatTaxes = data.map((tax: TaxIndividualI) => {
        return ({
          id: tax.id,
          state: tax.state,
          rate: Number(tax.rate).toFixed(2).toString(),
          customTax: { merchantTaxId: tax.customTax?.merchantTaxId, customRate: tax.customTax?.merchantTaxId !== "" ? Number(tax.customTax?.customRate).toFixed(2) : Number(tax.rate).toFixed(2)}
        })
      })
      formik.setValues({taxesValues: formatTaxes })
    }
    updateData()
  },[data])


  return (
    <div className='flex justify-center w-full'>
      <form  onSubmit={formik.handleSubmit} className='w-full'>
        <div className='flex flex-col items-center justify-center'>
          <div className="text-2xl md:text-3xl font-medium">{t("taxes.header")}</div>
          <div className='flex flex-col justify-center items-center profile-container'>
            <div className='w-full md:w-3/4'>
              <div className='flex w-full'>
                <div className="text-left text-base w-2/5">{t("taxes.firstCol")}</div>
                <div className="text-left text-base mx-2 w-3/5">{t("taxes.secondCol")}</div>
              </div>
              <div className='taxes-container'>
                {formik.values.taxesValues?.map((tax: TaxIndividualI, i: number) => {
                  return (
                    <TaxRow key={i} index={i} formik={formik} tax={tax} setChangedData={setChangedData} />
                  )}
                )}
              </div>
            </div>
            <button type="submit" className={`profile-submit-btn ${!changedData ? "opacity-70" : "opacity-100"}`} disabled={!changedData}>
              {t("taxes.saveBtn")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaxesForm;