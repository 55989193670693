import React from "react";
import { Input, Select, SelectStatesUS } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  businessRepresentativeFormProps,
  RepresentativeFormInfoProps,
} from "../../../interfaces/newLoginInterface";
import { useAppSelector } from "../../../hooks/storeHooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { days, formatData, months, years } from "../../../utils/birthDateSelect";

const BusinessRepresentativeForm = (props: businessRepresentativeFormProps) => {
  const { t } = useTranslation(['registration']);
  const getPreviousRepresentative: RepresentativeFormInfoProps = JSON.parse(
    window.localStorage.getItem("representativeFormData") || ""
  );

  const businessFlow = useAppSelector(
    (state) => state.businessFlow.businessflow
  );
  const { onSuccess } = props;
  const formik = useFormik({
    initialValues: {
      name: getPreviousRepresentative.name || "",
      last_name: getPreviousRepresentative.last_name || "",
      email: getPreviousRepresentative.email || "",
      dateOfBirth: getPreviousRepresentative.dateOfBirth
        ? moment(getPreviousRepresentative.dateOfBirth).toDate()
        : null,
      title: getPreviousRepresentative.title || "",
      ssn: getPreviousRepresentative.ssn || "",
      businessFlow: getPreviousRepresentative.businessFlow || businessFlow,
      address_value: getPreviousRepresentative.address_value || "",
      city: getPreviousRepresentative.city || "",
      state: getPreviousRepresentative.state || "",
      zip_code: getPreviousRepresentative.state || "",
      country: getPreviousRepresentative.country || "US",
      day: getPreviousRepresentative.day || "",
      month: getPreviousRepresentative.month || "",
      year:getPreviousRepresentative.year || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]*$/, t("businessRepresentativeForm.nameCharacters"))
        .required(t("businessRepresentativeForm.firstNameReq")),
      last_name: Yup.string()
        .matches(/^[A-Za-z\s]*$/, t("businessRepresentativeForm.nameCharacters"))
        .required(t("businessRepresentativeForm.lastNameReq")),
      email: Yup.string()
        .email( t("businessRepresentativeForm.validEmail"))
        .matches(
          /^\s*[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,4}\s*$/,
          t("businessRepresentativeForm.emailCharacters")
        )
        .required(t("businessRepresentativeForm.emailReq")),
      day: Yup.number().required(t("businessRepresentativeForm.dateOfBirthReq")),
      month: Yup.string().required(t("businessRepresentativeForm.dateOfBirthReq")),
      year: Yup.number().required(t("businessRepresentativeForm.dateOfBirthReq")),
      title: Yup.string().required(t("businessRepresentativeForm.jobTitleReq")),
      ssn: Yup.string()
        .min(0o000)
        .max(9999)
        .matches(/^[0-9]{4}$/, t("businessRepresentativeForm.ssnMax"))
        .required(t("businessRepresentativeForm.ssnReq")),
      address_value: Yup.string().when("businessFlow", {
        is: "company",
        then: Yup.string().required(t("businessRepresentativeForm.addReq"))
        .max(50, t("businessDetailsForm.addressMax")),
      }),
      city: Yup.string().when("businessFlow", {
        is: "company",
        then: Yup.string().required(t("businessRepresentativeForm.cityReq")),
      }),
      state: Yup.string().when("businessFlow", {
        is: "company",
        then: Yup.string().required(t("businessRepresentativeForm.stateReq")),
      }),
      zip_code: Yup.string().when("businessFlow", {
        is: "company",
        then: Yup.string().required(t("businessRepresentativeForm.zipReq")),
      }),
      country: Yup.string(),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="mt-12">
      <div className="w-full">
        <div className="qp-cont-inp text-left text-base sm:text-xl">
          {t("businessRepresentativeForm.headerOne")}
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessRepresentativeForm.firstName")}</div>
          {formik.touched.name && formik.errors.name && (
            <div className="qp-inp-error">{formik.errors.name}</div>
          )}
          <Input
            type="text"
            name="name"
            placeholder="Charlie"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.name && formik.errors.name}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessRepresentativeForm.lastName")}</div>
          {formik.touched.last_name && formik.errors.last_name && (
            <div className="qp-inp-error">{formik.errors.last_name}</div>
          )}
          <Input
            type="text"
            name="last_name"
            placeholder="Jackson"
            value={formik.values.last_name}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.last_name && formik.errors.last_name}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessRepresentativeForm.email")}</div>
          {formik.touched.email && formik.errors.email && (
            <div className="qp-inp-error">{formik.errors.email}</div>
          )}
          <Input
            type="text"
            name="email"
            placeholder={t("businessRepresentativeForm.emailAdd")}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessRepresentativeForm.birthDate")}</div>
          {((formik.touched.day && formik.errors.day) || (formik.touched.month && formik.errors.month) || (formik.touched.year && formik.errors.year)) && (
            <div className="qp-inp-error">{formik.errors.year}</div>
          )}
          <div className="flex">
            <Select
              extraClass="flex-1"
              placeholder={t("businessRepresentativeForm.selectMonth")}
              name="month"
              value={formik.values.month}
              options={formatData(months())}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={
                formik.touched.month && formik.errors.month
              }
            />
            <Select
              extraClass="flex-1 mx-2"
              placeholder={t("businessRepresentativeForm.selectDay")}
              name="day"
              value={formik.values.day}
              options={formatData(days(formik))}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={
                formik.touched.day && formik.errors.day
              }
            />
            <Select
              extraClass="flex-1"
              placeholder={t("businessRepresentativeForm.selectYear")}
              name="year"
              value={formik.values.year}
              options={formatData(years())}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={
                formik.touched.year && formik.errors.year
              }
            />
          </div>
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("businessRepresentativeForm.jobTitle")}</div>
          {formik.touched.title && formik.errors.title && (
            <div className="qp-inp-error">{formik.errors.title}</div>
          )}
          <Input
            type="text"
            name="title"
            placeholder="CEO"
            value={formik.values.title}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.title && formik.errors.title}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">
            {t("businessRepresentativeForm.ssn")}
          </div>
          {formik.touched.ssn && formik.errors.ssn && (
            <div className="qp-inp-error">{formik.errors.ssn}</div>
          )}
          <Input
            type="text"
            name="ssn"
            placeholder="8888"
            value={formik.values.ssn}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.ssn && formik.errors.ssn}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9]/.test(e.key) && e.preventDefault()
            }
            maxLength={4}
          />
        </div>
        {businessFlow === "company" && (
          <>
            <div className="qp-cont-inp text-left text-base sm:text-xl">
              {t("businessRepresentativeForm.headerTwo")}
            </div>
            <div className="qp-cont-inp">
              <div className="text-left">{t("businessRepresentativeForm.country")}</div>
              {formik.touched.country && formik.errors.country && (
                <div className="qp-inp-error">{formik.errors.country}</div>
              )}
              <Input
                type="text"
                name="country"
                placeholder={t("businessRepresentativeForm.country")}
                value="USA"
                disabled
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.country && formik.errors.country}
              />
            </div>
            <div className="qp-cont-inp">
              <div className="text-left">{t("businessRepresentativeForm.state")}</div>
              {formik.touched.state && formik.errors.state && (
                <div className="qp-inp-error">{formik.errors.state}</div>
              )}
              <SelectStatesUS
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                haveError={formik.touched.state && formik.errors.state}
                value={formik.values.state}
                name="state"
                placeholder={t("businessRepresentativeForm.stateSelect")}
              />
            </div>
            <div className="qp-cont-inp">
              <div className="text-left">{t("businessRepresentativeForm.city")}</div>
              {formik.touched.city && formik.errors.city && (
                <div className="qp-inp-error">{formik.errors.city}</div>
              )}
              <Input
                type="text"
                name="city"
                placeholder={t("businessRepresentativeForm.city")}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.city && formik.errors.city}
              />
            </div>
            <div className="qp-cont-inp">
              <div className="text-left">{t("businessRepresentativeForm.zipCode")}</div>
              {formik.touched.zip_code && formik.errors.zip_code && (
                <div className="qp-inp-error">{formik.errors.zip_code}</div>
              )}
              <Input
                type="text"
                name="zip_code"
                placeholder={t("businessRepresentativeForm.zipCode")}
                value={formik.values.zip_code}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.zip_code && formik.errors.zip_code}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  !/[0-9]/.test(e.key) && e.preventDefault()
                }
              />
            </div>
            <div className="qp-cont-inp">
              <div className="text-left">{t("businessRepresentativeForm.address")}</div>
              {formik.touched.address_value && formik.errors.address_value && (
                <div className="qp-inp-error">
                  {formik.errors.address_value}
                </div>
              )}
              <Input
                type="text"
                name="address_value"
                placeholder={t("businessRepresentativeForm.address")}
                value={formik.values.address_value}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={
                  formik.touched.address_value && formik.errors.address_value
                }
              />
            </div>
          </>
        )}
        <div className="qp-cont-inp">
          <br />
          <br />
          <hr />
          <br />
        </div>
        <button type="submit" className="qp-btn md:w-6/12 w-full mt-6">
          {t("businessRepresentativeForm.continueBtn")}
        </button>
        <br />
      </div>
    </form>
  );
};

export default BusinessRepresentativeForm;