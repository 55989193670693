import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import { useCallback, useEffect, useState } from "react";
import { getTransactionDetail } from "../../../services/transactionsService";
import { detailTransactionProps } from "../../../interfaces/transactionInterface";

const Detail = () => {
  const { t } = useTranslation(["transaction"]);
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<detailTransactionProps | null>(
    null
  );

  const handleStatus = useCallback(() => {
    if (!transaction) return;
    const { status } = transaction;
    let states = [
      t("detailTransaction.statusTransaction.created"),
      t("detailTransaction.statusTransaction.pending"),
      t("detailTransaction.statusTransaction.confirmed"),
      t("detailTransaction.statusTransaction.pendingBank"),
      t("detailTransaction.statusTransaction.cancelledBank"),
      t("detailTransaction.statusTransaction.failedBank"),
      t("detailTransaction.statusTransaction.completedBank"),
      t("detailTransaction.statusTransaction.completedQp"),
      t("detailTransaction.statusTransaction.cancelledQp"),
      t("detailTransaction.statusTransaction.failedQp"),
      t("detailTransaction.statusTransaction.refundReq"),
      t("detailTransaction.statusTransaction.refunded"),
      t("detailTransaction.statusTransaction.pending"),
    ];
    return states[status[status.length - 1].value];
  }, [transaction, t]);

  useEffect(() => {
    if (params.id) {
      getTransactionDetail(params.id).then((res) => {
        setTransaction(res.data);
      });
    }
  }, [params.id]);

  if (!transaction) {
    return <>Not found</>;
  }

  return (
    <div className="w-full md:w-8/12">
      <div className="flex items-center justify-between">
        <button onClick={() => navigate(-1)}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 10.0625H5.50562L13.5413 2.02687L11.5 0L0 11.5L11.5 23L13.5269 20.9731L5.50562 12.9375H23V10.0625Z"
              fill="#D95300"
            />
          </svg>
        </button>

        <h2 className="text-center w-full">{t("detailTransaction.title")}</h2>
      </div>
      <div>
        <p className="text-orange-detail font-medium mt-2">
          {t("detailTransaction.table.status")}: {handleStatus()}
        </p>
      </div>
      <div className="card-detail-transaction">
        <div className="row my-4">
          <p>{t("detailTransaction.table.OrderId")}</p>
          <p className="font-medium">{transaction.idOrder}</p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.date")}</p>
          <p className="font-medium">
            {formatDate(transaction.status[0].created, "LL / dd / yy")}
          </p>
        </div>
        {/* <div className="row my-4">
          <p>{t("detailTransaction.table.status")}</p>
          <p className="font-medium">{handleStatus()}</p>
        </div> */}
        <div className="row my-4">
          <p>{t("detailTransaction.table.customer")}</p>
          <p className="transaction-username">
            {transaction.user_name?.toLocaleLowerCase()}
          </p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.email")}</p>
          <p className="font-medium">{transaction.user_email}</p>
        </div>
        {/* <div className="row">
          <p>{t("detailTransaction.table.platform")}:</p>
          <p className="font-medium">Wordpress</p>
        </div> */}
      </div>

      <div className="card-detail-transaction">
        <div className="row">
          <p>{t("detailTransaction.table.purchaseVal")}</p>
          <p className="font-medium">${transaction.sub_total.toFixed(2)}</p>
        </div>
        <div className="row my-4">
          <p className="font-medium">{t("detailTransaction.table.points")}</p>
          <p className="font-bold">{`$${transaction.redeemCredit.toFixed(
            2
          )}`}</p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.subtotal")}</p>
          <p className="font-medium">{`$${(
            transaction.sub_total - transaction.redeemCredit
          ).toFixed(2)}`}</p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.taxes")}</p>
          <p className="font-medium">${transaction.taxes.toFixed(2)}</p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.subTax")}</p>
          <p className="font-medium">
            $
            {(
              transaction.taxes +
              transaction.sub_total -
              transaction.redeemCredit
            ).toFixed(2)}
          </p>
        </div>
        <div className="row my-4">
          <p>{t("detailTransaction.table.shipping")}</p>
          <p className="font-medium">${transaction.shipping.toFixed(2)}</p>
        </div>
        <hr className="my-6 border-0 border-t" />
        <div className="row">
          <p className="font-medium">{t("detailTransaction.table.total")}</p>
          <p className="font-bold text-orange-detail text-lg">
            {`$${Number(transaction.redeemAmount).toFixed(2)}`}
          </p>
        </div>
        <hr className="my-6 border-0 border-t" />
        <div className="row my-4">
          <p className="font-medium">
            {t("detailTransaction.table.nextPoints")}
          </p>
          <p className="font-bold text-xl">$ {transaction.points.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default Detail;
