import { ReactComponent as UploadIcon } from "../../../img/upload.svg";
import { uploadLogoProps } from "../../../interfaces/uploadInterface";

const UploadLogo = (props: uploadLogoProps) => {
  const { label } = props;
  return (
    <div className="upload-btn mt-4">
      <UploadIcon />
      <p className="upload-btn-text">
        {label} <br />
      </p>
    </div>
  );
};

export default UploadLogo;
