import { SumaryRowProps } from '../../../interfaces/newLoginInterface'

const SummaryRow = (props: SumaryRowProps) => {
  const { leftText, rightText, extraClass } = props;

  return (
    <div className={`item row text-sm md:text-base ${extraClass ? extraClass : ""}`}>
    <p className='text-sm md:text-base text-left break-words w-2/4'>{leftText}</p>
    <p className="summary-data">{rightText}</p>
  </div>
  );
};

export default SummaryRow;