import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UploadForm from "../../../components/profile/upload/UploadForms";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { uploadValuesProps } from "../../../interfaces/uploadInterface";
import { uploadLogo } from "../../../services/profileService";
import { setLoading, setModalMessage } from "../../../store/shared.slice";
import Swal from "sweetalert2";
import { getUserInfo, logoutUser } from "../../../services/authService";
import { setUserInfo } from "../../../store/auth.slice";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation(["profile"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = async(values: uploadValuesProps) => {
    const logoFormData = new FormData();
    logoFormData.append("file", values.file);
    dispatch(setLoading(true));
    await uploadLogo(logoFormData).then((res) => {
      if(res.data.message === "User updated successfully"){
        Swal.fire({
          title: t("upload.done"),
          text: t("upload.confirmationText"),
          icon: "success",
          confirmButtonText: t("upload.ok"),
        })
      }
      dispatch(setUserInfo({ photo_url: values.file.name }));
    }).catch((err) => {
      setModalMessage({
        title: "Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      })
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (userInfo.email.length === 0) {
      getUserInfo()
        .then((res) => {
          dispatch(setUserInfo(res.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response.status === 401) {
            logoutUser().then(() => {
              navigate("/");
            });
          }
          navigate("/signup");
        });
    }
  }, [userInfo, dispatch, navigate]);

  return (
    <div className="w-full mb-8">
      <div className="text-2xl md:text-3xl font-medium">{t("upload.title")}</div>
      <div className="profile-container">
        <UploadForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export default Upload;