import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  // getIpAddress,
  getUserInfo,
  logoutUser,
  registerDwolla,
  //signUpUser,
} from "../../../services/authService";
// import RegisterForm from "../../components/auth/RegisterForm";
// import { userSignUpInt } from "../../interfaces/authInterface";
import { useTranslation } from "react-i18next";
import { setUserInfo } from "../../../store/auth.slice";
import { setLoading, setModalMessage } from "../../../store/shared.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { DetailsFormInfoProps } from "../../../interfaces/newLoginInterface";

const SignUp = () => {
  const [ipAddress, setIpAddress] = useState("181.61.209.135");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["signup"]);
  // const userPhone = useAppSelector((state) => state.auth.userPhone);

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        dispatch(setUserInfo(res.data));
        // navigate("/dash");
        dispatch(setLoading(false));
      })
      .catch((err) => {
        /*if (err.response.status === 401) {
          logoutUser().then(() => navigate("/"))
        }
        */
        dispatch(setLoading(false));
      });

    //getIpAddress();
    //   .then((res: any) => {
    //     setIpAddress(res.data.ip);
    //   })
    //   .catch((err) => {});
  }, [navigate, dispatch]);

  /*const handleSubmit = (values: DetailsFormInfoProps) => {
    let sendData = Object.assign({}, values);
    // sendData.phone_number = userPhone;
    sendData.ipAdress = ipAddress;
    // delete sendData.accept_contidions;
    dispatch(setLoading(true));
    signUpUser(sendData)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(registerDwolla);
        // navigate("/dash");
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: "An error has occurred",
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };*/

  return (
    <div className="container-qp mt-0">
      <div className="w-full">
        <h2>{t("title")}</h2>
      </div>
    </div>
  );
};

export default SignUp;