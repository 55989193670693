import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import setup from "./utils/interceptors";

const region: string = process.env.REACT_APP_AMPLIFY_REGION as string;
const poolId: string = process.env.REACT_APP_AMPLIFY_USERPOOL_ID as string;
const poolWebClient: string = process.env
  .REACT_APP_AMPLIFY_USERPOOL_WEBCLIENT as string;

Amplify.configure({
  Auth: {
    region: region,
    userPoolId: poolId,
    userPoolWebClientId: poolWebClient,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
setup(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
