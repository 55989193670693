import { City } from "country-state-city";
import { selectCityProps } from "../interfaces/formInterface";
import { useCallback, useEffect, useState } from "react";
import Input from "./Input";

const SelectCityUS = (props: selectCityProps) => {
  const {
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    reference,
    disabled,
    label,
    stateCode,
    onSelectOption,
  } = props;
  const [cities, setStates] = useState<any>([]);
  const [predictions, setPredictions] = useState<any>([]);
  const [showPredictions, setShowPredictions] = useState(false);

  useEffect(() => {
    if (stateCode?.length > 0) {
      const cities = City.getCitiesOfState("US", stateCode);
      const send = cities.map((city, index) => {
        return {
          id: index,
          code: city.stateCode,
          value: city.name,
        };
      });
      setStates(send);
    }
  }, [stateCode]);

  const onValueChange = useCallback(
    (e) => {
      const filter = cities.filter((city: any) =>
        city.value.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPredictions(filter);
      setShowPredictions(true);
      onChange(e);
    },
    [cities, onChange]
  );

  return (
    <div className="relative">
      <div className="text-left">{label}</div>
      <Input
        name={name}
        onChange={onValueChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        haveError={haveError}
        extraClass={extraClass}
        reference={reference}
        disabled={disabled}
      />
      {value.length > 2 && predictions.length > 0 && (
        <div
          className="predictions"
          style={{ display: showPredictions ? "block" : "none" }}
        >
          <ul>
            {predictions.map((city: any) => (
              <li
                className="cursor-pointer"
                onClick={() => {
                  setShowPredictions(false);
                  onSelectOption(city.value);
                }}
                key={city.id}
              >
                {city.value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectCityUS;
