import { useEffect, useState } from "react";
import NewLoginHeader from "@qp/ui/components/NewLoginHeader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import * as Yup from "yup";
import {
  AddressInfo,
  CompanyRegisterInfo,
  DetailsFormInfoProps,
  OwnerFormInfoProps,
  RepresentativeFormInfoProps,
} from "../../../interfaces/newLoginInterface";
import {
  identityVerificationOwner,
  registerCompanyDwolla,
  signUpCompany,
  signUpIndividualNonProfit,
  signupUserEmail,
} from "../../../services/authService";
import { setLoading, setModalMessage } from "../../../store/shared.slice";
import { useNavigate } from "react-router-dom";
import SummaryRow from "../../../components/auth/registration/SummaryRow";
import {
  IUserLoginEmail,
  IUserSignUpEmail,
} from "../../../interfaces/loginInterface";
import { Checkbox } from "@qp/ui";
import { useFormik } from "formik";

const Summary = () => {
  const { t } = useTranslation(["registration"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const businessFlow = useAppSelector(
    (state) => state.businessFlow.businessflow
  );
  const [businessType, setBusinessType] = useState("");
  const [detailsFormInfo, setDetailsFormInfo] =
    useState<DetailsFormInfoProps>();
  const [representativeFormInfo, setRepresentativeFormInfo] =
    useState<RepresentativeFormInfoProps>();
  const [ownerFormInfo, setOwnerFormInfo] = useState<OwnerFormInfoProps>();
  const [secondOwnerFormInfo, setSecondOwnerFormInfo] =
    useState<OwnerFormInfoProps>();
  const [thirdOwnerFormInfo, setThirdOwnerFormInfo] =
    useState<OwnerFormInfoProps>();
  const [fourthOwnerFormInfo, setFourthOwnerFormInfo] =
    useState<OwnerFormInfoProps>();
  const [userInfo, setUserInfo] = useState<IUserLoginEmail>();

  const formik = useFormik({
    initialValues: {
      accept_contidions: false,
    },
    validationSchema: Yup.object({
      accept_contidions: Yup.boolean().oneOf(
        [true],
        t("summary.acceptConditionsErr")
      ),
    }),
    onSubmit: () => {
      handleSuccess();
    },
  });
  const handleRegisterCompanyDwolla = async (id: string) => {
    dispatch(setLoading(true));
    await registerCompanyDwolla(id)
      .then(() => {})
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: err.response.data.details?.Errors[0]
              ? err.response.data.details.Errors[0]
              : err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };

  const handleSignupUser = async (user: IUserSignUpEmail) => {
    dispatch(setLoading(true));
    await signupUserEmail(user)
      .then(() => {})
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };

  const handleSignupCompany = async (
    company: CompanyRegisterInfo,
    user: IUserSignUpEmail
  ) => {
    dispatch(setLoading(true));
    await signUpCompany(company)
      .then(async (res) => {
        if (res.status === 200) {
          // if (company.owners && company.owners.length > 0) {
            // let promises = company.owners.map((owner) => {
            //   if (owner.email) {
            //     console.log(owner.email);
            //     return identityVerificationOwner(owner.email);
            //   }
            // });
            // Promise.allSettled(promises)
            //   .then(async (responses) => {
            //     console.log(responses);

            //     localStorage.setItem("emailRegistered", res.data.email);
            //     localStorage.setItem(
            //       "registrationStatus",
            //       JSON.stringify("registered")
            //     );
            //     await handleRegisterCompanyDwolla(res.data.email);
            //     await handleSignupUser(user);
            //     dispatch(setLoading(false));
            //     navigate("/registration/bank-details");
            //   })
            //   .catch((err) => {
            //     dispatch(setLoading(false));
            //     dispatch(
            //       setModalMessage({
            //         title: "Error",
            //         text: err.response.data.message,
            //         icon: "error",
            //         confirmButtonText: "Ok",
            //       })
            //     );
            //   });
          // }

          localStorage.setItem("emailRegistered", res.data.email);
                localStorage.setItem(
                  "registrationStatus",
                  JSON.stringify("registered")
                );
                await handleRegisterCompanyDwolla(res.data.email);
                await handleSignupUser(user);
                dispatch(setLoading(false));
                navigate("/registration/bank-details");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };

  const handleSignUpIndividualNonProfit = (
    sendData: any,
    user: IUserSignUpEmail
  ) => {
    dispatch(setLoading(true));
    signUpIndividualNonProfit(sendData)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("emailRegistered", res.data.email);
          localStorage.setItem(
            "registrationStatus",
            JSON.stringify("registered")
          );
          handleSignupUser(user);
          dispatch(setLoading(false));
          navigate("/registration/bank-details");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: err.response.data.details?.Errors[0]
              ? err.response.data.details.Errors[0]
              : err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };

  const handleSuccess = () => {
    if (businessFlow === "company") {
      let controllerAddress: AddressInfo = {
        address_value: representativeFormInfo?.address_value,
        country: representativeFormInfo?.country,
        state: representativeFormInfo?.state,
        city: representativeFormInfo?.city,
        zip_code: representativeFormInfo?.zip_code,
      };
      let businessController = {
        name: representativeFormInfo?.name,
        last_name: representativeFormInfo?.last_name,
        ssn: representativeFormInfo?.ssn,
        dateOfBirth: representativeFormInfo?.dateOfBirth,
        title: representativeFormInfo?.title,
        address: controllerAddress,
      };
      let businessAddress = {
        address_value: detailsFormInfo?.address_value,
        country: detailsFormInfo?.country,
        state: detailsFormInfo?.state,
        city: detailsFormInfo?.city,
        zip_code: detailsFormInfo?.zip_code,
      };
      let firstOwnerAddress: AddressInfo = {
        address_value: ownerFormInfo?.address_value,
        country: ownerFormInfo?.country,
        state: ownerFormInfo?.state,
        city: ownerFormInfo?.city,
        zip_code: ownerFormInfo?.zip_code,
      };
      let secondOwnerAddress: AddressInfo = {
        address_value: secondOwnerFormInfo?.address_value,
        country: secondOwnerFormInfo?.country,
        state: secondOwnerFormInfo?.state,
        city: secondOwnerFormInfo?.city,
        zip_code: secondOwnerFormInfo?.zip_code,
      };
      let thirdOwnerAddress: AddressInfo = {
        address_value: thirdOwnerFormInfo?.address_value,
        country: thirdOwnerFormInfo?.country,
        state: thirdOwnerFormInfo?.state,
        city: thirdOwnerFormInfo?.city,
        zip_code: thirdOwnerFormInfo?.zip_code,
      };
      let fourthOwnerAddress: AddressInfo = {
        address_value: fourthOwnerFormInfo?.address_value,
        country: fourthOwnerFormInfo?.country,
        state: fourthOwnerFormInfo?.state,
        city: fourthOwnerFormInfo?.city,
        zip_code: fourthOwnerFormInfo?.zip_code,
      };
      let secondOwnerInfo = {
        name: secondOwnerFormInfo?.name,
        last_name: secondOwnerFormInfo?.last_name,
        email: secondOwnerFormInfo?.email,
        ssn: `${secondOwnerFormInfo?.ssn?.substring(
          0,
          3
        )}-${secondOwnerFormInfo?.ssn?.substring(
          3,
          5
        )}-${secondOwnerFormInfo?.ssn?.substring(5)}`,
        dateOfBirth: secondOwnerFormInfo?.dateOfBirth,
        file_name: JSON.parse(localStorage.getItem("document2") || ""),
        type: secondOwnerFormInfo?.documentType,
        address: secondOwnerAddress,
      };
      let thirdOwnerInfo = {
        name: thirdOwnerFormInfo?.name,
        last_name: thirdOwnerFormInfo?.last_name,
        email: thirdOwnerFormInfo?.email,
        ssn: `${thirdOwnerFormInfo?.ssn?.substring(
          0,
          3
        )}-${thirdOwnerFormInfo?.ssn?.substring(
          3,
          5
        )}-${thirdOwnerFormInfo?.ssn?.substring(5)}`,
        dateOfBirth: thirdOwnerFormInfo?.dateOfBirth,
        file_name: JSON.parse(localStorage.getItem("document3") || ""),
        type: thirdOwnerFormInfo?.documentType,
        address: thirdOwnerAddress,
      };
      let fourthOwnerInfo = {
        name: fourthOwnerFormInfo?.name,
        last_name: fourthOwnerFormInfo?.last_name,
        email: fourthOwnerFormInfo?.email,
        ssn: `${fourthOwnerFormInfo?.ssn?.substring(
          0,
          3
        )}-${fourthOwnerFormInfo?.ssn?.substring(
          3,
          5
        )}-${fourthOwnerFormInfo?.ssn?.substring(5)}`,
        dateOfBirth: fourthOwnerFormInfo?.dateOfBirth,
        file_name: JSON.parse(localStorage.getItem("document4") || ""),
        type: fourthOwnerFormInfo?.documentType,
        address: fourthOwnerAddress,
      };
      let ownersInfo = [
        {
          name: ownerFormInfo?.name,
          last_name: ownerFormInfo?.last_name,
          email: ownerFormInfo?.email,
          ssn: `${ownerFormInfo?.ssn?.substring(
            0,
            3
          )}-${ownerFormInfo?.ssn?.substring(
            3,
            5
          )}-${ownerFormInfo?.ssn?.substring(5)}`,
          dateOfBirth: ownerFormInfo?.dateOfBirth,
          file_name: JSON.parse(localStorage.getItem("document") || ""),
          type: ownerFormInfo?.documentType,
          address: firstOwnerAddress,
        },
      ];
      if (secondOwnerFormInfo && thirdOwnerFormInfo && fourthOwnerFormInfo) {
        ownersInfo.push(secondOwnerInfo, thirdOwnerInfo, fourthOwnerInfo);
      } else if (secondOwnerFormInfo && thirdOwnerFormInfo) {
        ownersInfo.push(secondOwnerInfo, thirdOwnerInfo);
      } else if (secondOwnerFormInfo) {
        ownersInfo.push(secondOwnerInfo);
      }

      let companyData: CompanyRegisterInfo = {
        email: userInfo?.email,
        bussiness_email: representativeFormInfo?.email,
        businessName: detailsFormInfo?.businessName,
        ein: detailsFormInfo?.ein,
        business_classifications: detailsFormInfo?.business_classifications,
        phone_number: detailsFormInfo?.phone_number.toString(),
        BusinessType: businessType,
        address: businessAddress,
        controller: businessController,
        owners: ownersInfo,
      };

      let user: IUserSignUpEmail = {
        email: userInfo?.email,
        password: userInfo?.password,
        name: representativeFormInfo?.name,
        family_name: representativeFormInfo?.last_name,
        phone_number: `+1${detailsFormInfo?.phone_number.toString()}`,
      };
      handleSignupCompany(companyData, user);
    } else {
      let individualAddress = {
        address_value: detailsFormInfo?.address_value,
        country: detailsFormInfo?.country,
        state: detailsFormInfo?.state,
        city: detailsFormInfo?.city,
        zip_code: detailsFormInfo?.zip_code,
      };
      let sendData = {
        name: representativeFormInfo?.name,
        last_name: representativeFormInfo?.last_name,
        email: userInfo?.email,
        bussiness_email: representativeFormInfo?.email,
        statement_descriptor: detailsFormInfo?.statement_descriptor,
        businessName: detailsFormInfo?.businessName,
        ein: detailsFormInfo?.ein,
        ssn: representativeFormInfo?.ssn?.toString(),
        dateOfBirth: representativeFormInfo?.dateOfBirth,
        business_classifications: detailsFormInfo?.business_classifications,
        phone_number: detailsFormInfo?.phone_number,
        address: individualAddress,
      };
      let user: IUserSignUpEmail = {
        email: userInfo?.email,
        password: userInfo?.password,
        name: representativeFormInfo?.name,
        family_name: representativeFormInfo?.last_name,
        phone_number: `+1${detailsFormInfo?.phone_number.toString()}`,
      };
      handleSignUpIndividualNonProfit(sendData, user);
    }
  };

  useEffect(() => {
    const businessDetailsInfo = JSON.parse(
      window.localStorage.getItem("detailsFormData") || ""
    );
    const representativeInfo = JSON.parse(
      window.localStorage.getItem("representativeFormData") || ""
    );
    const getBusinessType = JSON.parse(
      window.localStorage.getItem("businessType") || ""
    );
    const getFirstOwnerInfo = JSON.parse(
      window.localStorage.getItem("ownersFormData") || ""
    );
    const getSecondOwnerInfo = JSON.parse(
      window.localStorage.getItem("additionalFirstOwner") || ""
    );
    const getThirdOwnerInfo = JSON.parse(
      window.localStorage.getItem("additionalSecondOwner") || ""
    );
    const getFourthOwnerInfo = JSON.parse(
      window.localStorage.getItem("additionalThirdOwner") || ""
    );
    const getUserInfo = JSON.parse(
      window.localStorage.getItem("qp-signupUser") || ""
    );
    if (getBusinessType) {
      setBusinessType(getBusinessType);
    }
    if (businessDetailsInfo) {
      setDetailsFormInfo(businessDetailsInfo);
    }
    if (representativeInfo) {
      setRepresentativeFormInfo(representativeInfo);
    }
    if (getFirstOwnerInfo) {
      setOwnerFormInfo(getFirstOwnerInfo);
    }
    if (getSecondOwnerInfo) {
      setSecondOwnerFormInfo(getSecondOwnerInfo);
    }
    if (getThirdOwnerInfo) {
      setThirdOwnerFormInfo(getThirdOwnerInfo);
    }
    if (getFourthOwnerInfo) {
      setFourthOwnerFormInfo(getFourthOwnerInfo);
    }
    if (getUserInfo) {
      setUserInfo(getUserInfo);
    }
  }, []);

  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("summary.headerTitle")}`}
        showBackButton
      />
      <div className="card-detail-transaction card-summary">
        <div className="text-left font-medium text-xl md:text-2xl">
          {t("summary.firstSection")}
        </div>
        <SummaryRow
          leftText={t("summary.firstInput")}
          rightText={detailsFormInfo?.businessName}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.secondInput")}
          rightText={detailsFormInfo?.statement_descriptor}
        />
        <SummaryRow
          leftText={t("summary.thirdInput")}
          rightText={detailsFormInfo?.ein}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.tenthInput")}
          rightText={`+${
            detailsFormInfo?.phone_number.toString()[0]
          } ${detailsFormInfo?.phone_number
            .toString()
            .substring(1, 4)} ${detailsFormInfo?.phone_number
            .toString()
            .substring(4, 7)} ${detailsFormInfo?.phone_number
            .toString()
            .substring(7)}`}
        />
        <SummaryRow
          leftText={t("summary.eleventhInput")}
          rightText={JSON.parse(localStorage.getItem("industryName") || "")}
          extraClass="my-4"
        />
        <br />
        <div className="qp-cont-inp text-left text-lg md:text-xl">
          {t("summary.firstTitle")}
        </div>
        <SummaryRow
          leftText={t("summary.addressInputs.address")}
          rightText={detailsFormInfo?.address_value}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.addressInputs.city")}
          rightText={detailsFormInfo?.city}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.addressInputs.state")}
          rightText={detailsFormInfo?.state}
        />
        <SummaryRow
          leftText={t("summary.addressInputs.zip")}
          rightText={detailsFormInfo?.zip_code}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.addressInputs.country")}
          rightText={detailsFormInfo?.country}
        />
        <hr className="mt-8 mb-2" />
        <div className="text-left font-medium text-xl md:text-2xl pt-8 pb-4">
          {t("summary.secondSection")}
        </div>
        <div className="mb-12 text-left text-lg md:text-xl">
          {t("summary.firstTitleRepresentative")}
        </div>
        <SummaryRow
          leftText={t("summary.firstInputRepresentative")}
          rightText={representativeFormInfo?.name}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.secondInputRepresentative")}
          rightText={representativeFormInfo?.last_name}
        />
        <SummaryRow
          leftText={t("summary.thirdInputRepresentative")}
          rightText={representativeFormInfo?.email}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.fourthInputRepresentative")}
          rightText={`${representativeFormInfo?.dateOfBirth
            ?.toString()
            .substring(5, 10)}-${representativeFormInfo?.dateOfBirth
            ?.toString()
            .substring(0, 4)}`}
        />
        <SummaryRow
          leftText={t("summary.thirdInputOwners")}
          rightText={representativeFormInfo?.title}
          extraClass="my-4"
        />
        <SummaryRow
          leftText={t("summary.fifthInputRepresentative")}
          rightText={representativeFormInfo?.ssn}
          extraClass="my-4"
        />
        <br />
        {(businessType === "llc" ||
          businessType === "partnership" ||
          businessType === "corporation") && (
          <>
            <div className="qp-cont-inp text-left text-lg md:text-xl">
              {t("summary.secondTitleRepresentative")}
            </div>
            <SummaryRow
              leftText={t("summary.addressInputs.address")}
              rightText={representativeFormInfo?.address_value}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.city")}
              rightText={representativeFormInfo?.city}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.state")}
              rightText={representativeFormInfo?.state}
            />
            <SummaryRow
              leftText={t("summary.addressInputs.zip")}
              rightText={representativeFormInfo?.zip_code}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.country")}
              rightText={representativeFormInfo?.country}
            />
          </>
        )}
        {ownerFormInfo && (
          <>
            <hr className="mt-8 mb-2" />
            <div className="text-left font-medium text-xl md:text-2xl pt-8 pb-4">
              {t("summary.thirdSection")}
            </div>
            <div className="text-left text-lg md:text-xl font-medium">
              {t("summary.firstTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.firstInputOwners")}
              rightText={ownerFormInfo?.name}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.secondInputOwners")}
              rightText={ownerFormInfo?.last_name}
            />
            <SummaryRow
              leftText={t("summary.email")}
              rightText={ownerFormInfo?.email}
              extraClass="mt-4"
            />
            <SummaryRow
              leftText={t("summary.fourthInputOwners")}
              rightText={`${ownerFormInfo?.dateOfBirth
                ?.toString()
                .substring(5, 10)}-${ownerFormInfo?.dateOfBirth
                ?.toString()
                .substring(0, 4)}`}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.fifthInputOwners")}
              rightText={ownerFormInfo?.ssn}
            />
            <SummaryRow
              leftText={t("summary.sixthInputOwners")}
              rightText={ownerFormInfo?.documentType}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.seventhInputOwners")}
              rightText={JSON.parse(localStorage.getItem("document") || "")}
            />
            <br />
            <div className="qp-cont-inp text-left text-lg md:text-xl">
              {t("summary.addressTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.addressInputs.address")}
              rightText={ownerFormInfo?.address_value}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.city")}
              rightText={ownerFormInfo?.city}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.state")}
              rightText={ownerFormInfo?.state}
            />
            <SummaryRow
              leftText={t("summary.addressInputs.zip")}
              rightText={ownerFormInfo?.zip_code}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.country")}
              rightText={ownerFormInfo?.country}
            />
          </>
        )}
        {secondOwnerFormInfo && (
          <>
            <hr className="mt-8 mb-2" />
            <div className="text-left text-lg md:text-xl mt-12 font-medium">
              {t("summary.secondTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.firstInputOwners")}
              rightText={secondOwnerFormInfo?.name}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.secondInputOwners")}
              rightText={secondOwnerFormInfo?.last_name}
            />
            <SummaryRow
              leftText={t("summary.email")}
              rightText={secondOwnerFormInfo?.email}
              extraClass="mt-4"
            />
            <SummaryRow
              leftText={t("summary.fourthInputOwners")}
              rightText={`${secondOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(5, 10)}-${secondOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(0, 4)}`}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.fifthInputOwners")}
              rightText={secondOwnerFormInfo?.ssn}
            />
            <SummaryRow
              leftText={t("summary.sixthInputOwners")}
              rightText={secondOwnerFormInfo?.documentType}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.seventhInputOwners")}
              rightText={JSON.parse(localStorage.getItem("document2") || "")}
            />
            <br />
            <div className="qp-cont-inp text-left text-lg md:text-xl">
              {t("summary.addressTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.addressInputs.address")}
              rightText={secondOwnerFormInfo?.address_value}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.city")}
              rightText={secondOwnerFormInfo?.city}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.state")}
              rightText={secondOwnerFormInfo?.state}
            />
            <SummaryRow
              leftText={t("summary.addressInputs.zip")}
              rightText={secondOwnerFormInfo?.zip_code}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.country")}
              rightText={secondOwnerFormInfo?.country}
            />
          </>
        )}
        {thirdOwnerFormInfo && (
          <>
            <hr className="mt-8 mb-2" />
            <div className="text-left text-lg md:text-xl mt-12 font-medium">
              {t("summary.thirdTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.firstInputOwners")}
              rightText={thirdOwnerFormInfo?.name}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.secondInputOwners")}
              rightText={thirdOwnerFormInfo?.last_name}
            />
            <SummaryRow
              leftText={t("summary.email")}
              rightText={thirdOwnerFormInfo?.email}
              extraClass="mt-4"
            />
            <SummaryRow
              leftText={t("summary.fourthInputOwners")}
              rightText={`${thirdOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(5, 10)}-${thirdOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(0, 4)}`}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.fifthInputOwners")}
              rightText={thirdOwnerFormInfo?.ssn}
            />
            <SummaryRow
              leftText={t("summary.sixthInputOwners")}
              rightText={thirdOwnerFormInfo?.documentType}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.seventhInputOwners")}
              rightText={JSON.parse(localStorage.getItem("document3") || "")}
            />
            <br />
            <div className="qp-cont-inp text-left text-lg md:text-xl">
              {t("summary.addressTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.addressInputs.address")}
              rightText={thirdOwnerFormInfo?.address_value}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.city")}
              rightText={thirdOwnerFormInfo?.city}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.state")}
              rightText={thirdOwnerFormInfo?.state}
            />
            <SummaryRow
              leftText={t("summary.addressInputs.zip")}
              rightText={thirdOwnerFormInfo?.zip_code}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.country")}
              rightText={thirdOwnerFormInfo?.country}
            />
          </>
        )}
        {fourthOwnerFormInfo && (
          <>
            <hr className="mt-8 mb-2" />
            <div className="text-left text-lg md:text-xl mt-12 font-medium">
              {t("summary.fourthTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.firstInputOwners")}
              rightText={fourthOwnerFormInfo?.name}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.email")}
              rightText={fourthOwnerFormInfo?.email}
              extraClass="mt-4"
            />
            <SummaryRow
              leftText={t("summary.secondInputOwners")}
              rightText={fourthOwnerFormInfo?.last_name}
            />
            <SummaryRow
              leftText={t("summary.fourthInputOwners")}
              rightText={`${fourthOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(5, 10)}-${fourthOwnerFormInfo?.dateOfBirth
                ?.toString()
                .substring(0, 4)}`}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.fifthInputOwners")}
              rightText={fourthOwnerFormInfo?.ssn}
            />
            <SummaryRow
              leftText={t("summary.sixthInputOwners")}
              rightText={fourthOwnerFormInfo?.documentType}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.seventhInputOwners")}
              rightText={JSON.parse(localStorage.getItem("document4") || "")}
            />
            <br />
            <div className="qp-cont-inp text-left text-lg md:text-xl">
              {t("summary.addressTitleOwners")}
            </div>
            <SummaryRow
              leftText={t("summary.addressInputs.address")}
              rightText={fourthOwnerFormInfo?.address_value}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.city")}
              rightText={fourthOwnerFormInfo?.city}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.state")}
              rightText={fourthOwnerFormInfo?.state}
            />
            <SummaryRow
              leftText={t("summary.addressInputs.zip")}
              rightText={fourthOwnerFormInfo?.zip_code}
              extraClass="my-4"
            />
            <SummaryRow
              leftText={t("summary.addressInputs.country")}
              rightText={fourthOwnerFormInfo?.country}
            />
          </>
        )}
      </div>
      <form onSubmit={formik.handleSubmit} className="mt-12">
        <div className="qp-cont-inp flex">
          {formik.touched.accept_contidions &&
            formik.errors.accept_contidions && (
              <div className="qp-inp-error">
                {formik.errors.accept_contidions}
              </div>
            )}
          <Checkbox
            name="accept_contidions"
            checked={formik.values.accept_contidions}
            onChange={(e) => formik.handleChange(e)}
            txtlabel={
              <div className="text-sm">
                <span>{t("summary.privacy")}</span>
                <a
                  className="underline"
                  target="_blank"
                  href="https://www.quickpenny.com/terms"
                  rel="noreferrer"
                >
                  {t("summary.qpTerms")}
                </a>
                <span>{t("summary.and")}</span>
                <a
                  className="underline"
                  target="_blank"
                  href="https://www.quickpenny.com/privacy"
                  rel="noreferrer"
                >
                  {t("summary.qpPrivacy")}
                </a>
                <span>{t("summary.qpTerms2")}</span>
                <a
                  className="underline"
                  target="_blank"
                  href="https://www.dwolla.com/legal/tos/"
                  rel="noreferrer"
                >
                  {t("summary.qpTerms")}
                </a>
                <span>{t("summary.and")}</span>
                <a
                  className="underline"
                  target="_blank"
                  href="https://www.dwolla.com/legal/privacy/"
                  rel="noreferrer"
                >
                  {t("summary.qpPrivacy")}
                </a>
              </div>
            }
          />
        </div>
        <div className="qp-cont-inp">
          <button type="submit" className="qp-btn md:w-6/12 w-full mt-6">
            {`${t("summary.continueBtn")}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Summary;
