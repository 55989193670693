import { Input } from '@qp/ui'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setLoading } from '../../../store/shared.slice';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { redirectUrlsFormProps } from '../../../interfaces/uploadInterface';
import { setFailedUrl, setSuccessfulUrl } from '../../../store/profile.slice';
import { redirectUrls } from '../../../services/settingsService';
import { useTranslation } from 'react-i18next';

const                                        RedirectUrlsForm = (props : redirectUrlsFormProps) => {
  const { onSuccess } = props
  const { t } = useTranslation(["settings"]);
  const [changedData, setChangedData]= useState<boolean>(false);
  const existingSuccessfulUrl = useAppSelector((state) => state.profile.successfulTransactionUrl);
  const existingFailedUrl = useAppSelector((state) => state.profile.failedTransactionUrl);
  localStorage.setItem("successful-url", JSON.stringify(""));
  localStorage.setItem("failed-url", JSON.stringify(""));
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      successfulUrl: existingSuccessfulUrl ?? "",
      failedUrl : existingFailedUrl ?? ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      successfulUrl: Yup.string()
      .matches(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/, t("redirectUrls.invalidUrl"))
      .required(t("redirectUrls.sucessReq")),
      failedUrl: Yup.string()
      .matches(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/, t("redirectUrls.invalidUrl"))
      .required(t("redirectUrls.failReq")),
    }),
    onSubmit: (values) => {
      onSuccess(values);
      setChangedData(false)
    },
  });

  const handleChange = (e: any) => {
    formik.handleChange(e);
    setChangedData(true);
  };

  useEffect(() => {
    const getRedirectionUrls = async () => {
      dispatch(setLoading(true));
      redirectUrls().then((res: any) => {
        if (res.status === 200 ) {
          localStorage.setItem("successful-url", JSON.stringify(res.data.successfulTransactionUrl));
          localStorage.setItem("failed-url", JSON.stringify(res.data.failedTransactionUrl));
          dispatch(setSuccessfulUrl(res.data.successfulTransactionUrl))
          dispatch(setFailedUrl(res.data.failedTransactionUrl))
        }
      }).catch((err: any) => {
        console.error(err)
      });
      dispatch(setLoading(false));
    }
    getRedirectionUrls()
  },[dispatch])

  return (
    <div className="w-full mb-8">
      <form onSubmit={formik.handleSubmit}>
        <div className="text-2xl md:text-3xl font-medium">{t("redirectUrls.header")}</div>
        <div className='profile-container flex flex-col items-center'>
          <div className='flex flex-col relative my-4 w-full md:w-3/4'>
            <span className='text-left text-base'>{t("redirectUrls.successTitle")}</span>
            {formik.touched.successfulUrl && formik.errors.successfulUrl && (
              <div className="qp-input-error-profile">{formik.errors.successfulUrl}</div>
            )}
              <Input
                name="successfulUrl"
                type='text'
                extraClass='password'
                value={formik.values.successfulUrl}
                onChange={handleChange}
                placeholder='https://example.example/'
              />
          </div>
          <div className='flex flex-col relative mt-4 w-full md:w-3/4'>
            <span className='text-left text-base'>{t("redirectUrls.failedTitle")}</span>
            {formik.touched.failedUrl && formik.errors.failedUrl && (
              <div className="qp-input-error-profile">{formik.errors.failedUrl}</div>
            )}
              <Input
                name="failedUrl"
                type='text'
                extraClass='password'
                value={formik.values.failedUrl}
                onChange={handleChange}
                placeholder='https://example.example/'
              />
          </div>
          <button type="submit" className={`profile-submit-btn ${!changedData ? "opacity-70" : "opacity-100"}`} disabled={!changedData}>
          {`${existingSuccessfulUrl && existingFailedUrl ? t("redirectUrls.saveNew") : t("redirectUrls.save")}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RedirectUrlsForm;