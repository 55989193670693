import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { confirmOTP } from "../../../services/authService";
import { userSignUpInt } from "../../../interfaces/authInterface";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useTranslation } from "react-i18next";
import OtpForm from "../../../components/auth/OtpForm";

type LocationState = {
  user: userSignUpInt;
  phone: string;
};

const Otp = () => {
  const params = useLocation();
  const page = useAppSelector((state) => state.auth.pageRedirect);
  const phone = (params.state as LocationState)?.phone;
  const otpValidated = useAppSelector((state) => state.auth.otpValidated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["login"]);

  const handleSuccess = (value: string) => {
    dispatch(confirmOTP(value));
  };

  const formatPhone = useMemo(() => {
    if (phone) {
      let start = phone.substring(0, 2);
      let cut = 1;
      if (start === "57") cut = 2;
      let p = `(${phone.substring(cut, cut + 1)}xx) xxx-${phone.slice(
        phone.length - 4
      )}`;
      return p;
    }
  }, [phone]);

  useEffect(() => {
    if (otpValidated && page !== "") {
      if (page === "login") {
        navigate("/dash/transactions");
      }

      if (page === "signup") {
        navigate("/signup");
      }

      if (page === "transaction") {
        navigate(-1);
      }
    }
  }, [otpValidated, page, navigate]);

  if (phone && page !== "") {
    return (
      <div className="container-qp">
        <div>
          <h2>{t("otp.title")}</h2>
          <p className="mt-8 mb-16">
            {t("otp.description1")}
            <span className="mx-1">{formatPhone}</span>
            {t("otp.description2")}
          </p>
          <OtpForm onSuccess={handleSuccess} showButtons />
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Otp;