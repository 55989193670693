import { Outlet } from "react-router-dom";

const OutletAddress = () => {
  return (
    <div className="md:w-10/12 w-full">
      <Outlet />
    </div>
  );
};

export default OutletAddress;
