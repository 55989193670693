import { useFormik } from "formik";
import * as Yup from "yup";
import { PasswordInput } from "@qp/ui";
import { Link, useNavigate, useLocation} from "react-router-dom";
import { changePassword } from "../../services/authService";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setLoading, setModalMessage } from "../../store/shared.slice";
import { useTranslation } from "react-i18next";

const ChangePass = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation(["login"]);
  const urlCode = new URLSearchParams(location.search).get('code')
  const urlEmail = new URLSearchParams(location.search).get('email')

  const handleSuccess = (password: string) => {
    const code = urlCode
    const email = urlEmail
    if (email && code) {
      dispatch(setLoading(true));
      changePassword({
        email,
        confirmation_code: code,
        password,
      })
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("changePass.successTitle"),
              text: res.data.message,
              icon: "success",
              confirmButtonText: t("changePass.loginBtn"),
            })
          );
          navigate('/')
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("changePass.errTitle"),
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: t("changePass.ok"),
            })
          );
        });
    };
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("changePass.passReq"))
        .matches(
          /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{12,})/,
          t("changePass.passErr")
        ),
      confPassword: Yup.string()
        .required(t("changePass.confirmPassReq"))
        .matches(
          /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{12,})/,
          t("changePass.passErr")
        )
        .oneOf([Yup.ref("password"), null], t("changePass.passMatchErr")),
    }),
    onSubmit(values) {
      handleSuccess(values.password);
    },
  });

  return (
    <div className="container-qp">
      <div className="w-full">
        <h2>{t("changePass.header")}</h2>
        <form className="qp-cont-inp" onSubmit={formik.handleSubmit}>
          <div className="qp-cont-inp">
            {formik.touched.password && formik.errors.password && (
              <div className="qp-inp-error">{formik.errors.password}</div>
            )}
            <PasswordInput
              label={t("changePass.header")}
              type="password"
              placeholder={t("changePass.header")}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              haveError={formik.touched.password && formik.errors.password}
            />
          </div>
          <div className="qp-cont-inp">
            {formik.touched.confPassword && formik.errors.confPassword && (
              <div className="qp-inp-error">{formik.errors.confPassword}</div>
            )}
            <PasswordInput
              label={t("changePass.confirmPlaceholder")}
              type="password"
              placeholder={t("changePass.confirmPlaceholder")}
              name="confPassword"
              value={formik.values.confPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              haveError={
                formik.touched.confPassword && formik.errors.confPassword
              }
            />
          </div>
          <button type="submit" className="qp-btn qp-btn-outline mt-8">
            {t("changePass.changePassBtn")}
          </button>
        </form>
        <Link to="/" className="link md:w-6/12 w-full mt-16 mx-auto block">
          {t("changePass.loginBtn")}
        </Link>
      </div>
    </div>
  );
};

export default ChangePass;
