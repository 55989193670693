import { createAddress } from "../../../services/addressService";
import { useLocation, useNavigate } from "react-router-dom";
import { addressProps } from "../../../interfaces/addressInterface";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import AddressForm from "../../../components/dash/address/AddressForm";

const CreateAddress = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["profile"]);
  const location = useLocation();
  const state = location.state as any;
  const transaction = state ? state.transaction : null;

  const handleSuccess = (values: addressProps) => {
    createAddress(values)
      .then((res) => {
        Swal.fire({
          title: "",
          text: res.data.message,
          icon: "success",
          confirmButtonText: t("address.ok"),
        }).then(() => {
          if (transaction) {
            navigate(-2);
          } else {
            navigate("/dash/profile");
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="w-full">
      <h2>{t("address.newAddress")}</h2>
      <AddressForm onSuccess={handleSuccess} />
    </div>
  );
};

export default CreateAddress;
