import { createSlice } from "@reduxjs/toolkit";
import { AddressState } from "./state.types";

const initialState: AddressState = {
  address: [],
  isLoadPrincipalAddress: false,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setLoadPrincipalAddress: (state, action) => {
      state.isLoadPrincipalAddress = action.payload;
    },
  },
});

export const { setAddress, setLoadPrincipalAddress } = addressSlice.actions;

export default addressSlice.reducer;
