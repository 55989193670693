import { createSlice } from "@reduxjs/toolkit";
import { AccountsState } from "./state.types";

const initialState: AccountsState = {
  accounts: [],
};

const accountsMerchantSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setPlaidAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  },
});

export const { setPlaidAccounts } = accountsMerchantSlice.actions;

export default accountsMerchantSlice.reducer;
