import { addressProps } from "../interfaces/addressInterface";
import { AppThunk } from "../store";
import { setAddress, setLoadPrincipalAddress } from "../store/address.slice";
import axiosCLient from "./axios";

export const getAddress = (): AppThunk => async (dispatch) => {
  await axiosCLient
    .get("merchants/address")
    .then((res) => {
      let address = res.data.map((item: addressProps) => {
        let s = Object.assign({}, item);
        s.selected = false;
        return s;
      });
      dispatch(setAddress(address));
      dispatch(getPricipalAddress());
    })
    .catch((err) => {});
};

export const getPricipalAddress =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(setLoadPrincipalAddress(true));
    const { address } = getState().address;
    await axiosCLient
      .get("merchants/address/getaddressdefault")
      .then((res) => {
        dispatch(setLoadPrincipalAddress(false));
        if (res.status === 200) {
          let send = [...address];
          let principal = send.findIndex((item) => item.id === res.data.id);
          send[principal] = { ...send[principal], selected: true };
          send.push(...send.splice(0, principal));
          dispatch(setAddress(send));
        }
      })
      .catch((err) => {});
  };

export const setPrincipalAddress = async (id: string) => {
  const { data } = await axiosCLient.put(`merchants/address/setdefault/${id}`);
  return data;
};

export const createAddress = (address: addressProps) => {
  return axiosCLient.post("merchants/address", address);
};

export const editAddress = (address: addressProps) => {
  return axiosCLient.put(`merchants/address/${address.id}`, address);
};

export const deleteAddress = (id: string) => {
  return axiosCLient.delete(`merchants/address/${id}`);
};
