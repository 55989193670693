import { useState } from "react";
import { Icon } from "@qp/ui";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const { t } = useTranslation(["registration"]);
  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);
  const businessType = JSON.parse( window.localStorage.getItem("businessType") || "");
  const businessDetails = JSON.parse(window.localStorage.getItem("detailsFormData") || "");
  const businessRepresentative = JSON.parse(window.localStorage.getItem("representativeFormData") || "");
  const business = JSON.parse(window.localStorage.getItem("business") || "");
  const firstBusinessOwner = JSON.parse(window.localStorage.getItem("ownersFormData") || "");
  const isUserRegistered = JSON.parse(window.localStorage.getItem("registrationStatus") || "");

  const handleBusinessOwnersLinks = () => {
    if (businessType === "partnership" || businessType === "corporation" || businessType ==="llc"){
      if(!businessRepresentative || isUserRegistered){
        return (
          <button className="notActive">{t("sidebar.step1subtitle4")}</button>
        )
      } else return(
        <NavLink
          to={"/registration/add-business-owners"}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {t("sidebar.step1subtitle4")}
        </NavLink>
      )
    } else return null
  };

  const handleSummaryLink = () => {
    if (business === "Non-profit organization" || business === "Individual" || businessType === "Sole propietorship" || businessType === "Single-member LLC" ){
      if(businessRepresentative && !isUserRegistered){
        return (
          <NavLink
            to={"/registration/summary"}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            {t("sidebar.step1subtitle6")}
          </NavLink>
        )
      } else return(
        <button className="notActive">{t("sidebar.step1subtitle6")}</button>
      )
    } else if (business === "Company"){
      if (isUserRegistered){
        return(
          <button className="notActive">{t("sidebar.step1subtitle6")}</button>
        )
      } else if (!isUserRegistered && firstBusinessOwner){
        return(
          <NavLink
            to={"/registration/summary"}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            {t("sidebar.step1subtitle6")}
          </NavLink>
        )
      };
    };
  };

  const handleShowResponsiveSidebar = () => {
    return (
      <>
        <Icon
          icon="close-menu"
          extraClass="qp-icon-close"
          onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}
        />
        <h4>{t("sidebar.title1")}</h4>
        <div className="items-sidebar">
          {!isUserRegistered ? (
            <NavLink
              to={"/registration/business-type"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {t("sidebar.step1subtitle")}
            </NavLink>
          ) : (
            <button className="notActive">{t("sidebar.step1subtitle")}</button>
          )}
          { business && !isUserRegistered? (
            <NavLink
              to={"/registration/business-details"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {t("sidebar.step1subtitle2")}
            </NavLink>
          ) :
            <button className="notActive">{t("sidebar.step1subtitle2")}</button>
          }
          {businessDetails && !isUserRegistered?
            <NavLink
              to={"/registration/business-representative"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {t("sidebar.step1subtitle3")}
            </NavLink>
          : <button className="notActive">{t("sidebar.step1subtitle3")}</button>
          }
          {handleBusinessOwnersLinks()}
          {handleSummaryLink()}
        </div>
        <h4>{t("sidebar.title2")}</h4>
        <div className="items-sidebar">
          {isUserRegistered ? (
            <NavLink
              to={"/registration/bank-details"}
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              {t("sidebar.step2subtitle")}
            </NavLink>
          ) : (
            <button className="notActive">{t("sidebar.step2subtitle")}</button>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="sidebar relative">
      <Icon
        icon="menu"
        extraClass="qp-icon-menu mobile-menu register-menu-responsive"
        onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}
      />
      <div className={`container-links animatecss lg:flex flex-col justify-end hidden`}>
        {handleShowResponsiveSidebar()}
      </div>
      {/* mobile menu */}
      <div
        className={`container-links lg:hidden block animatecss menu-mobile ${
          showResponsiveSidebar ? "animatecss-fadeInLeft" : "animatecss-fadeOutLeft"
        }`}
      >
        {handleShowResponsiveSidebar()}
      </div>
      <div
        className={`overlay ${showResponsiveSidebar ? "block" : "hidden"}`}
        onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}
      />
    </div>
  );
};

export default Sidebar;