import { memo } from "react";
import { dataCustomerProps } from "../../../interfaces/customerInterface";
import { formatDate } from "../../../utils/formatDate";

const CustomerItem = (props: dataCustomerProps) => {
  const { customer_name, date, point} = props.data;

  return (
    <>
      <div className="hidden md:block">
        <div className="customer-item">
          <p className="name">{customer_name}</p>
          <p className="date">{formatDate(date)}</p>
          <p className="ammount flex justify-center">
            ${point}
          </p>
        </div>
      </div>
      <div className="point-item md:hidden">
        <div className="name">
          <p className="customer-name">{customer_name}</p>
        </div>
        <div className="text-right">
          <p className="date">{`Exp: ${formatDate(date)}`}</p>
          <p className="ammount flex justify-end">${point}</p>
        </div>
      </div>
    </>
  );
};

export default memo(CustomerItem, (prevProps, nextProps) => {
  return prevProps.data.id === nextProps.data.id;
});
