import { useEffect, useState } from "react";
import NewLoginHeader from "@qp/ui/components/NewLoginHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/storeHooks";
import { Button, Icon } from "@qp/ui";
import { OwnerFormInfoProps } from "../../../interfaces/newLoginInterface";

const AddBusinessOwners = () => {
  const { t } = useTranslation(["registration"]);
  const [maxBusinessOwners, setMaxBusinessOwners] = useState(false);
  const [firstBusinessOwner, setFirstBusinessOwner] =
    useState<OwnerFormInfoProps>();
  const [secondBusinessOwner, setSecondBusinessOwner] =
    useState<OwnerFormInfoProps>();
  const [thirdBusinessOwner, setThirdBusinessOwner] =
    useState<OwnerFormInfoProps>();
  const [fourthBusinessOwner, setFourthBusinessOwner] =
    useState<OwnerFormInfoProps>();
  const navigate = useNavigate();
  const businessFlow = useAppSelector(
    (state) => state.businessFlow.businessflow
  );

  const handleEditOwner = (ownerNumber: number) => {
    localStorage.setItem('ownerNumber', JSON.stringify(ownerNumber));
    navigate("/registration/business-owners")
  }

  const handleAddBusinessOwner = (newOwner: number) => {
    localStorage.setItem('ownerNumber', JSON.stringify(newOwner));
    navigate("/registration/business-owners");
  };

  const handleNextStep = () => {
    if (businessFlow === "company") {
      navigate("/registration/summary");
    } else navigate("/registration");
  };


  useEffect(() => {
    const fourthOwner = JSON.parse(
      localStorage.getItem("additionalSecondOwner") || ""
    );
    if (fourthOwner !== "") {
      setMaxBusinessOwners(true);
    }
    const businessOwnerInfo = JSON.parse(
      localStorage.getItem("ownersFormData") || "no data"
    );
    const businessSecondOwnerInfo = JSON.parse(
      localStorage.getItem("additionalFirstOwner") || "no data"
    );
    const businessThirdOwnerInfo = JSON.parse(
      localStorage.getItem("additionalSecondOwner") || "no data"
    );
    const businessFourthOwnerInfo = JSON.parse(
      localStorage.getItem("additionalThirdOwner") || "no data"
    );
    if (businessOwnerInfo) {
      setFirstBusinessOwner(businessOwnerInfo);
    }
    if (businessSecondOwnerInfo) {
      setSecondBusinessOwner(businessSecondOwnerInfo);
    }
    if (businessThirdOwnerInfo) {
      setThirdBusinessOwner(businessThirdOwnerInfo);
    }
    if (businessFourthOwnerInfo) {
      setFourthBusinessOwner(businessFourthOwnerInfo);
    }
  }, []);

  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("steps.titleaddbo")}`}
        showBackButton
      />
      <div className="qp-cont-inp text-left">
        <p>{`${t("steps.titlebotext1")}`}</p>
      </div>
      <div className="qp-cont-inp text-left">
        <p className="font-bold">{`${t("steps.titlebotext2")}`}</p>
      </div>
      {firstBusinessOwner && (
        <div className="card-business-owners">
          <p className="font-bold">{`${firstBusinessOwner.name} ${firstBusinessOwner.last_name}`}</p>
          <Icon extraClass="absolute right-9" icon="edit-dark" onClick={() => handleEditOwner(1)} />
        </div>
      )}
      {secondBusinessOwner && (
        <div className="card-business-owners">
          <p className="font-bold">{`${secondBusinessOwner.name} ${secondBusinessOwner.last_name}`}</p>
          <Icon extraClass="absolute right-9" icon="edit-dark" onClick={() => handleEditOwner(2)} />
        </div>
      )}
      {thirdBusinessOwner && (
        <div className="card-business-owners">
          <p className="font-bold">{`${thirdBusinessOwner.name} ${thirdBusinessOwner.last_name}`}</p>
          <Icon extraClass="absolute right-9" icon="edit-dark" onClick={() => handleEditOwner(3)} />
        </div>
      )}
      {fourthBusinessOwner && (
        <div className="card-business-owners">
          <p className="font-bold">{`${fourthBusinessOwner.name} ${fourthBusinessOwner.last_name}`}</p>
          <Icon extraClass="absolute right-9" icon="edit-dark" onClick={() => handleEditOwner(4)} />
        </div>
      )}
      <div className="qp-cont-inp">
        <button
          type="button"
          className={`${
            fourthBusinessOwner ? "qp-btn-add opacity-70" : "qp-btn-add"
          } md:w-6/12 w-full`}
          onClick={() => handleAddBusinessOwner(0)}
          disabled={fourthBusinessOwner ? true : false}
        >
          {`${
            !firstBusinessOwner
              ? t("steps.titlebosubmitbtn")
              : t("steps.titlebosubmitbtn2")
          }`}
        </button>
      </div>
      <div className="qp-cont-inp">
        <hr />
      </div>
      <Button
        text={t("steps.continueBtn")}
        type="submit"
        extraClass="md:w-6/12 w-full mt-6"
        onClick={handleNextStep}
        disabled={!firstBusinessOwner}
      ></Button>
      <br />
    </div>
  );
};

export default AddBusinessOwners;