import { memo } from "react";
import { DateTime } from "luxon";
import { dataTransactionProps } from "../../../interfaces/transactionInterface";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import checkmark from "@qp/ui/img/merchant/checkmark.svg";

const ItemTransaction = (props: dataTransactionProps) => {
  const {
    id,
    user_name,
    created,
    amount,
    refund_value,
    refund,
    idOrder,
    user_email,
  } = props.data;
  const formatDate = DateTime.fromISO(created).toFormat("LL/dd/yy");
  const navigate = useNavigate();
  const { t } = useTranslation(["transaction"]);
  const handleRefund = (id: string) => {
    navigate(`refund/${id}`);
  };

  return (
    <Link to={refund ? "#" : id} className="transaction-item">
      <p className="order-id">{idOrder}</p>
      <p className="name">{user_name.toLocaleLowerCase()}</p>
      <p className="email">{user_email}</p>
      <p className="date">{formatDate}</p>
      <p
        className={`ammount ${
          amount.toString().substring(0, 1) === "-"
            ? "text-red-dark"
            : "text-orange-dark"
        }`}
      >
        $ {Number(amount).toFixed(2)}
      </p>

      <div className="refund flex justify-center">
        {amount.toString().substring(0, 1) === "-" ? (
          <div className="refunded-transaction">
            {t("refunded")}
            <img
              alt="checkmark"
              src={checkmark}
              className="pl-2"
              width={20}
            ></img>
          </div>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              handleRefund(id);
            }}
            className={`qp-btn btn-transaction ${
              amount.toString().substring(0, 1) === "-" ||
              refund_value === Number(amount)
                ? "disabled"
                : ""
            }`}
            disabled={
              amount.toString().substring(0, 1) === "-" ||
              refund_value === Number(amount)
                ? true
                : false
            }
          >
            {t("requestRef")}
          </button>
        )}
      </div>
    </Link>
  );
};

export default memo(ItemTransaction, (prevProps, nextProps) => {
  return prevProps.data.id === nextProps.data.id;
});
