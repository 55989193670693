import { useEffect, useState } from "react";
import NewLoginHeader from "@qp/ui/components/NewLoginHeader";
import { businessRepresentativeProps } from "../../../interfaces/newLoginInterface";
import BusinessRepresentativeForm from "../../../components/auth/registration/BusinessRepresentativeForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/storeHooks";
import moment from "moment";

const BusinessRepresentative = (props: businessRepresentativeProps) => {
  const { t } = useTranslation(["registration"]);
  const navigate = useNavigate();
  const [representativeFormValues, setRepresentativeFormValues] = useState<any>(JSON.parse(
    window.localStorage.getItem("representativeFormData") || ""));
  const businessFlow = useAppSelector((state) => state.businessFlow.businessflow);

  const handleSuccess = (values: any) => {
    values.dateOfBirth = moment(`${values.day} ${values.month} ${values.year}`).toDate();
    setRepresentativeFormValues(values);
    if(businessFlow === "company"){
      navigate("/registration/add-business-owners");
    } else navigate("/registration/summary")
  };

  useEffect (() => {
    localStorage.setItem('representativeFormData', JSON.stringify(representativeFormValues));
  },[representativeFormValues])

  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("steps.titlestep4")}`}
        showBackButton
      />
      <BusinessRepresentativeForm onSuccess={(e) => handleSuccess(e)} />
    </div>
  );
};

export default BusinessRepresentative;