import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick: () => void;
  extraClass?: string;
}

const Button = (props: ButtonProps) => {
  const { type, onClick, text, disabled, extraClass } = props;
  return (
    <button
      className={`qp-btn ${disabled ? "disabled" : ""} ${
        extraClass ? extraClass : ""
      }`}
      onClick={() => onClick()}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
