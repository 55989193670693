import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./state.types";

const initialState: AuthState = {
  token: localStorage.getItem("qp-auth-token") || null,
  bussinessclasifications: [],
  userNotExist: false,
  otpValidated: false,
  userLogin: {},
  userPhone: "",
  pageRedirect: "",
  user: {
    email: "",
    photo_url: "",
  },
  changePassword: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOtp: (state, action) => {
      state.otpValidated = action.payload;
    },
    setBussinessClasifications: (state, action) => {
      state.bussinessclasifications = action.payload;
    },
    setUserLogin: (state, action) => {
      state.userLogin = action.payload.res;
      state.userPhone = action.payload.phone;
      state.pageRedirect = action.payload.page;
    },
    setUserInfo: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.userNotExist = false;
    },
    setChangePassword: (state, action) => {
      state.changePassword = action.payload;
    },
  },
});

export const {
  setToken,
  setBussinessClasifications,
  setOtp,
  setUserLogin,
  setUserInfo,
  setChangePassword,
} = authSlice.actions;

export default authSlice.reducer;
