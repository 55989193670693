import { createSlice } from "@reduxjs/toolkit";
import { redirectUrlProps, ShippingGetData, TaxIndividualI} from "../interfaces/uploadInterface";

const initialState: redirectUrlProps = {
  successfulTransactionUrl: "",
  failedTransactionUrl: "",
  shippingData: [],
  balance: 0,
  taxesData: []
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setSuccessfulUrl: (state, action) => {
      state.successfulTransactionUrl = action.payload;
    },
    setFailedUrl: (state, action) => {
      state.failedTransactionUrl = action.payload;
    },
    setShippingData: (state, action) => {
      state.shippingData = action.payload
    },
    setAddIndividualShippingData: (state, action) => {
      state.shippingData = [...state.shippingData, action.payload]
    },
    setDeleteIndividualShippingData: (state, action) => {
      state.shippingData = state.shippingData.filter((shippingValue: ShippingGetData) => shippingValue.id !== action.payload);
    },
    setUpdateShippingData: (state, action) => {
      state.shippingData = state.shippingData.map((s) => {
        if (s.id === action.payload.id){
          s = action.payload
        }
        return s
      })
    },
    setBalance:(state, action) => {
      state.balance = action.payload
    },
    setTaxesData: (state, action) => {
      state.taxesData = action.payload
    },
    setAddCustomTax: (state, action) => {
      state.taxesData = state.taxesData.map((tax) => {
        if (tax.id === action.payload.id) {
          tax.customTax = { customRate: action.payload.rate, merchantTaxId: action.payload.customTaxId }
        } return tax
      })
    },
    setUpdateCustomTax: (state, action) => {
      state.taxesData = state.taxesData.map((tax) => {
        if (tax.customTax?.merchantTaxId === action.payload.id) {
          tax.customTax = { customRate: action.payload.rate, merchantTaxId: action.payload.id }
        } return tax
      })
    },
  },
});

export const {
  setSuccessfulUrl,
  setFailedUrl,
  setShippingData,
  setBalance,
  setUpdateShippingData,
  setAddIndividualShippingData,
  setDeleteIndividualShippingData,
  setTaxesData,
  setAddCustomTax,
  setUpdateCustomTax
} = profileSlice.actions;

export default profileSlice.reducer;
