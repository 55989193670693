import axiosCLient from "./axios";
import { DateTime } from "luxon";
import { AppThunk } from "../store";
import { setTransactions } from "../store/transactions.slice";
import { setLoading } from "../store/shared.slice";

export const getTransactionDetail = (id: string) => {
  return axiosCLient.get(`merchants/transactions/${id}`);
};

export const refundTransactionService = async (values: {
  id: string;
  reason: string;
  amount: string;
}) => {
  return axiosCLient.post(`transactions/${values.id}/refund`, { observation: values.reason, amount: values.amount });
};

const formatFilters = (filters: any) => {
  const { startDate, endDate, lessAmount, greaterAmount } = filters;
  const startDateFormatted = startDate
    ? DateTime.fromJSDate(new Date(startDate)).toFormat("yyyy-MM-dd")
    : null;
  const endDateFormatted = endDate
    ? DateTime.fromJSDate(new Date(endDate)).toFormat("yyyy-LL-dd")
    : null;
  return {
    startDate: startDateFormatted,
    endDate: endDateFormatted,
    lessAmount,
    greaterAmount,
    page: filters.page
  };
};

export const getTransactions = (): AppThunk => async (dispatch, getSate) => {
  const filters = getSate().transaction.filters;
  let formatFilter = formatFilters(filters);
  let firstGet = formatFilter.greaterAmount === null && formatFilter.lessAmount === null;
  dispatch(setLoading(true));
  await axiosCLient
    .get("/merchants/transactions", {
      params: {
        ...formatFilter,
      },
    })
    .then((res) => {
      dispatch(setLoading(false));
      let response = Object.assign({}, res.data);
      if (firstGet) {
        response.firstGet = firstGet;
      }
      dispatch(setTransactions(response));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};
