import { Icon, Input } from '@qp/ui';
import { FocusEvent }from 'react';
import ReactTooltip from 'react-tooltip';
import Swal from "sweetalert2";
import { useAppDispatch } from '../../../hooks/storeHooks';
import { ShippingRowI } from '../../../interfaces/uploadInterface';
import { deleteShippingValues } from '../../../services/settingsService';
import { setDeleteIndividualShippingData } from '../../../store/profile.slice';
import { setLoading, setModalMessage } from '../../../store/shared.slice';
import { useTranslation } from 'react-i18next';

const ShippingRow = (props: ShippingRowI) => {
  const { index, formik, shipping, data, checked, setChangedData} = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["settings"]);

  const handleDisableTos = (i: number) => {
    const findValue = data.filter((value => value.id === formik.values.shippingValues[i].id ));
    if (formik.values.shippingValues[i].id === ""){
      if (formik.values.shippingValues[i].to === "" || (formik.values.shippingValues.length === 1 && i === 0)){
        return false
      }
    } else {
      if (i === formik.values.shippingValues.length -1) {
        return false
      } else {
        // si tiene id, no es el ultimo y el to no es undefined en el servidor
        if (findValue && (findValue[0]?.to === undefined ||  findValue[0]?.to === 0)) {
          return false
        } else return true
      }
    };
  };

  const isNumberDecimal = (e: any) => {
    return !(e.charCode === 0 || ((e.charCode >= 48 && e.charCode <= 57) || (e.charCode === 46 && e.target.value.indexOf('.') < 0))) && e.preventDefault();
  };

  /* If user wants to add manually a new row
  const handleAddFormFile = (values: any) => {
    const shippingValues = [...values.shippingValues];
    shippingValues.push({ from: '0.00', to: '', rate: '', id: "", merchantId: '', type: checked ? 1 : 0})
    formik.setValues({ ...values, shippingValues })
  };
  */

  const handleAddFormFileNew = (values: any, index: number) => {
    const shippingValues = [...values.shippingValues];
    shippingValues.push({ from: Number(formik.values.shippingValues[index].to) + 0.01, to: '' , rate: Number(0.00).toFixed(2), id: '', merchantId: '', type: checked ? 1 : 0 })
    formik.setValues({ ...values, shippingValues })
  };

  const onBlurTo = (index: number) => (e: FocusEvent<HTMLInputElement, Element>) => {
    formik.handleBlur(e);
    if (formik.values.shippingValues?.[index].to) {
      const data = [...formik.values.shippingValues]
      data[index].to = (Number(formik.values.shippingValues?.[index].to).toFixed(2).toString());
      formik.setValues({shippingValues: data})
    }
    if (formik.values.shippingValues?.[index].to && !formik.values.shippingValues?.[index + 1] && formik.errors.shippingValues?.[index]?.to === undefined){
      handleAddFormFileNew(formik.values, index);
    } else if (formik.values.shippingValues?.[index].to && formik.values.shippingValues?.[index + 1]){
      const data = [...formik.values.shippingValues]
      data[index + 1].from = (Number(e.target.value) + 0.01).toString();
      formik.setValues({shippingValues: data})
    };
  };

  const onBlurRate = (index: number) => (e: FocusEvent<HTMLInputElement, Element>) => {
    formik.handleBlur(e);
    if (checked) {
      if (formik.values.shippingValues?.[index].rate) {
        const data = [...formik.values.shippingValues]
        data[index].rate = (Number(formik.values.shippingValues?.[index].rate).toFixed(2).toString());
        formik.setValues({shippingValues: data})
      };
    }
  };

  const removeFields = (values: any, i: number) => {
    Swal.fire({
      title: t("shipping.deleteConfirmationText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("shipping.yes"),
      confirmButtonColor: "#FFA31C",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoading(true))
        deleteShippingValues(formik.values.shippingValues[i].id).then((res) => {
          dispatch(setDeleteIndividualShippingData(formik.values.shippingValues[i].id))
          dispatch(setLoading(false))
          const data = [...values.shippingValues];
          data.splice(i, 1)
          formik.setValues({ ...values, shippingValues: data })
        }).catch((err) => {
          dispatch(setLoading(false))
          dispatch(
            setModalMessage({
              title: t("shipping.errTitle"),
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: t("shipping.ok"),
            })
          );
        })
      };
    });
  };

  const handleChange = (e: any) => {
    formik.handleChange(e);
    setChangedData(true);
  };

  return (
    <div className='relative flex w-full items-end' key={index}>
      <div className='flex flex-col' style={{width: "30%"}}>
        <div className='shipping-error items-center relative'>
          {formik.touched.shippingValues?.[index]?.from && formik.errors.shippingValues?.[index]?.from && (
            <div className="qp-inp-error">{formik.errors.shippingValues[index]?.from}</div>
          )}
          <Input
            type="text"
            placeholder={t("shipping.initialPlaceholder")}
            name={`shippingValues.${[index]}.from`}
            value={Number(shipping.from).toFixed(2) as string}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            onKeyPress={isNumberDecimal}
            disabled={true}
          />
        </div>
      </div>
      <div className='flex flex-col mx-2' style={{width: "30%"}}>
        <div className='shipping-error items-end relative'>
          {formik.touched.shippingValues?.[index]?.to && formik.errors.shippingValues?.[index]?.to && (
            <div className="qp-inp-error w-full">{formik.errors.shippingValues[index]?.to}</div>
          )}
          <Input
            type="text"
            placeholder={t("shipping.finalPlaceholder")}
            name={`shippingValues.${[index]}.to`}
            value={shipping.to as string}
            onChange={handleChange}
            onBlur={onBlurTo(index)}
            onKeyPress={isNumberDecimal}
            disabled={handleDisableTos(index)}

          />
        </div>
      </div>
      <div className='flex flex-col' style={{width: "30%"}}>
        <div className='shipping-error items-center relative'>
          {formik.touched.shippingValues?.[index]?.rate && formik.errors.shippingValues?.[index]?.rate && (
            <div className="qp-inp-error">{formik.errors.shippingValues[index]?.rate}</div>
          )}
          <Input
            type="text"
            placeholder={t("shipping.ratePlaceholder")}
            name={`shippingValues.${[index]}.rate`}
            value={shipping.rate as string}
            onChange={handleChange}
            onBlur={onBlurRate(index)}
            onKeyPress={isNumberDecimal}
          />
        </div>
      </div>
      <div className='flex flex-col pt-4 items-center' style={{width: "10%"}}>
        {index+ 1 === formik.values.shippingValues.length && formik.values.shippingValues.length !== 1 &&  (
          <>
            <ReactTooltip
              id="delete"
              place="top"
              className="w-20"
              type="dark"
            />
            <i
              data-for="delete"
              data-tip={t("shipping.delete")}
              className='self-end'
            >
              <Icon icon='delete-black' extraClass='ml-2'onClick={() => removeFields(formik.values, index)}/>
            </i>
          </>
        )}
      </div>
    </div>
  );
};

export default ShippingRow;