import { Input } from '@qp/ui'
import { TaxRowI } from '../../../interfaces/uploadInterface'
import { FocusEvent } from 'react';

const TaxRow = (props: TaxRowI) => {
  const { tax, index, formik, setChangedData } = props;

  const handleKey = (e: any) => {
    return !(e.charCode === 0 || ((e.charCode >= 48 && e.charCode <= 57) || (e.charCode === 46 && e.target.value.indexOf('.') < 0))) && e.preventDefault();
  };
  const handleBlur = (index: number) => (e: FocusEvent<HTMLInputElement, Element>) => {
    formik.handleBlur(e);
    if(Number(formik.values.taxesValues[index].customTax.customRate.toString().substring(0,1)) === 0 && formik.values.taxesValues[index].customTax.customRate.toString().substring(1,2) !== "."){
      const data = [...formik.values.taxesValues]
      data[index].customTax.customRate = Number(formik.values.taxesValues[index].customTax.customRate.toString().substring(1));
      formik.setValues({taxesValues: data});
    };
  };

  const handleChange = (e: any) => {
    formik.handleChange(e);
    setChangedData(true);
  };

  return (
    <div className='flex justify-center items-center' key={index} >
      <div className='pt-4 mr-4 relative w-2/5 text-left font-semibold'>
        {tax.state}
      </div>
      <div className='pt-4 relative w-3/5'>
        {formik.touched.taxesValues?.[index]?.customTax?.customRate && formik.errors.taxesValues?.[index]?.customTax?.customRate && (
          <div className="qp-inp-error">{formik.errors.taxesValues[index]?.customTax?.customRate}</div>
        )}
        <Input
          type="text"
          name={`taxesValues.${[index]}.customTax.customRate`}
          value={tax.customTax?.customRate.toString()}
          onChange={handleChange}
          onBlur={handleBlur(index)}
          onKeyPress={handleKey}
        />
      </div>
    </div>
  );
};

export default TaxRow;