import { AppThunk } from "../store";
import { setCustomers } from "../store/customers.slice";
import axiosCLient from "./axios";

export const getCustomers = (): AppThunk => async (dispatch) => {
  await axiosCLient
    .get("merchants/customers")
    .then((res) => {
      dispatch(setCustomers(res.data));
    })
    .catch((err) => {});
};
