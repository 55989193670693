import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SetExpiration from "../../../components/dash/loyalty/SetExpiration";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import {
  getExpirationLoyalty,
  getLoyalty,
  sendLoyalty,
  setExpirationLoyalty,
} from "../../../services/loyaltyService";
import { loyaltyValuesProps } from "../../../interfaces/loyaltyInterface";
import LoyaltyForm from "../../../components/dash/loyalty/LoyaltyForm";
import Expiration from "../../../components/dash/loyalty/Expiration";

const Loyalty = () => {
  const { t } = useTranslation(["loyalty"]);
  const dispatch = useAppDispatch();
  const { loyalty, expirationDays } = useAppSelector((state) => state.loyalty);

  useEffect(() => {
    dispatch(getLoyalty());
    dispatch(getExpirationLoyalty());
  }, [dispatch]);

  const handleSuccess = (values: loyaltyValuesProps) => {
    dispatch(sendLoyalty(values.loyalty));
  };

  const handleSetExpiration = (expiration: string) => {
    dispatch(setExpirationLoyalty(expiration));
  };

  return (
    <div className="w-full md:w-9/12">
      <div className="text-2xl md:text-3xl font-medium">
        {t("setloyalty.title")}
      </div>
      <LoyaltyForm onSuccess={handleSuccess} loyalty={loyalty} />
      {/* <hr className="w-9/12 my-12 m-auto" /> */}
      {/* <div className="my-8">
        <Expiration expiration={expirationDays} />
      </div> */}
      <hr className="w-9/12 my-12 m-auto" />
      <SetExpiration onSuccess={(e) => handleSetExpiration(e)} />
    </div>
  );
};

export default Loyalty;
