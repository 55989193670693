import { inputProps } from "../interfaces/formInterface";

const Input = (props: inputProps ) => {
  const {
    type,
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    extraProps,
    reference,
    disabled,
    onKeyPress,
    maxLength,
    label,
    extraClassLabel
  } = props;

  return (
    <>
      <label className={`text-left mb-2 block ${extraClassLabel ? extraClassLabel : ""
          }`}>{label}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className={`${haveError !== undefined ? "error" : ""} ${extraClass ? extraClass : ""
          } ${disabled ? "qp-inp-disabled" : ""} qp-input placeholder-gray-500`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={reference}
        disabled={disabled}
        {...extraProps}
        onKeyPress={onKeyPress}
        maxLength={maxLength}
      />
    </>
  );
};

export default Input;
