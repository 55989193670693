import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/storeHooks";
import { routerProps } from "../interfaces/routerInterface";

export const PrivateRoute: React.FC<routerProps> = ({
  component: RouteComponent,
}) => {
  const isAuthenticated = useAppSelector((state) => state.auth.token);
  if (isAuthenticated) {
    return <RouteComponent />;
  }

  return <Navigate to="/" />;
};

export default PrivateRoute;
