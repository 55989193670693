import { createSlice } from "@reduxjs/toolkit";
import { SharedState } from "./state.types";

const initialState: SharedState = {
  isLoading: false,
  message: {
    title: "",
    text: "",
    icon: "",
    confirmButtonText: "",
  },
};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalMessage: (state, action) => {
      state.message = action.payload;
    },
    resetModalMessage: (state) => {
      state.message = {
        title: "",
        text: "",
        icon: "",
        confirmButtonText: "",
      };
    },
  },
});

export const { setLoading, setModalMessage, resetModalMessage } =
  sharedSlice.actions;
export default sharedSlice.reducer;
