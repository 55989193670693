import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Checkbox, Input, TextArea } from "@qp/ui";
import {
  IRefundFormProps,
  refundValuesProps,
} from "../../../interfaces/transactionInterface";
import { useTranslation } from "react-i18next";

function RefundForm(props: IRefundFormProps) {
  const { t } = useTranslation(["transaction"]);
  const { onSuccess, transaction } = props;
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const total: any = transaction && Number(transaction?.sub_total + transaction?.shipping + transaction?.taxes - transaction?.redeemCredit).toFixed(2);
  const formik = useFormik({
    initialValues: {
      amount: transaction?.refund_value === 0 ? total?.toString() : (Number(total) - transaction?.refund_value).toFixed(2),
      allAmount: false,
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError(t("refundForm.decimalErr"))
        .positive()
        .test(
          "is-decimal",
          t("refundForm.decimalErr"),
          (val: any) => {
            if (val !== undefined) {
              return patternTwoDigisAfterComma.test(val);
            }
            return true;
          }
        )
        .test('maxAmount', t("refundForm.maxRefundErr"), (val) => {
          if (val ) {
            return val <= total;
          }
          return true;
        })
        .test('maxAmountAvailable', t("refundForm.maxRefundAvErr"), (val) => {
          if (val ) {
            const remainingTotal: any = (Number(total) - transaction?.refund_value).toFixed(2);
            return val <= remainingTotal;
          }
          return true;
        })
        .required(t("refundForm.amountReq")),
        reason: Yup.string()
        .required(t("refundForm.reasonReq"))
    }),
    onSubmit: (values: refundValuesProps) => {
      onSuccess(values);
    },
  });

const handleCheckbox = (e: any) => {
  formik.handleChange(e);
  formik.setFieldValue("amount", (Number(total) - transaction?.refund_value).toFixed(2));
}
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mt-12 md:w-6/12 w-full mx-auto"
    >
      {/* amount input */}
      <div className="qp-cont-inp">
        {formik.touched.amount && formik.errors.amount && (
          <div className="qp-inp-error">{formik.errors.amount}</div>
        )}
        <Input
          label={t("refundForm.amount")}
          type="text"
          placeholder={t("refundForm.amount")}
          name="amount"
          value={formik.values.amount.toString()}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          haveError={formik.touched.amount && formik.errors.amount}
          disabled={formik.values.allAmount}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
            !/[0-9.]/.test(e.key) && e.preventDefault()
          }
        />
      </div>
      {/* allAmount input */}
      <div className="qp-cont-inp">
        {formik.touched.allAmount && formik.errors.allAmount && (
          <div className="qp-inp-error">{formik.errors.allAmount}</div>
        )}
        <Checkbox
          txtlabel={t("refundForm.allAmount")}
          lblExtraClass="rigth"
          name="allAmount"
          checked={formik.values.allAmount}
          onChange={(e) => handleCheckbox(e)}
          haveError={formik.touched.allAmount && formik.errors.allAmount}
        />
      </div>
      {/* reason */}
      <div className="qp-cont-inp">
        {formik.touched.reason && formik.errors.reason && (
          <div className="qp-inp-error">{formik.errors.reason}</div>
        )}
        <TextArea
          label={t("refundForm.reason")}
          placeholder={t("refundForm.reason")}
          name="reason"
          value={formik.values.reason}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          haveError={formik.touched.reason && formik.errors.reason}
        />
      </div>
      <button type="submit" className="qp-btn mt-16">
        {t("refundForm.button")}
      </button>
    </form>
  );
};

export default RefundForm;