import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import ListAddress from "../../../components/dash/address/ListAddress";

const Address = () => {
  const { t } = useTranslation(["profile"]);
  const location = useLocation();
  const state = location.state as any;
  const transaction = state ? state.transaction : false;
  return (
    <>
      <div className="text-2xl md:text-3xl font-medium">{t("address.title")}</div>
      <div className="pt-8">
        <ListAddress />
        <div className="flex justify-center pt-8 px-12">
          <Link
            to="/dash/address/create"
            state={{ transaction: transaction }}
            className="profile-submit-btn mt-0 mb-0"
          >
            {t("address.addAddress")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Address;