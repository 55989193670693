import axios from "axios";
import { logoutUser } from "./authService";

const baseURL: string = process.env.REACT_APP_CUSTOMER_BASE_URL as string;
const axiosCLient = axios.create({
  baseURL,
});

axiosCLient.interceptors.request.use(function (request: any) {
  const token =
    JSON.parse(localStorage.getItem("qp-auth-token") || "{}") || null;
  if (token !== null ) {
    if(token.id_token) {
      request.headers = {
        Authorization: `Bearer ${token.id_token}`,
      };
      return request;
    }
  }
  return request;
});

export default axiosCLient;
