import { Icon, Input } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const EditEmail = (props: { email: string }) => {
  const [editEmail, setEditEmail] = useState(false);
  const { t } = useTranslation(["editEmail"]);
  const { email } = props;

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("emailReq"))
        .required(t("validEmail")),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
    },
  });

  return (
    <div className="my-4 w-9/12 mx-auto relative">
      <form onSubmit={formik.handleSubmit} className="cont-edit-email">
        <Input
          type="email"
          placeholder="email@email"
          value={formik.values.email}
          name="email"
          onChange={(e) => formik.handleChange(e)}
          extraClass="qp-edit-email"
          disabled={!editEmail}
        />
        <div className="absolute top-2 right-4">
          {//<Icon icon={"edit-dark"} onClick={() => setEditEmail(!editEmail)} />
        }
        </div>
      </form>
    </div>
  );
};

export default EditEmail;
