import { PlaidLinkOnSuccess } from "react-plaid-link";
import { AppThunk } from "../store";
import { setPlaidAccounts } from "../store/accountsMerchant.slice";
import { setLoading, setModalMessage } from "../store/shared.slice";
import axiosCLient from "./axios";

export const connectPlaid = async () => {
  const { data } = await axiosCLient.post("payers/plaid");
  return data;
};

export const getPlaidAccounts = (): AppThunk => async (dispatch) => {
  await axiosCLient.get("merchants/account").then((res) => {
    dispatch(setPlaidAccounts(res.data));
  });
};

export const savePlaidAccounts =
  (success: any, signup?: boolean, callback?: (isSuccess: any) => void): AppThunk =>
    async (dispatch, getState) => {
      let emailRegistered = getState().auth.user.email
      let accountData = ""
      let requests = success.accounts.map((account: PlaidLinkOnSuccess | any) => {
        let send = Object.assign({}, account);
        send.public_token = success.public_token;
        send.sub_type = account.subtype;
        send.user_name = emailRegistered;
        if (signup) {
          const getEmail = localStorage.getItem("emailRegistered");
          send.user_name = getEmail;
        }
        delete send.subtype;
        delete send.class_type;
        delete send.verification_status;
        accountData = send
        return axiosCLient.post("merchants/account", send);
      });
      dispatch(setLoading(true));
      const responses: any = await Promise.allSettled(requests);
      dispatch(setLoading(false));
      if (callback) callback(responses[0].status); // only one account
      const isRegistered = responses[0].status !== "rejected"
      if (isRegistered) {
        let accountDataToSend: any = Object.assign({}, accountData);
        delete accountDataToSend.name;
        delete accountDataToSend.user_name;
        delete accountDataToSend.public_token;
        dispatch(setPlaidAccounts([accountDataToSend])); // inside array as its structured for more accounts
        if (signup) {
          dispatch(
            setModalMessage({
              title: "Great",
              text: "Your account has been successfully registered. Please go check your email for confirmation.",
              icon: "success",
              confirmButtonText: "Ok",
            })
          );
        } else {
          dispatch(
            setModalMessage({
              title: "Great",
              text: "Your bank account has been successfully updated.",
              icon: "success",
              confirmButtonText: "Ok",
            })
          );
        }
      }
      const err = responses.some((p: any) => p.status === "rejected");
      if (err) {
        dispatch(
          setModalMessage({
            title: "Error",
            text: "There was an error saving your account. Please try again.",
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      };
    };
