import { useEffect } from "react";
import { editAddress, getAddress } from "../../../services/addressService";
import { useNavigate, useParams } from "react-router-dom";
import { addressProps } from "../../../interfaces/addressInterface";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AddressForm from "../../../components/dash/address/AddressForm";

const EditAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const address = useAppSelector((state) => state.address.address);
  const data = address.find((item: addressProps) => item.id === id);
  const { t } = useTranslation(["profile"]);


  useEffect(() => {
    if (address.length === 0) {
      dispatch(getAddress());
    }
  }, [address, dispatch]);

  const handleSuccess = (values: addressProps) => {
    values.id = id;
    editAddress(values).then(() => {
      navigate(-1);
    });
  };

  return (
    <div className="container-qp">
      {data && (
        <div className="w-full">
          <h2>
            {t("address.editAddress")} {data.name}
          </h2>
          <AddressForm onSuccess={handleSuccess} data={data} />
        </div>
      )}
    </div>
  );
};

export default EditAddress;
