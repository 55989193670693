import axiosCLient from "./axios";

export const uploadLogo = async (imageLogo: FormData) => {
  let logo = await axiosCLient.post(
    "merchants/logo",
    imageLogo,
    {
      headers: { "Content-Type": `undefined ` },
      validateStatus: function(status) {
        return status < 500;
      },
    }
  );
  return logo;
};

export const getBalance = () => axiosCLient.get("merchants/transactions/balance")