import React from "react";
import { textAreaProps } from "../interfaces/formInterface";

const TextArea = (props: textAreaProps) => {
  const {
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    reference,
    disabled,
    label
  } = props;

  return (
    <>
      <div className="text-left mb-2">{label}</div>
      <textarea
        name={name}
        placeholder={placeholder}
        className={`qp-textarea ${haveError !== undefined ? "error" : ""} ${
          extraClass ? extraClass : ""
        } ${disabled ? "qp-inp-disabled" : ""} qp-input`}
        value={value}
        onChange={(e) => onChange(e)}
        onBlur={() => onBlur}
        ref={reference}
        disabled={disabled}
      />
    </>
  );
};

export default TextArea;
