import { Icon } from "@qp/ui";
import { itemAddressProps } from "../../../interfaces/addressInterface";

export const ItemAddress = (props: itemAddressProps) => {
  const { onIconClick, total } = props;
  const { id, name, address_value, selected } = props.data;
  return (
    <div className={`${selected || total === 1 ? "active rounded-xl" : "" } profile-container item-address address-list-container`}>
      <div className="info">
        <p className="name">{name}</p>
        <p className="address">{address_value}</p>
      </div>
      <div className="icons">
        <Icon
          icon="pin-orange"
          extraClass={!selected ? "opacity-50" : ""}
          onClick={() => onIconClick({ id, type: "principal" })}
        />
        <Icon
          icon="edit-orange"
          extraClass="mx-8"
          onClick={() => onIconClick({ id, type: "edit" })}
        />
        <Icon
          icon="delete-new"
          onClick={() => onIconClick({ id, type: "delete" })}
        />
      </div>
    </div>
  );
};
