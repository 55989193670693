import NewLoginHeader from '@qp/ui/components/NewLoginHeader';
import { useTranslation } from 'react-i18next';
import BankDetailsForm from '../../../components/auth/registration/BankDetailsForm';

const BankDetails = () => {
  const { t } = useTranslation(["registration"]);

  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("steps.titlebdtitle1")} <br /> ${t("steps.titlebdtitle2")}`}
      />
      <div className="qp-cont-inp">
        <p>{`${t("steps.textbd1")}`} <br /> {`${t("steps.textbd2")}`}</p>
      </div>
      <BankDetailsForm />
    </div>
  );
};

export default BankDetails;