import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import OtpForm from "../../../components/auth/OtpForm";

const ConfirmPass = () => {
  const email = localStorage.getItem("qp-recovery-email");
  const navigate = useNavigate();
  const { t } = useTranslation(["login"]);
  const handleSuccess = (code: string) => {
    localStorage.setItem("qp-code-recovery", code);
    navigate("/login/change-password");
  };

  const maskEmail = () => {
    if (email) {
      return email.replace(
        /^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, "*") + c
      );
    }
  };

  const customButtons = () => (
    <div className="flex items-center justify-end md:w-8/12 mx-auto mt-8">
      <Link to="/login/forgot-password" className="link-phone">
        {t("otp.newEmail")}
      </Link>
    </div>
  );

  return (
    <div className="container-qp">
      <div className="w-full">
        <h2>{t("otp.headerConfirmPass")}</h2>
        <p className="my-6">{t("otp.subtitleConfirmPass")} {maskEmail()}</p>
        <OtpForm onSuccess={handleSuccess} customButtons={customButtons()} />
      </div>
    </div>
  );
};

export default ConfirmPass;