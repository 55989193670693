import React from "react";

function NotFounPage() {
  return (
    <div className="text-center text-5xl font-semibold mt-16">
      404 not found
    </div>
  );
}

export default NotFounPage;
