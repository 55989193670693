import { Auth } from "aws-amplify";
import {
  IConfirmCodeForgotPass,
  IUserLoginEmail,
  IUserSignUpEmail,
} from "../interfaces/loginInterface";
import {
  CompanyRegisterInfo,
  AllRegisterInfo,
} from "../interfaces/newLoginInterface";
import { AppThunk } from "../store";
import {
  setBussinessClasifications,
  setToken,
  setOtp,
  setUserLogin,
} from "../store/auth.slice";
import { setLoading, setModalMessage } from "../store/shared.slice";
import axiosCLient from "./axios";
import axiosAuthCLient from "./axiosAuth";

export const loginUserEmail =
  (userData: IUserLoginEmail): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await axiosAuthCLient
      .post("login", userData)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setToken(res.data));
        localStorage.setItem("qp-auth-token", JSON.stringify(res.data));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(
          setModalMessage({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      });
  };

export const emailExist = async (email: string) => {
  return await axiosCLient.get(`merchants/exist/${email}`);
};

export const forgotPassword = async (email: string) => {
  return await axiosAuthCLient.post("forgotPassword", { email });
};

export const changePassword = async (params: IConfirmCodeForgotPass) => {
  return await axiosAuthCLient.post("confirmForgotPassword", params);
};

export const resendCodeConfirmUser = async (email: string) => {
  return await axiosAuthCLient.post("resendConfirmationCode", { email: email });
};

export const signupUserEmail = async (userData: IUserSignUpEmail) => {
  const headers = {
    "X-Path": `Auth/Merchant/signup`,
    "Content-Type": "application/json",
  };
  return await axiosCLient.post("registerbothenv", userData, {
    headers: headers,
  });
};

export const registerCompanyDwolla = async (user_id: string) => {
  const headers = {
    "X-Path": `QuickPenny-Resources/merchants/company/${user_id}`,
    "Content-Type": "application/json",
  };
  return await axiosCLient.post(`registerbothenv`, null, {
    headers: headers,
  });
};

export const registerUUID = async (uuid: string) => {
  return await axiosCLient.post("device/merchant", {
    ua: "Mozilla/5.0 (Windows; U; Windows NT 5.1; de-CH) AppleWebKit/523.15 (KHTML, like Gecko, Safari/419.3) Arora/0.2",
    browserName: "Arora",
    browserVersion: "0.2",
    osName: "Windows",
    osVersion: "XP",
    uniqueIdentifier: "52a862c4-a0fe-4907-b1b9-83de3514833f",
  });
};

export const signUpIndividualNonProfit = async (userInfo: AllRegisterInfo) => {
  const headers = {
    "X-Path": "QuickPenny-Resources/merchants",
    "Content-Type": "application/json",
  };
  let userLogin = await axiosCLient.post("registerbothenv", userInfo, {
    headers: headers,
  });
  return userLogin;
};

export const signUpCompany = async (companyInfo: CompanyRegisterInfo) => {
  const headers = {
    "X-Path": "QuickPenny-Resources/merchants/company",
    "Content-Type": "application/json",
  };
  let companyRegister = await axiosCLient.post("registerbothenv", companyInfo, {
    headers: headers,
  });
  return companyRegister;
};

export const identityVerificationOwner = async (email: string) => {
  return await axiosCLient.post(`merchants/identityverification/${email}`);
};

export const registerOwnerIdDocument = async (document: FormData) => {
  /*const headers = {
    'X-Path': 'QuickPenny-Resources/merchants/company/owners/documents',
    "Content-Type": 'application/json'
  }
  */
  let ownerIdDocument = await axiosCLient.post(
    "merchants/company/owners/documents",
    document,
    {
      headers: { "Content-Type": `undefined ` },
      validateStatus: function (status) {
        return status < 500; // Resuelve solo si el código de estado es menor que 500
      },
    }
  );
  return ownerIdDocument;
};
/*export const signUpAdditionalOwner = async (additionalOwner: AdditionalOwnerInfo) => {
  let setAdditionalOwner = await http.post("/merchants/company/owners", additionalOwner);
  return setAdditionalOwner;
}*/

export const confirmOTP =
  (code: string): AppThunk =>
  async (dispatch, getState) => {
    const user = getState().auth.userLogin;
    dispatch(setLoading(true));
    await Auth.sendCustomChallengeAnswer(user, code)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setToken(res.signInUserSession.idToken.jwtToken));
        axiosCLient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.signInUserSession.idToken.jwtToken}`;
        dispatch(setOtp(true));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

const authUser =
  (phone: string): AppThunk =>
  async (dispatch) => {
    await Auth.signUp({
      username: `+${phone}`,
      password: Date.now().toString(),
    })
      .then((res) => {
        dispatch(loginUser(phone, "signup"));
      })
      .catch((err) => {});
  };

export const loginUser =
  (phone: string, page: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await Auth.signIn(`+${phone}`)
      .then((res: any) => {
        dispatch(setLoading(false));
        dispatch(setUserLogin({ res, phone, page }));
      })
      .catch((err) => {
        if (
          err.message ===
          "DefineAuthChallenge failed with error User does not exist."
        ) {
          dispatch(authUser(phone));
        }
        // dispatch(setLoading(false));
      });
  };

export const logoutUser = async () => {
  return await Auth.signOut();
};

export const getUserInfo = async () => {
  return await axiosCLient.get("merchants/info");
};

const userStatusDwolla = async () => {
  return await axiosCLient.get("merchant/dwolla/status");
};

export const registerDwolla = async () => {
  userStatusDwolla().then((res) => {
    if (res.data === false) {
      axiosCLient.post("merchant/dwolla/register").then((res) => {});
    }
  });
};

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await Auth.currentAuthenticatedUser()
    .then((res) => {
      let idToken = res.signInUserSession.idToken.jwtToken;
      if (idToken) {
        axiosCLient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${idToken}`;
        dispatch(setToken(idToken));
        dispatch(setLoading(false));
      }
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(setToken(""));
    });
};

export const getBussinessClassification = (): AppThunk => async (dispatch) => {
  await axiosCLient.get("merchants/businessclassifications").then((res) => {
    let send = res.data._embedded.businessClassifications.map((item: any) => {
      return {
        id: item.id,
        value: item.name,
        embedded: item._embedded,
      };
    });
    dispatch(setBussinessClasifications(send));
  });
};

export const refreshToken = async () => {
  const token =
    JSON.parse(localStorage.getItem("qp-auth-token") || "{}") || null;

  return await axiosAuthCLient.post(
    "refreshToken",
    {
      refresh_token: token.refresh_token,
    },
    {
      headers: {
        Authorization: `Bearer ${token.id_token}`,
      },
    }
  );
};
