import React, { FocusEvent } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "@qp/ui";
import {
  loyaltyInterface, loyaltyValuesProps,
} from "../../../interfaces/loyaltyInterface";
import { useTranslation } from "react-i18next";

function LoyaltyForm(props: loyaltyInterface) {
  const { t } = useTranslation(["loyalty"]);
  const { onSuccess, loyalty } = props;
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const formik = useFormik({
    initialValues: {
      loyalty: loyalty ? loyalty : 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
        loyalty: Yup
        .number()
        .typeError(t("setloyalty.maxDecimals"))
        .positive()
        .test(
          "is-decimal",
          t("setloyalty.maxDecimals"),
          (val: any) => {
            if (val !== undefined) {
              return patternTwoDigisAfterComma.test(val);
            }
            return true;
          }
        )
        .max(100, t("setloyalty.maxErr"))
        .required(t("setloyalty.loyaltyReq")),
    }),
    onSubmit: (values: loyaltyValuesProps) => {
      onSuccess(values);
    },
  });

  const handleBlur = () => (e: FocusEvent<HTMLInputElement, Element>) => {
    formik.handleBlur(e);
    if(Number(formik.values.loyalty.toString().substring(0,1)) === 0 && formik.values.loyalty.toString().substring(1,2) !== "."){
      formik.setFieldValue("loyalty", Number(formik.values.loyalty.toString().substring(1)))
    };
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="profile-container"
    >
      {/* loyalty */}
      <div className="qp-cont-inp flex flex-col items-center">
        {formik.touched.loyalty && formik.errors.loyalty && (
          <div className="w-full md:w-4/6 qp-inp-error">{formik.errors.loyalty}</div>
        )}
        <Input
          extraClassLabel="w-full md:w-4/6"
          label={t("setloyalty.placeholder")}
          extraClass="w-full md:w-4/6"
          type="text"
          placeholder={t("setloyalty.placeholder")}
          name="loyalty"
          value={formik.values.loyalty.toString()}
          onChange={(e) => formik.handleChange(e)}
          onBlur={handleBlur()}
          haveError={formik.touched.loyalty && formik.errors.loyalty}
          extraProps={{
            min: 0,
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
            !/[0-9.]/.test(e.key) && e.preventDefault()
          }
        />
      </div>
      <button type="submit" className="profile-submit-btn">
        {t("setloyalty.button")}
      </button>
    </form>
  );
};

export default LoyaltyForm;
