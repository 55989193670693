import NewLoginHeader from "@qp/ui/components/NewLoginHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BusinessTypeForm from "../../../components/auth/registration/BusinessTypeForm";
import { setBusinessFlow } from "../../../store/businessFlow.slice";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { useEffect, useState } from "react";

const BusinessType = () => {
  const { t } = useTranslation(["registration"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [businessType, setBusinessType] = useState<any>(
    JSON.parse(window.localStorage.getItem("businessType") || "")
  );
  const [business, setBusiness] = useState<any>(
    JSON.parse(window.localStorage.getItem("business") || "")
  );

  const handleSuccess = (values: any) => {
    if (values.businessTypes === "Company") {
      if (
        values.businessStructure !== "Sole propietorship" &&
        values.businessStructure !== "Single-member LLC"
      ) {
        setBusinessType(values.businessStructure);
        setBusiness(values.businessTypes);
        dispatch(setBusinessFlow("company"));
        navigate("/registration/business-details");
      } else {
        dispatch(setBusinessFlow("individual"));
        setBusinessType(values.businessStructure);
        setBusiness(values.businessTypes);
        navigate("/registration/business-details");
      }
    } else dispatch(setBusinessFlow("individual"));
    setBusiness(values.businessTypes);
    setBusinessType("");
    navigate("/registration/business-details");
  };

  useEffect(() => {
    localStorage.setItem("businessType", JSON.stringify(businessType));
    localStorage.setItem("business", JSON.stringify(business));
  }, [businessType, business]);

  return (
    <div className="w-full">
      <NewLoginHeader titleHeader={`${t("steps.titlestep2")}`} showBackButton />
      <BusinessTypeForm onSuccess={(e) => handleSuccess(e)} />
    </div>
  );
};

export default BusinessType;