import React from "react";
import { Range, getTrackBackground } from "react-range";
import { rangeSliderProps } from "../interfaces/layoutInterface";

const RangeSlider = (props: rangeSliderProps) => {
  const STEP = 1;
  const { values, min, max, onChange, onFinalChange } = props;

  return (
    <div className="flex justify-center items-center flex-wrap">
      <Range
        values={values}
        step={STEP}
        min={min}
        max={max}
        onChange={onChange}
        onFinalChange={onFinalChange}
        renderTrack={({ props: renderTrackProps, children }) => (
          <div
            onMouseDown={renderTrackProps.onMouseDown}
            onTouchStart={renderTrackProps.onTouchStart}
            style={{
              ...renderTrackProps.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={renderTrackProps.ref}
              style={{
                height: "10px",
                width: "100%",
                borderRadius: "35px",
                background: getTrackBackground({
                  values: props.values,
                  colors: ["#EBEBEB", "#FFA31C", "#EBEBEB"],
                  min: props.min,
                  max: props.max,
                }),
                alignSelf: "center",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props: renderThumbProps, isDragged }) => (
          <div
            {...renderThumbProps}
            style={{
              ...renderThumbProps.style,
              height: "22px",
              width: "22px",
              borderRadius: "50%",
              backgroundColor: "#FFA31C",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-28px",
                color: "#000",
                fontWeight: "bold",
                fontSize: "14px",
                padding: "4px",
                borderRadius: "4px",
              }}
            >
              {props.values[index].toFixed(1)}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default RangeSlider;
