import React from "react";
import { ReactComponent as PencilDark } from "../img/customer/pencil-dark.svg";
import { ReactComponent as PencilOrange } from "../img/customer/pencil-orange.svg";
import { ReactComponent as DeleteOrange } from "../img/customer/delete-orange.svg";
import { ReactComponent as CalendarOrange } from "../img/customer/calendar-orange.svg";
import { ReactComponent as PinOrange } from "../img/customer/pin-orange.svg";
import { ReactComponent as CloseMenu } from "../img/merchant/close.svg";
import { ReactComponent as Menu } from "../img/merchant/menu.svg";
import { ReactComponent as Logout } from "../img/merchant/logout.svg";
import { ReactComponent as Search } from "../img/merchant/search.svg";
import { ReactComponent as Info } from "../img/merchant/info.svg";
import { ReactComponent as InfoReg } from "../img/merchant/info-reg.svg";
import { ReactComponent as EyeOpen } from "../img/eyeOpen.svg";
import { ReactComponent as EyeClosed } from "../img/eyeClosed.svg";
import { ReactComponent as Copy } from "../img/copy.svg";
import { ReactComponent as Add } from "../img/merchant/addNew.svg";
import { ReactComponent as AddSmall } from "../img/merchant/add20.svg";
import { ReactComponent as DeleteBlack } from "../img/merchant/delete.svg";
import { ReactComponent as Gmail } from "../img/gmail.svg";
import { ReactComponent as Outlook } from "../img/outlook.svg";
import { ReactComponent as Yahoo } from "../img/yahoo.svg";
import { ReactComponent as DeleteNew } from "../img/merchant/delete-new.svg";
import { iconCustomerProps } from "../interfaces/iconInterface";

const Icon = (props: iconCustomerProps) => {
  const { icon, extraClass, onClick, disabled } = props;

  const renderIcon = (iconType: string) => {
    switch (iconType) {
      case "edit-dark":
        return <PencilDark />;
      case "edit-orange":
        return <PencilOrange />;
      case "delete-orange":
        return <DeleteOrange />;
      case "calendar-orange":
        return <CalendarOrange />;
      case "pin-orange":
        return <PinOrange />;
      case "close-menu":
        return <CloseMenu />;
      case "menu":
        return <Menu />;
      case "logout":
        return <Logout />;
      case "search":
        return <Search />;
      case "info":
        return <Info />;
      case "visible":
        return <EyeOpen />;
      case "not-visible":
        return <EyeClosed />;
      case "copy":
        return <Copy />;
      case "add":
        return <Add />;
      case "addSmall":
        return <AddSmall />;
      case "delete-black":
        return <DeleteBlack />;
      case "gmail":
        return <Gmail />;
      case "outlook":
        return <Outlook />;
      case "yahoo":
        return <Yahoo />;
      case "delete-new":
        return <DeleteNew />;
      case "info-reg":
        return <InfoReg />
    }
  };

  return (
    <button
      onClick={onClick}
      className={`qp-icon ${extraClass ? extraClass : ""}`}
      disabled={disabled}
      type="button"
    >
      {renderIcon(icon)}
    </button>
  );
};

export default Icon;
