import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/storeHooks";
import { routerProps } from "../interfaces/routerInterface";

export const PublicRoute: React.FC<routerProps> = ({
  component: RouteComponent,
}) => {
  const token = useAppSelector((state) => state.auth.token);
  if (token) {
    return <Navigate to="/dash" />;
  }

  return <RouteComponent />;
};

export default PublicRoute;
