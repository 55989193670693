import { useTranslation } from 'react-i18next';
import ShippingForm from '../../../components/settings/shipping/ShippingForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { ShippingFormValuesI, ShippingGetData } from '../../../interfaces/uploadInterface';
import { createShippingValues, updateShippingValues } from '../../../services/settingsService';
import { setAddIndividualShippingData, setUpdateShippingData } from '../../../store/profile.slice';
import { setLoading, setModalMessage } from '../../../store/shared.slice';

const Shipping = () => {
  const dispatch = useAppDispatch();
  const shippingData = useAppSelector((state) => state.profile.shippingData);
  const { t } = useTranslation(["settings"]);
  const handleSuccess = async (values: ShippingFormValuesI) => {
    values.shippingValues.forEach(element => {
      const valueId = element.id;
      if (valueId !== "") {
        const existingValue = shippingData.filter((value: ShippingGetData) => value.id === valueId);
        if ((Number(existingValue[0].rate).toFixed(2) !== element.rate) || ((!existingValue[0].to || existingValue[0].to === 0)  && Number(existingValue[0].to).toFixed(2) !== element.to )){
          const sendData = {
            from: Number(element.from),
            id: element.id,
            merchantId: element.merchantId,
            rate: Number(element.rate),
            type: element.type,
            ...element.to && element.to!== "" && {to: Number(element.to)},
          }
          dispatch(setLoading(true));
          updateShippingValues(sendData).then(() => {
            dispatch(setLoading(false));
            dispatch(setUpdateShippingData(sendData))
          }).catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setModalMessage({
                title: t("shipping.errTitle"),
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: t("shipping.ok"),
              })
            );
          })
        }
      } else {
        const sendData = {
          from: Number(element.from),
          id: element.id,
          merchantId: element.merchantId,
          rate: Number(element.rate),
          type: element.type,
          ...element.to !== "" && {to: Number(element.to)},
        }
        dispatch(setLoading(true));
        createShippingValues(sendData).then((res) => {
          dispatch(setLoading(false));
          dispatch(setAddIndividualShippingData(res.data))
          dispatch(
            setModalMessage({
              title: t("shipping.done"),
              text: t("shipping.confirmationText"),
              icon: "success",
              confirmButtonText: t("shipping.ok"),
            })
          );
        }).catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setModalMessage({
              title: t("shipping.errTitle"),
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: t("shipping.ok"),
            })
          );
        })
      }
    });
  };

  return (
    <div className='w-full mb-8'>
      <div className="flex justify-start">
        <ShippingForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export default Shipping;