import { Input } from '@qp/ui';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { RecoveryPassConfirmAccFormI } from '../../interfaces/authInterface';

const RecoveryPassConfirmAccForm = (props: RecoveryPassConfirmAccFormI) => {
  const { confirmAcc, handleConfirmAcc, handleRecovery } = props;
  const { t } = useTranslation(["login"]);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("recoveryPass.emailReq"))
        .email(t("recoveryPass.validEmail")),
    }),
    onSubmit(values) {
      confirmAcc ? handleConfirmAcc(values): handleRecovery(values);
    },
  });

  return (
    <form className="qp-cont-inp" onSubmit={formik.handleSubmit}>
      <div className="qp-cont-inp">
        {formik.touched.email && formik.errors.email && (
          <div className="qp-inp-error">{formik.errors.email}</div>
        )}
        <Input
          type="email"
          placeholder={t("recoveryPass.placeholder")}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          haveError={formik.touched.email && formik.errors.email}
        />
      </div>
      <button type="submit" className="qp-btn qp-btn-outline mt-8">
        {confirmAcc ? t("confirmAcc.confirmBtn") : t("recoveryPass.forgotBtn")}
      </button>
    </form>
  );
};

export default RecoveryPassConfirmAccForm;