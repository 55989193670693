import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import EditEmail from "../../../components/dash/EditEmail";
import ListTransactions from "../../../components/dash/transactions/ListTransactions";
import { getUserInfo, logoutUser } from "../../../services/authService";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { setUserInfo } from "../../../store/auth.slice";
import { setLoading } from "../../../store/shared.slice";
import { useNavigate } from "react-router-dom";
import Filters from "../../../components/dash/transactions/Filters";

const Transactions = () => {
  const { t } = useTranslation(["transaction"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user.email.length === 0) {
      getUserInfo()
        .then((res) => {
          dispatch(setUserInfo(res.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response) {
            if (err.response.status === 401) {
              logoutUser().then(() => {
                navigate("/");
              });
            }
          }
        });
    }
  }, [user, dispatch, navigate]);

  return (
    <div className="w-full">
      <div className="tabs-headers">{t("title")}</div>
      <Filters />
      <hr />
      <div className="my-8">
        <EditEmail email={user.email} />
      </div>
      <div>
        <ListTransactions />
      </div>
    </div>
  );
};

export default Transactions;
