import { useState } from "react";
import { inputProps } from "../interfaces/formInterface";
import Icon from "./Icon";

const PasswordInput = (props: inputProps ) => {
  const {
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    extraProps,
    reference,
    disabled,
    onKeyPress,
    maxLength,
    label
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div className={`relative mt-4`}>
      <label className="text-left mb-2 block">{label}</label>
      <input
        type={visible ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        className={`${haveError !== undefined ? "error" : ""} ${extraClass ? extraClass : ""
          } ${disabled ? "qp-inp-disabled" : ""} qp-input password placeholder-gray-500`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={reference}
        disabled={disabled}
        {...extraProps}
        onKeyPress={onKeyPress}
        maxLength={maxLength}
      />
      <div className= {`visibility-icon`}>
      <Icon
        icon={`${visible ? 'visible' : 'not-visible'}`}
        onClick={() => setVisible((visible) => !visible)}
      />
      </div>
    </div>
  );
};

export default PasswordInput;
