import { useTranslation } from "react-i18next";
import QpLogo from "../../img/qp-logo-generate.png";

const GenerateButton = () => {
  const { t } = useTranslation("generate");
  return (
    <div className="w-full">
      <h2>{t("title")}</h2>
      <div className="md:w-6/12 w-full mx-auto mt-12">
        <div className="border rounded-2xl py-16 px-4">
          <button className="qp-btn flex items-center justify-center">
            {t("pay")}
            <img src={QpLogo} alt="logo" width={160} className="ml-2" />
          </button>
        </div>
        <button className="qp-btn mt-16">{t("button")}</button>
      </div>
    </div>
  );
};

export default GenerateButton;
