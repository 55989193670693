import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginForm from "../../components/auth/LoginForm";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { IUserLoginEmail } from "../../interfaces/loginInterface";
import { useTranslation } from "react-i18next";
import { setLoading, setModalMessage } from "../../store/shared.slice";
import axiosAuthCLient from "../../services/axiosAuth";
import { setToken } from "../../store/auth.slice";
import Swal from "sweetalert2";

const LoginEmail = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation(["login"]);

  const handleLogin = async(data: IUserLoginEmail) => {
    dispatch(setLoading(true));
    await axiosAuthCLient
    .post("login", data)
    .then((res) => {
      dispatch(setLoading(false));
      dispatch(setToken(res.data));
      localStorage.setItem("qp-auth-token", JSON.stringify(res.data));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      if (error.response.data.message === "Error: User is not confirmed"){
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showCancelButton: true,
          cancelButtonText: 'Ok',
          confirmButtonText:"Confirm account",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/login/confirm-account")
          }
        })
      } else {
        dispatch(
          setModalMessage({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
      }
    });
  };

  useEffect(() => {
    if (token !== null) {
      navigate("/dash/transactions");
    }
  }, [token, navigate]);

  return (
    <div className="container-qp">
      <div className="w-full">
        <h2>{t("login")}</h2>
        <LoginForm onSuccess={handleLogin} />
        <hr className="mt-12" />
        <p className="my-8">{t("noAcc")}</p>
        <Link
          to="/registration"
          className="qp-btn md:w-6/12 w-full mx-auto block"
        >
          {t("register")}
        </Link>
      </div>
    </div>
  );
};

export default LoginEmail;
