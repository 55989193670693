import { useEffect } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { resetModalMessage } from "../store/shared.slice";

const ModalApi = () => {
  const dispatch = useAppDispatch();
  const msg: any = useAppSelector((state) => state.shared.message);
  useEffect(() => {
    if (msg.text.length > 0) {
      Swal.fire({
        title: msg.title,
        text: msg.text,
        icon: msg.icon,
        confirmButtonText: msg.confirmButtonText,
      }).then((result) => {
        if (result.value) {
          dispatch(resetModalMessage());
        }
      });
    }
  }, [msg, dispatch]);
  return null;
};

export default ModalApi;
