import { createSlice } from "@reduxjs/toolkit";
import { CustomerState } from "./state.types";

const initialState: CustomerState = {
  customers: [],
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
  },
});

export const { setCustomers } = customersSlice.actions;

export default customersSlice.reducer;
