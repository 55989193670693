import React, { useEffect, useState } from "react";
import { Input, Select, SelectStatesUS } from "@qp/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  businessRepresentativeFormProps,
  OwnerFormInfoProps,
} from "../../../interfaces/newLoginInterface";
import { FileUploader } from "react-drag-drop-files";
import UploadLogo from "../../profile/upload/UploadLogo";
import { useTranslation } from "react-i18next";
import moment, { months } from "moment";
import { days, formatData, years } from "../../../utils/birthDateSelect";
const acceptedImageFormats = ["image/jpg", "image/jpeg", "image/png"];

const BusinessOwnerForm = (props: businessRepresentativeFormProps) => {
  const getFirstOwner: OwnerFormInfoProps = JSON.parse(
    window.localStorage.getItem("ownersFormData") || ""
  );
  const getSecondOwner: OwnerFormInfoProps = JSON.parse(
    window.localStorage.getItem("additionalFirstOwner") || ""
  );
  const getThirdOwner: OwnerFormInfoProps = JSON.parse(
    window.localStorage.getItem("additionalSecondOwner") || ""
  );
  const getFourthOwner: OwnerFormInfoProps = JSON.parse(
    window.localStorage.getItem("additionalThirdOwner") || ""
  );

  const { t } = useTranslation(["registration"]);
  const { onSuccess } = props;
  const fileOwnerOne = JSON.parse(localStorage.getItem("document") || "");
  const fileOwnerTwo = JSON.parse(localStorage.getItem("document2") || "");
  const fileOwnerThree = JSON.parse(localStorage.getItem("document3") || "");
  const fileOwnerFour = JSON.parse(localStorage.getItem("document4") || "");

  const [fileName, setFileName] = useState(
    `${t("bussinessOwnerForm.fileNameDefault")}`
  );
  const ownerNumber = JSON.parse(
    window.localStorage.getItem("ownerNumber") || ""
  );
  const idTypes = [
    { value: "license", name: t("bussinessOwnerForm.license"), id: 1 },
    { value: "idCard", name: t("bussinessOwnerForm.idCard"), id: 2 },
  ];

  const formik = useFormik({
    initialValues: {
      name:
        ownerNumber === 1
          ? getFirstOwner.name
          : "" || ownerNumber === 2
          ? getSecondOwner.name
          : "" || ownerNumber === 3
          ? getThirdOwner.name
          : "" || ownerNumber === 4
          ? getFourthOwner.name
          : "" || "",
      email:
        ownerNumber === 1
          ? getFirstOwner.email
          : "" || ownerNumber === 2
          ? getSecondOwner.email
          : "" || ownerNumber === 3
          ? getThirdOwner.email
          : "" || ownerNumber === 4
          ? getFourthOwner.email
          : "",
      last_name:
        ownerNumber === 1
          ? getFirstOwner.last_name
          : "" || ownerNumber === 2
          ? getSecondOwner.last_name
          : "" || ownerNumber === 3
          ? getThirdOwner.last_name
          : "" || ownerNumber === 4
          ? getFourthOwner.last_name
          : "" || "",
      dateOfBirth:
        ownerNumber === 1
          ? moment(getFirstOwner.dateOfBirth).toDate()
          : null || ownerNumber === 2
          ? moment(getSecondOwner.dateOfBirth).toDate()
          : null || ownerNumber === 3
          ? moment(getThirdOwner.dateOfBirth).toDate()
          : null || ownerNumber === 4
          ? moment(getFourthOwner.dateOfBirth).toDate()
          : null || null,
      ssn:
        ownerNumber === 1
          ? getFirstOwner.ssn
          : "" || ownerNumber === 2
          ? getSecondOwner.ssn
          : "" || ownerNumber === 3
          ? getThirdOwner.ssn
          : "" || ownerNumber === 4
          ? getFourthOwner.ssn
          : "" || "",
      address_value:
        ownerNumber === 1
          ? getFirstOwner.address_value
          : "" || ownerNumber === 2
          ? getSecondOwner.address_value
          : "" || ownerNumber === 3
          ? getThirdOwner.address_value
          : "" || ownerNumber === 4
          ? getFourthOwner.address_value
          : "" || "",
      city:
        ownerNumber === 1
          ? getFirstOwner.city
          : "" || ownerNumber === 2
          ? getSecondOwner.city
          : "" || ownerNumber === 3
          ? getThirdOwner.city
          : "" || ownerNumber === 4
          ? getFourthOwner.city
          : "" || "",
      state:
        ownerNumber === 1
          ? getFirstOwner.state
          : "" || ownerNumber === 2
          ? getSecondOwner.state
          : "" || ownerNumber === 3
          ? getThirdOwner.state
          : "" || ownerNumber === 4
          ? getFourthOwner.state
          : "" || "",
      zip_code:
        ownerNumber === 1
          ? getFirstOwner.zip_code
          : "" || ownerNumber === 2
          ? getSecondOwner.zip_code
          : "" || ownerNumber === 3
          ? getThirdOwner.zip_code
          : "" || ownerNumber === 4
          ? getFourthOwner.zip_code
          : "" || "",
      country: "US",
      file:
        ownerNumber === 1
          ? getFirstOwner.file
          : "" || ownerNumber === 2
          ? getSecondOwner.file
          : "" || ownerNumber === 3
          ? getThirdOwner.file
          : "" || ownerNumber === 4
          ? getFourthOwner.file
          : "",
      documentType:
        ownerNumber === 1
          ? getFirstOwner.documentType
          : "" || ownerNumber === 2
          ? getSecondOwner.documentType
          : "" || ownerNumber === 3
          ? getThirdOwner.documentType
          : "" || ownerNumber === 4
          ? getFourthOwner.documentType
          : "",
      firstOwner: ownerNumber ? true : false,
      day:
        ownerNumber === 1
          ? getFirstOwner.day
          : "" || ownerNumber === 2
          ? getSecondOwner.day
          : "" || ownerNumber === 3
          ? getThirdOwner.day
          : "" || ownerNumber === 4
          ? getFourthOwner.day
          : "",
      month:
        ownerNumber === 1
          ? getFirstOwner.month
          : "" || ownerNumber === 2
          ? getSecondOwner.month
          : "" || ownerNumber === 3
          ? getThirdOwner.month
          : "" || ownerNumber === 4
          ? getFourthOwner.month
          : "",
      year:
        ownerNumber === 1
          ? getFirstOwner.year
          : "" || ownerNumber === 2
          ? getSecondOwner.year
          : "" || ownerNumber === 3
          ? getThirdOwner.year
          : "" || ownerNumber === 4
          ? getFourthOwner.year
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]*$/, t("bussinessOwnerForm.nameCharacters"))
        .required(t("bussinessOwnerForm.firstNameReq")),
      email: Yup.string()
        .email()
        .required(t("bussinessOwnerForm.emailReq"))
        .test(
          "unique",
          "This email has already been assigned to another owner",
          (value) => {
            return ![
              getFirstOwner?.email,
              getSecondOwner?.email,
              getThirdOwner?.email,
              getFourthOwner?.email,
            ].includes(value);
          }
        ),
      last_name: Yup.string()
        .matches(/^[A-Za-z\s]*$/, t("bussinessOwnerForm.nameCharacters"))
        .required(t("bussinessOwnerForm.lastNameReq")),
      day: Yup.string().required(t("bussinessOwnerForm.birthDateReq")),
      month: Yup.string().required(t("bussinessOwnerForm.birthDateReq")),
      year: Yup.string().required(t("bussinessOwnerForm.birthDateReq")),
      ssn: Yup.string()
        .matches(/^[0-9]{9}$/, t("bussinessOwnerForm.ssnFormat"))
        .required(t("bussinessOwnerForm.ssnReq")),
      address_value: Yup.string()
        .required(t("bussinessOwnerForm.addReq"))
        .max(50, t("businessDetailsForm.addressMax")),
      city: Yup.string().required(t("bussinessOwnerForm.cityReq")),
      state: Yup.string().required(t("bussinessOwnerForm.stateReq")),
      zip_code: Yup.string().required(t("bussinessOwnerForm.zipReq")),
      country: Yup.string(),
      file: Yup.mixed()
        .when("firstOwner", {
          is: false,
          then: Yup.mixed()
            .required(t("bussinessOwnerForm.fileReq"))
            .test("type", t("bussinessOwnerForm.formatError"), (file) => {
              if (file) {
                return acceptedImageFormats.includes(file.type);
              }
              return true;
            })
            .test("fileSize", t("bussinessOwnerForm.sizeError"), (file) => {
              if (file) {
                return file.size <= 10485760;
              }
              return true;
            }),
        })
        .when("firstOwner", {
          is: true,
          then: Yup.mixed(),
        }),
      documentType: Yup.string().required(t("bussinessOwnerForm.docTypeReq")),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  useEffect(() => {
    if (fileName.length > 30) {
      let name = fileName.substring(0, 30);
      name = name + ` (...)`;
      setFileName(name);
    }
  }, [fileName, setFileName]);

  useEffect(() => {
    if (ownerNumber === 1) {
      setFileName(fileOwnerOne);
    } else if (ownerNumber === 2) {
      setFileName(fileOwnerTwo);
    } else if (ownerNumber === 3) {
      setFileName(fileOwnerThree);
    } else if (ownerNumber === 4) {
      setFileName(fileOwnerFour);
    }
  }, [ownerNumber, fileOwnerOne, fileOwnerTwo, fileOwnerThree, fileOwnerFour]);

  return (
    <form onSubmit={formik.handleSubmit} className="mt-12">
      <div className="w-full">
        <div className="qp-cont-inp text-left text-xl">
          {t("bussinessOwnerForm.headerOne")}
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.firstName")}</div>
          {formik.touched.name && formik.errors.name && (
            <div className="qp-inp-error">{formik.errors.name}</div>
          )}
          <Input
            type="text"
            name="name"
            placeholder="Charlie"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.name && formik.errors.name}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
            }
          />
        </div>

        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.lastName")}</div>
          {formik.touched.last_name && formik.errors.last_name && (
            <div className="qp-inp-error">{formik.errors.last_name}</div>
          )}
          <Input
            type="text"
            name="last_name"
            placeholder="Jackson"
            value={formik.values.last_name}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.last_name && formik.errors.last_name}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.email")}</div>
          {formik.touched.email && formik.errors.email && (
            <div className="qp-inp-error">{formik.errors.email}</div>
          )}
          <Input
            type="email"
            name="email"
            placeholder="owner@quickpenny.com"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.birthDate")}</div>
          {((formik.touched.day && formik.errors.day) ||
            (formik.touched.month && formik.errors.month) ||
            (formik.touched.year && formik.errors.year)) && (
            <div className="qp-inp-error">{formik.errors.year}</div>
          )}
          <div className="flex">
            <Select
              extraClass="flex-1"
              placeholder={t("businessRepresentativeForm.selectMonth")}
              name="month"
              value={formik.values.month}
              options={formatData(months())}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={formik.touched.month && formik.errors.month}
            />
            <Select
              extraClass="flex-1 mx-2"
              placeholder={t("businessRepresentativeForm.selectDay")}
              name="day"
              value={formik.values.day}
              options={formatData(days(formik))}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={formik.touched.day && formik.errors.day}
            />
            <Select
              extraClass="flex-1"
              placeholder={t("businessRepresentativeForm.selectYear")}
              name="year"
              value={formik.values.year}
              options={formatData(years())}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={formik.touched.year && formik.errors.year}
            />
          </div>
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.ssn")}</div>
          {formik.touched.ssn && formik.errors.ssn && (
            <div className="qp-inp-error">{formik.errors.ssn}</div>
          )}
          <Input
            type="text"
            name="ssn"
            placeholder="123456789"
            value={formik.values.ssn}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.ssn && formik.errors.ssn}
            maxLength={9}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9]/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <br />
          <hr />
          <br />
        </div>
        <div className="qp-cont-inp text-left text-xl">
          {t("bussinessOwnerForm.headerTwo")}
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.country")}</div>
          {formik.touched.country && formik.errors.country && (
            <div className="qp-inp-error">{formik.errors.country}</div>
          )}
          <Input
            type="text"
            name="country"
            placeholder={t("bussinessOwnerForm.country")}
            value="USA"
            disabled
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.country && formik.errors.country}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.state")}</div>
          {formik.touched.state && formik.errors.state && (
            <div className="qp-inp-error">{formik.errors.state}</div>
          )}
          <SelectStatesUS
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            haveError={formik.touched.state && formik.errors.state}
            value={formik.values.state}
            name="state"
            placeholder={t("bussinessOwnerForm.stateSelect")}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.city")}</div>
          {formik.touched.city && formik.errors.city && (
            <div className="qp-inp-error">{formik.errors.city}</div>
          )}
          <Input
            type="text"
            name="city"
            placeholder={t("bussinessOwnerForm.city")}
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.city && formik.errors.city}
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.zipCode")}</div>
          {formik.touched.zip_code && formik.errors.zip_code && (
            <div className="qp-inp-error">{formik.errors.zip_code}</div>
          )}
          <Input
            type="text"
            name="zip_code"
            placeholder={t("bussinessOwnerForm.zipCode")}
            value={formik.values.zip_code}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.zip_code && formik.errors.zip_code}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/[0-9]/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className="qp-cont-inp">
          <div className="text-left">{t("bussinessOwnerForm.address")}</div>
          {formik.touched.address_value && formik.errors.address_value && (
            <div className="qp-inp-error">{formik.errors.address_value}</div>
          )}
          <Input
            type="text"
            name="address_value"
            placeholder={t("bussinessOwnerForm.address")}
            value={formik.values.address_value}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={
              formik.touched.address_value && formik.errors.address_value
            }
          />
        </div>
        <div>
          <div className="qp-cont-inp text-left text-xl">
            {t("bussinessOwnerForm.headerThree")}
          </div>
          <div className="qp-cont-inp">
            <div className="text-left">{t("bussinessOwnerForm.docType")}</div>
            <div className="text-left"></div>
            {formik.touched.documentType && formik.errors.documentType && (
              <div className="qp-inp-error">{formik.errors.documentType}</div>
            )}
            <select
              name="documentType"
              className={`${
                formik.touched.documentType &&
                formik.errors.documentType !== undefined
                  ? "error"
                  : ""
              } qp-input`}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              value={formik.values.documentType}
            >
              <option value="">{t("bussinessOwnerForm.selectDocType")}</option>
              {idTypes.map((option: any) => {
                return (
                  <option value={option.value} key={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="qp-cont-inp">
            <div className="text-left">{t("bussinessOwnerForm.uploadDoc")}</div>
            {formik.touched.file && formik.errors.file && (
              <div className="qp-inp-error">{formik.errors.file}</div>
            )}
            <div className="w-3/6 ">
              <FileUploader
                types={["png", "jpeg", "jpg"]}
                multiple={false}
                handleChange={(file: File) => {
                  formik.setFieldValue("file", file ? file : "no file");
                  setFileName(file.name);
                }}
                children={<UploadLogo label={fileName} />}
                name="file"
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>
        <div className="qp-cont-inp">
          <br />
          <hr />
          <br />
        </div>
        <button type="submit" className="qp-btn md:w-6/12 w-full mt-6">
          {t("bussinessOwnerForm.continueBtn")}
        </button>
        <br />
      </div>
    </form>
  );
};

export default BusinessOwnerForm;
