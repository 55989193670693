import RedirectUrls from "../../../components/dash/settings/RedirectUrls";
import Secretkeys from "../../../components/dash/settings/SecretKeys";
import Shipping from "../../../components/dash/settings/Shipping";
import Taxes from "../../../components/dash/settings/Taxes";

const Settings = () => {
  return (
    <div className="w-full md:w-9/12">
      <Secretkeys />
      <hr className="w-9/12 my-12 m-auto"/>
      <RedirectUrls />
      <hr className="w-9/12 my-12 m-auto"/>
      <Shipping />
      <hr className="w-9/12 my-12 m-auto"/>
      <Taxes />
    </div>
  );
};

export default Settings;