import React from "react";
import { checkboxProps } from "../interfaces/formInterface";

const Checkbox = (props: checkboxProps) => {
  const {
    txtlabel,
    name,
    checked,
    extraClass,
    lblExtraClass,
    haveError,
    disabled,
    onChange,
  } = props;
  return (
    <label
      className={`qp-lbl-checkbox ${lblExtraClass ? lblExtraClass : ""} ${
        extraClass ? extraClass : ""
      }`}
    >
      {txtlabel}
      <input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
        className={`qp-checkbox ${haveError !== undefined ? "error" : ""} ${
          disabled ? "qp-inp-disabled" : ""
        } ${extraClass ? extraClass : ""}`}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
