import customers from "../img/merchant/customer.png";
import transactions from "../img/merchant/transactions.png";
import expiration from "../img/merchant/expiration.png";
import generate from "../img/merchant/generate.png";
import loyalty from "../img/merchant/loyalty.png";
import upload from "../img/merchant/upload.png";
import address from "../img/merchant/address.png";
import settings from "../img/merchant/settings.svg";

import { ReactComponent as Logout } from "../img/merchant/logout.svg";
import { ReactComponent as User } from "../img/merchant/user.svg";
import { iconMerchantProps } from "../interfaces/iconInterface";

const MenuItem = (props: iconMerchantProps) => {
  const { icon, extraClass, extraClassContainer, disabled, text } = props;

  const renderIcon = (iconType: string) => {
    switch (iconType) {
      case "transactions":
        return (
          <img src={transactions} alt="transactions icon menu" width={20} />
        );
      case "customers":
        return <img src={customers} alt="customer icon menu" width={25} />;
      case "expiration":
        return <img src={expiration} alt="expiration icon menu" width={20} />;
      case "generate":
        return <img src={generate} alt="generate icon menu" width={20} />;
      case "loyalty":
        return <img src={loyalty} alt="loyalty icon menu" width={17} />;
      case "upload":
        return <img src={upload} alt="upload icon menu" width={20} />;
      case "address":
        return <img src={address} alt="address icon menu" width={12} />;
      case "user":
        return <User width={20} />;
      case "settings":
        return <img src={settings} alt="settings icon menu" width={21} />;
      case "logout":
        return <Logout width={20} />;
    }
  };

  return (
    <div
      className={`link-item ${extraClassContainer ? extraClassContainer : ""}`}
    >
      <div className={`qp-icon ${extraClass ? extraClass : ""}`}>
        {renderIcon(icon)}
      </div>
      <div className="capitalize">{text}</div>
    </div>
  );
};

export default MenuItem;
