import { createSlice } from "@reduxjs/toolkit";
import { BusinessFlowState } from "./state.types";

const initialState: BusinessFlowState = {
  businessflow: "",
};

const businessFlowSlice = createSlice({
  name: "businessFlow",
  initialState,
  reducers: {
    setBusinessFlow: (state, action) => {
      state.businessflow = action.payload;
    },
  },
});

export const { setBusinessFlow } = businessFlowSlice.actions;

export default businessFlowSlice.reducer;
