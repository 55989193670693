import { useTranslation } from "react-i18next";
import ListCustomers from "../../../components/dash/customers/ListCustomers";

const Customers = () => {
  const { t } = useTranslation(["customers"]);

  return (
    <div className="w-full">
      <div className="tabs-headers">{t("title")}</div>
      <ListCustomers />
    </div>
  );
};

export default Customers;
