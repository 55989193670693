import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@qp/ui";
import Swal from "sweetalert2";

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from "react-plaid-link";
import { connectPlaid } from "../../services/plaid";

interface plaidLinkProps {
  onAddAccount: (e: {}) => void;
  isEditing?: boolean;
  className?: string;
}

const QpPlaidLink = (props: plaidLinkProps) => {
  const { onAddAccount, isEditing, className } = props;
  const [token, setToken] = useState<string | null>(null);
  const { t } = useTranslation(["plaid"]);

  // get a link_token from your API when component mounts
  React.useEffect(() => {
    const createLinkToken = async () => {
      const data: any = await connectPlaid();
      setToken(data.link_token);
    };
    createLinkToken();
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      localStorage.setItem("plaid-accounts", JSON.stringify(metadata));
      onAddAccount(metadata);
    },
    [onAddAccount]
  );
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  const handleEditAccount = () => {
    Swal.fire({
      title: t("editConfirmationTitle"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("editConfirmationYes"),
    }).then((result) => {
      if (result.isConfirmed) {
        open();
      }
    });
  };

  const handlePlaidButton = () => {
    if (isEditing) {
      return <Icon icon="edit-dark" onClick={handleEditAccount} />;
    } else
      return (
        <button
          onClick={() => open()}
          disabled={!ready}
          className={className || "text-center underline w-full mt-4"}
        >
          {t("connectBtn")}
        </button>
      );
  };
  return <>{handlePlaidButton()}</>;
};

export default QpPlaidLink;
