import { BusinessClassificationsProps, businessDetailsProps, IndustryProps } from "../../../interfaces/newLoginInterface";
import NewLoginHeader from "@qp/ui/components/NewLoginHeader";
import BusinessDetailsForm from "../../../components/auth/registration/BusinessDetailsForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";

const BusinessDetails = (props: businessDetailsProps) => {
const { t } = useTranslation(["registration"]);
const bussinessClasifications = useAppSelector(
  (state) => state.auth.bussinessclasifications
);
const navigate = useNavigate();
const [detailsFormValues, setDetailsFormValues] = useState<any>(JSON.parse(
  window.localStorage.getItem("detailsFormData") || ""));

const handleSuccess = (values: any) => {
  setDetailsFormValues(values)
  let getIndustry= bussinessClasifications.map(( item:BusinessClassificationsProps) => {
    if(item.embedded.industryClassifications){
      return item.embedded.industryClassifications.find((industry: IndustryProps) =>
        industry.id === values.business_classifications)
    } return false
  })

  let getIndustryName = getIndustry.find((item) => Boolean(item) === true)
  let industryName:IndustryProps = getIndustryName as IndustryProps
  localStorage.setItem('industryName', JSON.stringify(industryName?.name));
  navigate("/registration/business-representative");
};

  useEffect (() => {
    localStorage.setItem('detailsFormData', JSON.stringify(detailsFormValues));
  },[detailsFormValues])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className="w-full">
      <NewLoginHeader
        titleHeader={`${t("steps.titlestep3")}`}
        showBackButton
      />
      <BusinessDetailsForm onSuccess={(e) => handleSuccess(e)} />
    </div>
  );
};

export default BusinessDetails;