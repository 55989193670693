import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword, resendCodeConfirmUser } from "../../services/authService";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setLoading, setModalMessage } from "../../store/shared.slice";
import { setChangePassword } from "../../store/auth.slice";
import { useTranslation } from "react-i18next";
import { RecoveryPassConfirmAccI } from "../../interfaces/authInterface";
import RecoveryPassConfirmAccForm from "../../components/auth/RecoveryPassConfirmAccForm";
import Swal from "sweetalert2";

const RecoveryPassConfirmAcc = (props: RecoveryPassConfirmAccI) => {
  const { confirmAcc } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["login"]);
  const handleRecovery = (values: { email: string }) => {
    dispatch(setLoading(true));
    forgotPassword(values.email)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(setModalMessage({
          title: t("recoveryPass.doneTitle"),
          text: t("recoveryPass.doneText"),
          icon: "success",
          confirmButtonText: t("recoveryPass.ok"),
        }))
        navigate("/")
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.status === 404) {
          dispatch(setModalMessage({
            title: t("recoveryPass.errTitle"),
            text: t("recoveryPass.errText"),
            icon: "error",
            confirmButtonText: t("recoveryPass.ok"),
          }))
        } else if (err.response.data.message === "Error: Cannot reset password for the user as there is no registered/verified email or phone_number"){
          Swal.fire({
            title: t("recoveryPass.errTitle"),
            text: t("recoveryPass.errNotConfirmed"),
            icon: "error",
            showCancelButton: true,
            cancelButtonText: 'Ok',
            confirmButtonText: t("recoveryPass.confirmAcc"),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace("/login/confirm-account")
            }
          })
        }
      });
  };

  const handleConfirmAcc = (values: { email: string }) => {
    dispatch(setLoading(true));
    resendCodeConfirmUser(values.email)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(setModalMessage({
          title: t("confirmAcc.doneTitle"),
          text: t("confirmAcc.doneText"),
          icon: "success",
          confirmButtonText: t("confirmAcc.ok"),
        }))
        navigate("/")
      })
      .catch((err) => {
        console.error(err)
        dispatch(setLoading(false));
        dispatch(setModalMessage({
          title: t("confirmAcc.errTitle"),
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: t("confirmAcc.ok"),
        }))
      });
  };

  useEffect(() => {
    dispatch(setChangePassword(true))
  },[dispatch])

  return (
    <div className="container-qp">
      <div className="w-full">
        <h2>{confirmAcc ? t("confirmAcc.header") : t("recoveryPass.header")}</h2>
        <RecoveryPassConfirmAccForm confirmAcc={confirmAcc} handleConfirmAcc={handleConfirmAcc} handleRecovery={handleRecovery}/>
        <Link to="/" className="link md:w-6/12 w-full mt-16 mx-auto block">
          {t("recoveryPass.loginBtn")}
        </Link>
      </div>
    </div>
  );
};

export default RecoveryPassConfirmAcc;
